import React, { Component } from "react";
import { connect } from "react-redux";
import { selectDate, changeFocus, RECEIVE_TIME_DATA } from "./CalendarActions";
import { requestData } from "../api/actions";
import JobCalendar from "./Calendar";
import history from "../history";

class CalendarContainer extends Component {
  componentDidMount() {
    const actionParams = {
      actionType: RECEIVE_TIME_DATA,
      name: "time",
      url: `time/${this.props.cmpny}/${this.props.match.params.selectedJob}`,
      job: this.props.match.params.selectedJob
    };
    this.props.requestData(actionParams);
  }
  componentDidUpdate(prevProps) {
    const actionParams = {
      actionType: RECEIVE_TIME_DATA,
      name: "time",
      url: `time/${this.props.cmpny}/${this.props.match.params.selectedJob}`,
      job: this.props.match.params.selectedJob
    };
    if (
      this.props.match.params.selectedJob !== prevProps.match.params.selectedJob
    ) {
      this.props.requestData(actionParams);
    }
  }
  render() {
    return <JobCalendar {...this.props} />;
  }
}
const mapStateToProps = state => {
  return {
    selectedDay: state.selectedDay,
    focused: state.calendarFocus,
    calendarDaysWithTime: state.calendarDaysWithTime,
    time: state.time,
    jobs: state.jobs.byId,
    cmpny: state.group
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handleDateChange: toClickedDate => {
      dispatch(selectDate(toClickedDate));
      history.push(toClickedDate);
    },
    handleFocusChange: focused => {
      dispatch(changeFocus(focused));
    },
    requestData: (actionType, name, url) =>
      dispatch(requestData(actionType, name, url))
  };
};

CalendarContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CalendarContainer);

export default CalendarContainer;
