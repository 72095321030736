import { action, thunk } from "easy-peasy"; // 👈 import
import { API } from "aws-amplify";
import { postData } from "../utils/postArrayData";
import { deleteApi } from "../sagas";
import {
  ADD_COST_CODE_DATA,
  DELETE_COST_CODE_DATA
} from "../timesheet/TimesheetActions";
import { RECEIVE_ADDED_JOB } from "../landing/LandingActions";
import { toast } from "react-toastify";
import { subModel } from "./AdminModelSubs";
import { jobUserModel } from "./AdminModelJobUsers";

const adminModel = {
  subs: subModel,
  jobUsers: jobUserModel,
  employees: {},
  /*EMP THUNKS */
  fetchEmp: thunk(async (actions, payload) => {
    const data = await API.get("time", `time/${payload}/search`);
    console.log("fetch emp data: ", data);
    const dataObj = data.reduce((agg, item) => {
      return { ...agg, [item.empl]: item };
    }, {});
    actions.updateEmp(dataObj);
  }),
  addCmpnyEmp: thunk(async (actions, payload, { dispatch }) => {
    console.log("ACTION URL ISSUE: ", payload);
    actions.updateIsLoading({ toggle: true, name: "Emp" });
    try {
      const data = await postData("time", `admin/${payload.cmpny}/emp`, [
        payload.data
      ]);
      if (data.fail.length > 0) {
        data.fail.map(x => {
          let err = `Unable to Add ${x.fname} ${x.lname}`;
          actions.updateIsLoading({ toggle: false, name: "Emp" });
          toast.error(err);
          throw new Error(err);
        });
      }
      actions.updateIsLoading({ toggle: false, name: "Emp" });
      console.log("added Emp data: ", data);
      const dataObj = data.success.reduce((agg, item) => {
        return { ...agg, [item.empl]: item };
      }, {});
      actions.updateEmp(dataObj);
      toast.success("Successfully Updated", { containerId: "general" });
    } catch (error) {
      actions.updateIsLoading({ toggle: false, name: "Emp" });
      toast.error(error, { containerId: "general" });
      throw error;
    }
  }),
  delCmpnyEmp: thunk(async (actions, payload) => {
    actions.updateIsLoading({ toggle: true, name: "Emp" });
    try {
      const data = await deleteApi(
        "time",
        `admin/${payload.cmpny}/emp/${payload.empl}`
      );
      if (typeof data === "undefined") {
        let err = "Unable to Delete Employee";
        actions.updateIsLoading({ toggle: false, name: "Emp" });
        toast.error(err, { containerId: "general" });
        throw new Error(err);
      }
      actions.updateIsLoading({ toggle: false, name: "Emp" });
      console.log("Returned DELETE DATA: ", data);
      actions.delEmp(payload.empl);
      toast.success("Successfully Updated", { containerId: "general" });
    } catch (error) {
      actions.updateIsLoading({ toggle: false, name: "Emp" });
      toast.error(error, { containerId: "general" });
      throw error;
    }
  }),
  /*EMP ACTIONS */
  updateEmp: action((state, payload) => {
    console.log("PAYLOAD: ", payload);
    state.employees = { ...state.employees, ...payload };
  }),
  delEmp: action((state, payload) => {
    console.log("PAYLOAD: ", payload);
    let { [payload]: _, ...temp } = state.employees;
    state.employees = temp;
  }),
  /*COST CODES */
  cmpnyCostCodes: {},
  /*COST CODE THUNKS */
  fetchCmpnyCostCodes: thunk(async (actions, payload) => {
    const data = await API.get("time", `time/${payload}/cc`);
    console.log("fetch cmpny data: ", data);
    const dataObj = data.reduce((agg, item) => {
      return { ...agg, [item.cc]: item };
    }, {});
    actions.updateCmpnyCostCodes(dataObj);
  }),
  addCmpnyCostCode: thunk(async (actions, payload) => {
    actions.updateIsLoading({ toggle: true, name: "CC" });
    try {
      const data = await postData("time", `admin/${payload.cmpny}/cc`, [
        payload.data
      ]);
      const dataObj = data.success[0];
      if (data.fail.length > 0) {
        let err = "Unable to add Cost Code";
        actions.updateIsLoading({ toggle: false, name: "CC" });
        toast.error(err);
        throw new Error(err);
      }
      actions.updateIsLoading({ toggle: false, name: "CC" });
      console.log("added CC data: ", data);
      actions.updateCmpnyCostCodes({ [dataObj.cc]: dataObj });
      toast.success("Successfully Updated", { containerId: "general" });
    } catch (error) {
      actions.updateIsLoading({ toggle: false, name: "CC" });
      toast.error("Unable to Process");
      throw error;
    }
  }),
  deleteCmpnyCostCode: thunk(async (actions, payload) => {
    actions.updateIsLoading({ toggle: true, name: "CC" });
    try {
      const data = await deleteApi(
        "time",
        `admin/${payload.cmpny}/cc/${payload.cc}`
      );
      actions.updateIsLoading({ toggle: false, name: "CC" });
      console.log("deleted CC data: ", data);
      actions.delCmpnyCostCodes(payload.cc);
      toast.success("Successfully Updated", { containerId: "general" });
    } catch (error) {
      actions.updateIsLoading({ toggle: false, name: "CC" });
      toast.error("Unable to Process", { containerId: "general" });
      throw error;
    }
  }),
  addJobCostCodes: thunk(async (actions, payload, { dispatch }) => {
    actions.updateIsLoading({ toggle: true, name: "CC" });
    try {
      const data = await postData(
        "time",
        `time/${payload.cmpny}/${payload.job}/cc`,
        payload.data
      );
      actions.updateIsLoading({ toggle: false, name: "CC" });
      console.log("added CC data: ", data);
      dispatch({
        type: ADD_COST_CODE_DATA,
        payload: { data: data.success, job: payload.job }
      });
      console.log("WHAAAATTTTT");
      toast.success("Successfully Updated", { containerId: "general" });
    } catch (error) {
      actions.updateIsLoading({ toggle: false, name: "CC" });
      toast.error("Unable to Process", { containerId: "general" });
      throw error;
    }
  }),
  delJobCostCodes: thunk(async (actions, payload, { dispatch }) => {
    actions.updateIsLoading({ toggle: true, name: "CC" });
    try {
      const data = await deleteApi(
        "time",
        `time/${payload.cmpny}/${payload.job}/cc/${payload.cc}`
      );
      actions.updateIsLoading({ toggle: false, name: "CC" });
      console.log("added CC data: ", payload.cc, data);
      dispatch({
        type: DELETE_COST_CODE_DATA,
        payload: { cc: payload.cc, job: payload.job }
      });
      console.log("WHAAAATTTTT");
      toast.success("Successfully Updated", { containerId: "general" });
    } catch (error) {
      actions.updateIsLoading({ toggle: false, name: "CC" });
      toast.error("Unable to Process", { containerId: "general" });
      throw error;
    }
  }),
  /*COST CODE ACTIONS */
  updateCmpnyCostCodes: action((state, payload) => {
    state.cmpnyCostCodes = { ...state.cmpnyCostCodes, ...payload };
  }),
  delCmpnyCostCodes: action((state, payload) => {
    let { [payload]: _, ...temp } = state.cmpnyCostCodes;
    state.cmpnyCostCodes = temp;
  }),
  isCCLoading: false,
  isEmpLoading: false,
  isUsersLoading: false,
  isAddJobLoading: false,
  updateIsLoading: action((state, payload) => {
    console.log("LOADING PAYLOAD: ", payload);
    state[`is${payload.name}Loading`] = payload.toggle;
  }),
  /*USERS*/
  cmpnyUsers: {},
  cmpnyRegions: {},
  /*USER THUNKS */
  fetchCmpnyUsers: thunk(async (actions, payload) => {
    const data = await API.get("time", `admin/${payload}/user`);
    console.log("fetch cmpny data: ", data);
    const dataObj = data.reduce((agg, item) => {
      return { ...agg, [item.email]: item };
    }, {});
    actions.updateCmpnyUsers(dataObj);
  }),
  fetchRegions: thunk(async (actions, payload) => {
    const data = await API.get("time", `admin/${payload}/region`);
    console.log("fetch regions: ", data);
    const dataObj = data.reduce((agg, item) => {
      return { ...agg, [item.region]: item };
    }, {});
    actions.updateCmpnyRegions(dataObj);
  }),
  addCmpnyUser: thunk(async (actions, payload) => {
    console.log("ACTION URL ISSUE: ", payload);
    actions.updateIsLoading({ toggle: true, name: "Users" });
    try {
      const data = await API.post("time", `admin/${payload.cmpny}/user`, {
        body: payload.data
      });
      actions.updateIsLoading({ toggle: false, name: "Users" });
      console.log("added User data: ", data);
      const dataObj = { [data.email]: data };
      actions.updateCmpnyUsers(dataObj);
      toast.success("Successfully Updated", { containerId: "general" });
    } catch (error) {
      actions.updateIsLoading({ toggle: false, name: "Users" });
      toast.error("Unable to Process", { containerId: "general" });
      throw error;
    }
  }),
  delCmpnyUser: thunk(async (actions, payload) => {
    actions.updateIsLoading({ toggle: true, name: "Users" });
    try {
      const data = await API.del("time", `admin/${payload.cmpny}/user/delete`, {
        body: payload
      });
      actions.updateIsLoading({ toggle: false, name: "Users" });
      console.log("Returned DELETE DATA: ", data);
      actions.deleteCmpnyUser(payload.email);
      toast.success("Successfully Updated", { containerId: "general" });
    } catch (error) {
      actions.updateIsLoading({ toggle: false, name: "Users" });
      toast.error("Unable to Process", { containerId: "general" });
      throw error;
    }
  }),
  updateCmpnyUser: thunk(async (actions, payload) => {
    console.log(payload);
    actions.updateIsLoading({ toggle: true, name: "Users" });
    try {
      const data = await API.put("time", `admin/${payload.cmpny}/user`, {
        body: payload.updateData
      });
      actions.updateIsLoading({ toggle: false, name: "Users" });
      console.log("Returned UPDATE DATA: ", data);
      actions.updateCmpnyUsers({
        [data.email]: data
      });
      toast.success("Successfully Updated", { containerId: "general" });
    } catch (error) {
      actions.updateIsLoading({ toggle: false, name: "Users" });
      toast.error("Unable to Process", { containerId: "general" });
      throw error;
    }
  }),
  /*USER ACTIONS */
  updateCmpnyUsers: action((state, payload) => {
    console.log("PAYLOAD: ", payload);
    state.cmpnyUsers = { ...state.cmpnyUsers, ...payload };
  }),
  updateCmpnyRegions: action((state, payload) => {
    console.log("PAYLOAD: ", payload);
    state.cmpnyRegions = { ...state.cmpnyRegions, ...payload };
  }),
  deleteCmpnyUser: action((state, payload) => {
    console.log("PAYLOAD: ", payload);
    let { [payload]: _, ...temp } = state.cmpnyUsers;
    state.cmpnyUsers = temp;
  }),
  /*NEW JOBS*/
  cmpnyJobList: [],
  /*NEW JOB THUNKS */
  fetchCmpnyJobList: thunk(async (actions, payload) => {
    const data = await API.get("time", `admin/${payload}/joblist`);
    console.log("fetch cmpny jobs: ", data);
    actions.updateCmpnyJobList(data);
  }),

  addCmpnyJob: thunk(async (actions, payload, { dispatch }) => {
    actions.updateIsLoading({ toggle: true, name: "AddJob" });
    try {
      const data = await API.post(
        "time",
        `admin/${payload.cmpny}/job/${payload.job}`,
        { body: payload.data }
      );
      if (typeof data === "undefined") {
        let err = `Job ${payload.job} could not be created`;
        actions.updateIsLoading({ toggle: false, name: "AddJob" });
        toast.error(err, { containerId: "general" });
        throw new Error(err);
      }
      actions.updateIsLoading({ toggle: false, name: "AddJob" });
      console.log("added Job data: ", data);
      dispatch({
        type: RECEIVE_ADDED_JOB,
        data,
        job: payload.job
      });
      toast.success("Successfully Updated", { containerId: "general" });
    } catch (error) {
      actions.updateIsLoading({ toggle: false, name: "AddJob" });
      toast.error(error, { containerId: "general" });
      throw error;
    }
  }),
  delCmpnyJob: thunk(async (actions, payload) => {
    actions.updateIsLoading({ toggle: true, name: "AddJob" });
    try {
      const data = await API.del("time", `admin/${payload.cmpny}/user/delete`, {
        body: payload
      });
      actions.updateIsLoading({ toggle: false, name: "Users" });
      console.log("Returned DELETE DATA: ", data);
      actions.delCmpnyUser(payload.email);
      toast.success("Successfully Updated", { containerId: "general" });
    } catch (error) {
      actions.updateIsLoading({ toggle: false, name: "Users" });
      toast.error("Unable to Process", { containerId: "general" });
      throw error;
    }
  }),
  updateCmpnyJobList: action((state, payload) => {
    console.log("PAYLOAD: ", payload);
    state.cmpnyJobList = [...new Set([...state.cmpnyJobList, ...payload])];
  })
};

export default adminModel;
