export const SELECT_DATE = "SELECT_DATE";
export const CHANGE_FOCUS = "CHANGE_FOCUS";
export const RECEIVE_TIME_DATA = "RECEIVE_TIME_DATA";

export const selectDate = payload => ({
  type: SELECT_DATE,
  payload
});

export const changeFocus = payload => ({
  type: CHANGE_FOCUS,
  payload
});
