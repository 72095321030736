import React, { useMemo, useEffect } from "react";
import { useStoreState, useStoreActions } from "easy-peasy";
import {
  Header,
  Segment,
  Form,
  Dimmer,
  Loader,
  Button
} from "semantic-ui-react";
import { Formik, Field } from "formik";
import { addJobFormSchema } from "./FormSchema";
import { SemanticField } from "../utils/SemanticField";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

const DatePickerComponent = ({
  label,
  error,
  field: { name, value },
  form: { setFieldValue }
}) => {
  return (
    <Form.Field>
      <label>{label}</label>
      <DatePicker
        value={value}
        selected={value}
        onChange={e => setFieldValue(name, new Date(e))}
        monthsShown={2}
      />
    </Form.Field>
  );
};

export default props => {
  const cmpnyJobList = useStoreState(state => state.admin.cmpnyJobList);
  const cmpny = useStoreState(state => state.group);
  const fetchCmpnyRegions = useStoreActions(
    actions => actions.admin.fetchRegions
  );
  const cmpnyRegions = useStoreState(state => state.admin.cmpnyRegions);
  const cmpnyRegionList = useMemo(
    () =>
      Object.values(cmpnyRegions).map(item => ({
        value: item.region,
        text: item.region_name,
        key: item.region
      })),
    [cmpnyRegions]
  );
  const addCmpnyJob = useStoreActions(actions => actions.admin.addCmpnyJob);
  const isAddJobLoading = useStoreState(state => state.admin.isAddJobLoading);
  const fetchCmpnyJobList = useStoreActions(
    actions => actions.admin.fetchCmpnyJobList
  );
  useEffect(() => {
    fetchCmpnyJobList(cmpny);
  }, [cmpny, fetchCmpnyJobList]);
  useEffect(() => {
    fetchCmpnyRegions(cmpny);
  }, [cmpny, fetchCmpnyRegions]);

  return (
    <div style={{ maxWidth: "500px" }}>
      <Button
        labelPosition="left"
        icon="left chevron"
        content="Back"
        onClick={() => props.history.goBack()}
      />
      <Header as="h3">Add New Job</Header>
      <Formik
        initialValues={{
          job: "",
          job_name: "",
          latitude: "",
          longitude: "",
          maxRegHours: 8,
          region: "01",
          expire: new Date()
        }}
        validationSchema={addJobFormSchema(cmpnyJobList)}
        onSubmit={async (items, { resetForm }) => {
          // same shape as initial values
          const data = {
            ...items,
            ttl: Math.floor(items.expire.getTime() / 1000) + 365 * 2 * 86400
          };
          const job = items.job;
          console.log(data);
          addCmpnyJob({ cmpny, job, data });
          console.log("getting here");
          resetForm();
          //props.history.goBack();
        }}
      >
        {({
          values,
          errors,
          setFieldValue,
          setTouched,
          isSubmitting,
          isValid,
          touched,
          handleSubmit,
          handleBlur,
          ...rest
        }) => {
          console.log(values, touched, errors);
          /* region: data.region,
        email: data.email,
        phone: data.phoneNumber,
        job_name: data.job_name,
        latitude: data.latitude,
        role: data.role */
          return (
            <Dimmer.Dimmable as={Segment} dimmed={isAddJobLoading} blurring>
              <Dimmer active={isAddJobLoading} inverted>
                <Loader>Loading</Loader>
              </Dimmer>
              <Form>
                <SemanticField
                  component={Form.Input}
                  fluid
                  label="Job Number"
                  placeholder="Enter Company Job Number"
                  name="job"
                />
                <SemanticField
                  component={Form.Input}
                  fluid
                  label="Job Name"
                  placeholder="Job Name"
                  name="job_name"
                />
                <SemanticField
                  component={Form.Input}
                  fluid
                  label="Latitude"
                  placeholder="Latitude"
                  name="latitude"
                />
                <SemanticField
                  component={Form.Input}
                  fluid
                  label="Longitude"
                  placeholder="Longitude"
                  name="longitude"
                />
                <SemanticField
                  component={Form.Input}
                  fluid
                  label="Max Regular Hours"
                  placeholder="Enter Regular Shift Hours Allowed on Job"
                  name="maxRegHours"
                />
                <SemanticField
                  component={Form.Dropdown}
                  options={cmpnyRegionList}
                  fluid
                  label="Region"
                  placeholder="Select User Region"
                  selection
                  name="region"
                />
                <Field
                  component={DatePickerComponent}
                  label="Projected End Date"
                  name="expire"
                />

                <Form.Group>
                  <Form.Button
                    disabled={isSubmitting || !isValid}
                    type="submit"
                    onClick={handleSubmit}
                    primary
                  >
                    Create Job
                  </Form.Button>
                </Form.Group>
              </Form>
            </Dimmer.Dimmable>
          );
        }}
      </Formik>
    </div>
  );
};
