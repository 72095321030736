import React, { Fragment } from "react";
import { Header, Grid } from "semantic-ui-react";
import AdminEmpEdit from "./AdminEmpEdit";
import AdminEmpAdd from "./AdminEmpAdd";

function AdminSubs() {
  return (
    <Fragment>
      <Header block>EDIT COMPANY EMPLOYEES</Header>
      <Grid stackable columns={2}>
        <Grid.Column>
          <AdminEmpEdit />
        </Grid.Column>
        <Grid.Column>
          <AdminEmpAdd />
        </Grid.Column>
      </Grid>
    </Fragment>
  );
}

export default AdminSubs;
