import React, { useState, useCallback, useEffect, useMemo, memo } from "react";
import { useStoreState, useStoreActions } from "easy-peasy";
import {
  Dropdown,
  Header,
  Dimmer,
  Segment,
  Loader,
  Form,
  Radio
} from "semantic-ui-react";
import { Formik, Field } from "formik";
import { userFormEditSchema } from "./FormSchema";
import { SemanticField } from "../utils/SemanticField";
import MaskedInput from "react-text-mask";

const SemanticMaskInput = ({
  label,
  error,
  form: { handleChange, handleBlur },
  field: { name, value },
  ...otherProps
}) => {
  console.log(otherProps);
  return (
    <Form.Field>
      <label>{label}</label>
      <MaskedInput
        id={name}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        {...otherProps}
      />
    </Form.Field>
  );
};

const renderRadioField = ({
  label,
  error,
  checked,
  form: { setFieldValue, handleBlur, values },
  field: { name, value },
  ...otherProps
}) => {
  return (
    <Radio
      value={value}
      name={name}
      checked={values.role === checked}
      label={label}
      onChange={(e, { value }) => {
        setFieldValue(name, value);
      }}
      onBlur={handleBlur}
      {...otherProps}
    />
  );
};

const roleList = [
  { value: "PM", name: "Project Manager" },
  { value: "Super", name: "Superintendent" },
  { value: "RegionAdmin", name: "Regional Administrator" }
];

const convertPhoneNumber = x => {
  console.log(
    `${x.substring(0, 2)} (${x.substring(2, 5)}) ${x.substring(
      5,
      8
    )}-${x.substring(8, 12)}`
  );
  return `${x.substring(0, 2)} (${x.substring(2, 5)}) ${x.substring(
    5,
    8
  )}-${x.substring(8, 12)}`;
};

function AdminEditCode(props) {
  const cmpnyUsers = useStoreState(state => state.admin.cmpnyUsers);
  const cmpny = useStoreState(state => state.group);
  const isUsersLoading = useStoreState(state => state.admin.isUsersLoading);
  const [currentUser, setCurrentUser] = useState("");
  const [currentUserInitialState, setCurrentUserInitialState] = useState({
    email: "",
    fname: "",
    lname: "",
    phoneNumber: "",
    role: "",
    region: ""
  });

  const deleteCmpnyUser = useStoreActions(
    actions => actions.admin.delCmpnyUser
  );
  const addCmpnyUser = useStoreActions(actions => actions.admin.addCmpnyUser);
  const updateCmpnyUser = useStoreActions(
    actions => actions.admin.updateCmpnyUser
  );
  const fetchCmpnyUsers = useStoreActions(
    actions => actions.admin.fetchCmpnyUsers
  );
  const cmpnyUserList = useMemo(
    () =>
      Object.values(cmpnyUsers)
        .map(item => ({
          key: item.email,
          text: item.email,
          value: item.email
        }))
        .sort((a, b) =>
          a.key.localeCompare(b.key, undefined, {
            numeric: true,
            sensitivity: "base"
          })
        ),
    [cmpnyUsers]
  );
  const cmpnyRegionList = props.cmpnyRegionList;
  useEffect(() => {
    fetchCmpnyUsers(cmpny);
  }, [cmpny, fetchCmpnyUsers]);
  useEffect(() => {
    currentUser &&
      setCurrentUserInitialState({
        email: currentUser,
        fname: cmpnyUsers[currentUser].fname,
        lname: cmpnyUsers[currentUser].lname,
        phoneNumber: convertPhoneNumber(cmpnyUsers[currentUser].phoneNumber),
        role: cmpnyUsers[currentUser].role,
        region: cmpnyUsers[currentUser].region
      });
  }, [currentUser, cmpnyUsers]);

  const handleDropdownChange = useCallback(value => {
    console.log("handleDropdownChange re-rendered");
    setCurrentUser(value);
  }, []);

  const handleClick = () => {
    deleteCmpnyUser({ cmpny, email: currentUser });
    setCurrentUser("");
  };

  const handleAltSubmit = async items => {
    // same shape as initial values
    const data = {
      ...items,
      phoneNumber: items.phoneNumber.replace(/^(\+)|\D/g, "$1")
    };
    if (currentUser !== data.email) {
      await addCmpnyUser({ cmpny, data });
      await deleteCmpnyUser({ cmpny, email: currentUser });
      console.log("getting here");
    } else {
      console.log("update called");
      const updateData = {
        ...data,
        sub: cmpnyUsers[currentUser].sort_key.split("#")[1]
      };
      await updateCmpnyUser({ cmpny, updateData });
    }
    setCurrentUser("");
    //resetForm();
  };

  return (
    <Dimmer.Dimmable as={Segment} dimmed={isUsersLoading} blurring>
      <Dimmer active={isUsersLoading} inverted>
        <Loader>Loading</Loader>
      </Dimmer>
      <Header as="h3">Edit Company User</Header>
      <ExpensiveDropdown
        userOptions={cmpnyUserList}
        currentValue={currentUser}
        changeValue={handleDropdownChange}
      />
      {currentUser && (
        <Formik
          enableReinitialize //Allows Expensive Dropdown to set the Initial State
          initialValues={currentUserInitialState}
          validationSchema={userFormEditSchema(cmpnyUsers)}
          onSubmit={async items => {
            // same shape as initial values
            const data = {
              ...items,
              phoneNumber: items.phoneNumber.replace(/^(\+)|\D/g, "$1")
            };
            if (currentUser !== data.email) {
              await addCmpnyUser({ cmpny, data });
              await deleteCmpnyUser({ cmpny, email: currentUser });
              console.log("getting here");
            } else {
              console.log("update called");
              const updateData = {
                ...data,
                sub: cmpnyUsers[currentUser].sort_key.split("#")[1]
              };
              await updateCmpnyUser({ cmpny, updateData });
            }
            setCurrentUser("");
            //resetForm();
          }}
        >
          {({
            values,
            errors,
            setFieldValue,
            setTouched,
            isSubmitting,
            isValid,
            touched,
            handleSubmit,
            handleBlur,
            ...rest
          }) => {
            console.log(values);
            return (
              <Segment>
                <Form>
                  <SemanticField
                    component={Form.Input}
                    fluid
                    label="Email"
                    placeholder="Email Address"
                    name="email"
                    error={errors.email && touched.email ? errors.email : false}
                  />
                  <SemanticField
                    component={Form.Input}
                    fluid
                    label="First Name"
                    placeholder="First Name"
                    name="fname"
                    error={errors.fname && touched.fname ? errors.fname : false}
                  />
                  <SemanticField
                    component={Form.Input}
                    fluid
                    label="Last Name"
                    placeholder="Last Name"
                    name="lname"
                    error={errors.lname && touched.lname ? errors.lname : false}
                  />
                  <Form.Field label="Role">Role</Form.Field>
                  {roleList.map(item => {
                    return (
                      <Form.Field key={item.value}>
                        <Field
                          component={renderRadioField}
                          label={item.name}
                          value={item.value}
                          checked={item.value}
                          name="role"
                        />
                      </Form.Field>
                    );
                  })}
                  <SemanticField
                    component={Form.Dropdown}
                    options={cmpnyRegionList}
                    fluid
                    label="Region"
                    placeholder="Select User Region"
                    selection
                    name="region"
                    error={
                      errors.region && touched.region ? errors.region : false
                    }
                  />
                  <Field
                    component={SemanticMaskInput}
                    label="Mobile Number"
                    placeholder="XXX-XXX-XXXX"
                    mask={[
                      "+",
                      "1",
                      " ",
                      "(",
                      /[1-9]/,
                      /\d/,
                      /\d/,
                      ")",
                      " ",
                      /\d/,
                      /\d/,
                      /\d/,
                      "-",
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/
                    ]}
                    name="phoneNumber"
                    error={
                      errors.phoneNumber && touched.phoneNumber
                        ? errors.phoneNumber
                        : false
                    }
                  />
                  <Form.Button
                    disabled={isSubmitting || !isValid}
                    type="submit"
                    onClick={() => {
                      handleAltSubmit(values);
                    }}
                    color="green"
                  >
                    Update User
                  </Form.Button>
                  <Form.Button
                    disabled={isSubmitting}
                    onClick={handleClick}
                    negative
                  >
                    Delete User
                  </Form.Button>
                </Form>
              </Segment>
            );
          }}
        </Formik>
      )}
    </Dimmer.Dimmable>
  );
}

const ExpensiveDropdown = memo(({ userOptions, currentValue, changeValue }) => {
  console.log("Getting HERRRREEE:", currentValue);
  const handleChange = value => {
    console.log("change handler fired: ", value);
    changeValue(value);
  };
  console.log("this is the first Option: ", userOptions);
  // The rest of your rendering logic
  return (
    <Dropdown
      placeholder="Select User"
      fluid
      search
      selection
      clearable
      options={Object.values(userOptions)}
      value={currentValue}
      onChange={(e, { value }) => handleChange(value)}
    />
  );
});

export default AdminEditCode;
