import React, { Fragment } from "react";
import { Header, Grid, Dimmer, Loader } from "semantic-ui-react";
import AdminCodeEdit from "./AdminCodeEdit";
import AdminCodeAdd from "./AdminCodeAdd";
import { useStoreState } from "easy-peasy";

function AdminSubs() {
  const isCodesLoading = useStoreState(state => state.admin.isCodesLoading);
  return (
    <Fragment>
      <Header block>EDIT COMPANY COST CODES</Header>
      <Dimmer.Dimmable dimmed={isCodesLoading} blurring>
        <Dimmer active={isCodesLoading} inverted>
          <Loader>Loading</Loader>
        </Dimmer>
        <Grid stackable columns={2}>
          <Grid.Column>
            <AdminCodeEdit />
          </Grid.Column>
          <Grid.Column>
            <AdminCodeAdd />
          </Grid.Column>
        </Grid>
      </Dimmer.Dimmable>
    </Fragment>
  );
}

export default AdminSubs;
