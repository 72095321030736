import { createSelector } from "reselect";
import moment from "moment";
import { changeObjectKey } from "../utils/changeObjectKey";

const getJob = (state, props) => props.match.params.selectedJob;
const getJobInfo = state => state.jobs.entities.jobs;
const getDay = (state, props) => {
  return moment(props.match.params.selectedDay).format("YYYY-MM-DD");
};
const getEmployeesByJob = state => state.employeesByJob;
const getEmployeesInSummary = state => state.employeesInSummary;
const getCostCodesByJob = state => state.costCodesByJob;
const getSubsByJob = state => state.subsByJob;
const getWeekNo = (state, props) => {
  console.log(
    "week addition: ",
    String(moment("2018-11-11").week() + 1).padStart(2, "0")
  );
  return moment(props.match.params.selectedDay).week();
};
const getTime = (state, props) => {
  return Object.keys(state.time.byId).length === 0
    ? []
    : Object.values(state.time.byId).filter(
        item => item.pk === `${state.group}#${props.match.params.selectedJob}`
      );
};

const getEmployeesWithTimeOnJob = (state, props) => {
  return typeof state.employeesInSummary[props.match.params.selectedJob] ===
    "undefined" ||
    Object.values(state.employeesInSummary[props.match.params.selectedJob])
      .length === 0
    ? []
    : Object.values(
        state.employeesInSummary[props.match.params.selectedJob]
      ).map(x => x.emp);
};

const getReport = (state, props) => {
  return Object.keys(state.report.byId).length === 0
    ? []
    : Object.values(state.report.byId).filter(
        item => item.pk === `${state.group}#${props.match.params.selectedJob}`
      );
};

const getSortedEmployees = createSelector(
  state => state.allEmployees,
  employees => {
    console.log("INSIDE SELECTOR", employees);
    //get an array of employee numbers sorted by employee's trade and last name
    return Object.values(employees)
      .sort((a, b) => {
        if (a.trade < b.trade) {
          return -1;
        }
        if (a.trade > b.trade) {
          return 1;
        }
        if (a.lname < b.lname) {
          return -1;
        }
        if (a.lname > b.lname) {
          return 1;
        }
        // names must be equal
        return 0;
      })
      .map(item => item.emp);
  }
);

//filter by week
const getTotals = (state, props) => {
  return Object.keys(state.timeTotalsByWeek).length === 0
    ? []
    : Object.values(state.timeTotalsByWeek).filter(
        item =>
          item.pk.split("#")[2] ===
          `${String(
            moment(props.match.params.selectedDay).weekYear()
          )}-${String(moment(props.match.params.selectedDay).week()).padStart(
            2,
            "0"
          )}`
      );
};

export const makeGetCostCodesByJob = () =>
  createSelector(
    getJob,
    getCostCodesByJob,
    (job, costCodes) => {
      return (
        costCodes[job] &&
        costCodes[job].sort((a, b) =>
          a.cc.localeCompare(b.cc, undefined, {
            numeric: true,
            sensitivity: "base"
          })
        )
      );
    }
  );

export const makeGetSubsByJob = () =>
  createSelector(
    getJob,
    getSubsByJob,
    (job, subs) => {
      return (
        subs[job] &&
        subs[job].sort((a, b) => a.sub_name.localeCompare(b.sub_name))
      );
    }
  );

export const makeGetEmployeesByJob = () =>
  createSelector(
    getJob,
    getEmployeesByJob,
    (job, employees) =>
      employees[job] &&
      employees[job].sort(function(a, b) {
        if (a.trade < b.trade) {
          return -1;
        }
        if (a.trade > b.trade) {
          return 1;
        }
        if (a.lname < b.lname) {
          return -1;
        }
        if (a.lname > b.lname) {
          return 1;
        }
        // names must be equal
        return 0;
      })
  );

export const makeGetJobInfo = () =>
  createSelector(
    getJob,
    getJobInfo,
    (job, info) => info[job]
  );

export const makeGetTimeByEmployee = () =>
  createSelector(
    [getTime, getSortedEmployees, getWeekNo, getDay],
    (time, employees, weekNo, currentDay) => {
      //pass array of objects and key to organize object
      const filteredSortedTime = time
        .filter(item => Number(item.we) === weekNo && item.date === currentDay)
        .sort((a, b) => employees.indexOf(a.emp) - employees.indexOf(b.emp));
      return (
        time &&
        employees && {
          sortedList: [...new Set(filteredSortedTime.map(item => item.emp))],
          byEmp: changeObjectKey(filteredSortedTime, "emp")
        }
      );
    }
  );

export const makeGetTotalsByEmployee = () =>
  createSelector(
    [getTotals, getEmployeesInSummary, getJob],
    (totals, employees, job) => {
      const totalTimeFiltered = Object.values(totals).filter(el => {
        return (
          employees[job] &&
          Object.values(employees[job]).some(f => {
            return f.emp === el.sk;
          })
        );
      });
      return totalTimeFiltered.reduce(function(acc, cur) {
        acc[cur.sk] = cur;
        return acc;
      }, {});
    }
  );

export const makeGetReport = () =>
  createSelector(
    [getReport, getWeekNo, getDay],
    (report, weekNo, currentDay) => {
      const dayReport = report.filter(
        item =>
          Number(item.sk.split("#")[2].split("-")[1]) === weekNo &&
          item.sk.split("#")[3] === currentDay
      );
      //pass array of objects and key to organize object
      const reportStateObject = dayReport.reduce(
        (agg, item) => {
          switch (item.sk.split("#")[4]) {
            case "r":
              return { ...agg, report: item };
            case "s":
              return { ...agg, subs: { ...agg["subs"], [item.sub_no]: item } };
            case "eq":
              return { ...agg, eq: { ...agg["eq"], [item.eq_no]: item } };
            default:
              return agg;
          }
        },
        {
          isSaveDisabled: true,
          isPrintDisabled: false,
          report: {
            issues: "",
            delays: "",
            safety: "",
            general: "",
            extra: ""
          },
          subs: {},
          eq: {}
        }
      );
      return reportStateObject;
    }
  );

//Selectors for use with Hooks in Time Summary
export const makeGetEmployeesWithTime = () =>
  createSelector(
    getTime,
    getEmployeesWithTimeOnJob,
    (time, employees) => {
      console.log("LOOOOK: ", time, employees);
      let timeObjs =
        time &&
        time.filter(
          timeItem => timeItem.emp && !employees.includes(timeItem.emp)
        );
      return employees.length > 0
        ? [...new Set(timeObjs.map(item => item.emp))]
        : [];
    }
  );
