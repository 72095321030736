import React from "react";
import { Switch } from "react-router-dom";
import AdminRoute from "../components/AdminRoute";
import RegionAdminRoute from "../components/RegionAdminRoute";
import SuperAdminRoute from "../components/SuperAdminRoute";
import AdminHome from "./AdminHome";
import AdminEmployees from "./AdminEmployees";
import AdminSubs from "./AdminSubs";
import AdminEquip from "./AdminEquip";
import AdminEditJob from "./AdminEditJob";
import AdminCodes from "./AdminCodes";
import AdminUsers from "./AdminUsers";
import AdminJobAdd from "./AdminJobAdd";
import AdminTimeFetch from "./AdminTimeFetch";

export default ({ childProps }) => (
  <Switch>
    <AdminRoute exact path="/admin" props={childProps} component={AdminHome} />
    <RegionAdminRoute
      exact
      path="/admin/subs"
      props={childProps}
      component={AdminSubs}
    />
    <RegionAdminRoute
      exact
      path="/admin/addjob"
      props={childProps}
      component={AdminJobAdd}
    />
    <RegionAdminRoute
      exact
      path="/admin/time"
      props={childProps}
      component={AdminTimeFetch}
    />
    <AdminRoute
      exact
      path="/admin/equip"
      props={childProps}
      component={AdminEquip}
    />
    <RegionAdminRoute
      exact
      path="/admin/employees"
      props={childProps}
      component={AdminEmployees}
    />
    <SuperAdminRoute
      exact
      path="/admin/codes"
      props={childProps}
      component={AdminCodes}
    />
    <SuperAdminRoute
      exact
      path="/admin/users"
      props={childProps}
      component={AdminUsers}
    />
    <AdminRoute
      exact
      path="/admin/:selectedJob"
      props={childProps}
      component={AdminEditJob}
    />
  </Switch>
);
