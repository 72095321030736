//change keys of an object and add duplicates to array
export const changeObjectKey = (objArray, key) => {
  console.log(Object.values(objArray));
  return Object.values(objArray).reduce((acc, item) => {
    if (acc[item[key]]) {
      acc[item[key]] = acc[item[key]].isArray
        ? acc[item[key]].concat(item)
        : acc[item[key]].concat([item]);
    } else {
      acc[item[key]] = [item];
    }
    return acc;
  }, {});
};
