import { action, thunk } from "easy-peasy"; // 👈 import
import { API } from "aws-amplify";
import { deleteApi } from "../sagas";
import { toast } from "react-toastify";

export const jobUserModel = {
  updateIsLoading: action((state, payload) => {
    console.log("LOADING PAYLOAD: ", payload);
    state[`is${payload.name}Loading`] = payload.toggle;
  }),
  jobUsers: {},
  fetchJobUsers: thunk(async (actions, payload) => {
    const { cmpny, job } = payload;
    console.log("PAYLOAD ISSUE: ", payload);
    const data = await API.get("time", `admin/${cmpny}/user/${job}`);
    console.log(data);
    const dataObj = data.reduce((agg, item) => {
      return {
        ...agg,
        [item.sort_key.split("#")[1]]: item
      };
    }, {});
    actions.updateJobUser({ job, dataObj });
  }),
  addJobUser: thunk(async (actions, payload) => {
    console.log("User SUB: ", payload);
    const { cmpny, job } = payload;
    actions.updateIsLoading({ toggle: true, name: "JobUser" });
    try {
      const data = await API.post("time", `admin/${cmpny}/user/${job}`, {
        body: payload.data
      });
      if (typeof data === "undefined") {
        let err = `Unable to Add ${payload.sub}`;
        actions.updateIsLoading({ toggle: false, name: "JobUser" });
        toast.error(err, { containerId: "general" });
        throw new Error(err);
      }
      actions.updateIsLoading({ toggle: false, name: "JobUser" });
      console.log("added User data: ", data);
      const dataObj = { [data.sort_key.split("#")[1]]: data };
      actions.updateJobUser({ job, dataObj });
      toast.success("Successfully Updated", { containerId: "general" });
    } catch (error) {
      actions.updateIsLoading({ toggle: false, name: "JobUser" });
      toast.error(error, { containerId: "general" });
      throw error;
    }
  }),
  deleteJobUser: thunk(async (actions, payload) => {
    const { cmpny, job } = payload;
    actions.updateIsLoading({ toggle: true, name: "JobUser" });
    try {
      const data = await deleteApi("time", `admin/${cmpny}/user/${job}`, {
        sort_key: payload.sort_key
      });
      if (typeof data === "undefined") {
        let err = "Unable to Delete User";
        actions.updateIsLoading({ toggle: false, name: "JobUser" });
        toast.error(err, { containerId: "general" });
        throw new Error(err);
      }
      actions.updateIsLoading({ toggle: false, name: "JobUser" });
      console.log("Returned DELETE DATA: ", data);
      actions.delJobUser(payload);
      toast.success("Successfully Updated", { containerId: "general" });
    } catch (error) {
      actions.updateIsLoading({ toggle: false, name: "JobUser" });
      toast.error(error, { containerId: "general" });
      throw error;
    }
  }),
  /*SUB ACTIONS */
  updateJobUser: action((state, payload) => {
    console.log("PAYLOAD: ", payload);
    state.jobUsers = {
      ...state.jobUsers,
      [payload.job]: {
        ...state.jobUsers[payload.job],
        ...payload.dataObj
      }
    };
  }),
  delJobUser: action((state, payload) => {
    console.log("PAYLOAD: ", payload);
    let { [payload.sort_key.split("#")[1]]: _, ...temp } = state.jobUsers[
      payload.job
    ];
    state.jobUsers[payload.job] = temp;
  })
};
