import React, { useState, useCallback, useEffect, useMemo, memo } from "react";
import { useStoreState, useStoreActions } from "easy-peasy";
import {
  Dropdown,
  Header,
  Dimmer,
  Segment,
  Loader,
  Form
} from "semantic-ui-react";
import { Formik } from "formik";
import { ccFormEditSchema } from "./FormSchema";
import { SemanticField } from "../utils/SemanticField";

function AdminEditCode() {
  const cmpnyCostCodes = useStoreState(state => state.admin.cmpnyCostCodes);
  const cmpny = useStoreState(state => state.group);
  const isCCLoading = useStoreState(state => state.admin.isCCLoading);
  const [currentCC, setCurrentCC] = useState("");
  const [currentCCInitialState, setCurrentCCInitialState] = useState({
    cc: "",
    descr: ""
  });
  console.log("CURRENT CC:", currentCC);
  console.log("INITIAL STATE:", currentCCInitialState);
  const deleteCmpnyCostCode = useStoreActions(
    actions => actions.admin.deleteCmpnyCostCode
  );
  const addCmpnyCostCode = useStoreActions(
    actions => actions.admin.addCmpnyCostCode
  );
  const fetchCmpnyCostCodes = useStoreActions(
    actions => actions.admin.fetchCmpnyCostCodes
  );
  const cmpnyCCList = useMemo(
    () =>
      Object.values(cmpnyCostCodes)
        .map(item => ({
          key: item.cc,
          text: `${item.cc} - ${item.descr}`,
          value: item.cc
        }))
        .sort((a, b) =>
          a.key.localeCompare(b.key, undefined, {
            numeric: true,
            sensitivity: "base"
          })
        ),
    [cmpnyCostCodes]
  );

  useEffect(() => {
    fetchCmpnyCostCodes(cmpny);
  }, [cmpny, fetchCmpnyCostCodes]);
  useEffect(() => {
    currentCC &&
      setCurrentCCInitialState({
        cc: currentCC,
        descr: cmpnyCostCodes[currentCC].descr
      });
  }, [currentCC, cmpnyCostCodes]);

  const handleDropdownChange = useCallback(value => {
    console.log("handleDropdownChange re-rendered");
    setCurrentCC(value);
  }, []);

  const handleClick = () => {
    deleteCmpnyCostCode({ cmpny, cc: currentCC });
    setCurrentCC("");
  };

  return (
    <Dimmer.Dimmable as={Segment} dimmed={isCCLoading} blurring>
      <Dimmer active={isCCLoading} inverted>
        <Loader>Loading</Loader>
      </Dimmer>
      <Header as="h3">Edit Cost Code</Header>
      <ExpensiveDropdown
        ccOptions={cmpnyCCList}
        currentValue={currentCC}
        changeValue={handleDropdownChange}
      />
      {currentCC && (
        <Formik
          enableReinitialize //Allows Expensive Dropdown to set the Initial State
          initialValues={currentCCInitialState}
          validationSchema={ccFormEditSchema(
            cmpnyCostCodes,
            currentCCInitialState.cc
          )}
          onSubmit={async (data, { resetForm }) => {
            // same shape as initial values
            await addCmpnyCostCode({ cmpny, data });
            let tempCC = currentCC;
            if (currentCC !== data.cc) {
              setCurrentCC("");
              await deleteCmpnyCostCode({ cmpny, cc: tempCC });
              console.log("getting here");
            }
            //resetForm();
          }}
        >
          {({
            values,
            errors,
            setFieldValue,
            setTouched,
            isSubmitting,
            isValid,
            touched,
            handleSubmit,
            handleBlur,
            ...rest
          }) => {
            return (
              <Segment>
                <Form>
                  <SemanticField
                    component={Form.Input}
                    fluid
                    label="Cost Code"
                    placeholder="Cost Code"
                    name="cc"
                  />
                  <SemanticField
                    component={Form.Input}
                    fluid
                    label="Cost Code Description"
                    placeholder="Cost Code Description"
                    name="descr"
                  />
                  <Form.Button
                    disabled={isSubmitting || !isValid}
                    type="submit"
                    onClick={handleSubmit}
                    color="green"
                  >
                    Update Cost Code
                  </Form.Button>
                  <Form.Button
                    disabled={isSubmitting}
                    onClick={handleClick}
                    negative
                  >
                    Delete Cost Code
                  </Form.Button>
                </Form>
              </Segment>
            );
          }}
        </Formik>
      )}
    </Dimmer.Dimmable>
  );
}

const ExpensiveDropdown = memo(({ ccOptions, currentValue, changeValue }) => {
  console.log("Getting HERRRREEE:", currentValue);
  const handleChange = value => {
    console.log("change handler fired: ", value);
    changeValue(value);
  };
  console.log("this is the first Option: ", ccOptions);
  // The rest of your rendering logic
  return (
    <Dropdown
      placeholder="Cost Code"
      fluid
      search
      selection
      clearable
      options={Object.values(ccOptions)}
      value={currentValue}
      onChange={(e, { value }) => handleChange(value)}
    />
  );
});

export default AdminEditCode;
