import React from "react";
import { Form, Grid, Segment } from "semantic-ui-react";
import LoaderButton from "../components/LoaderButton";
import { Formik } from "formik";
import * as Yup from "yup";
import { SemanticField } from "../utils/SemanticField";

const LoginResetPassword = ({ submitFuction, setIsSubmitting }) => (
  <Formik
    initialValues={{
      email: ""
    }}
    validationSchema={Yup.object().shape({
      email: Yup.string().required("Required")
    })}
    onSubmit={(values, { setSubmitting, resetForm }) => {
      setIsSubmitting(true);
      submitFuction(values);
      resetForm();
    }}
  >
    {({ isSubmitting, isValid, handleSubmit }) => {
      return (
        <Grid.Column style={{ maxWidth: 450 }}>
          <Form>
            <Segment stacked>
              <SemanticField
                component={Form.Input}
                name="email"
                label="Email"
                placeholder="Email Address"
                fluid
              />
              <LoaderButton
                size="large"
                text="Send Confirmation"
                disabled={!isValid}
                isSubmitting={isSubmitting}
                loadingText="Sending Confirmation…"
                type="submit"
                onClick={handleSubmit}
              />
            </Segment>
          </Form>
        </Grid.Column>
      );
    }}
  </Formik>
);

export default LoginResetPassword;
