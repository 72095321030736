import React, { useEffect, useState, useReducer } from "react";
import { Table } from "semantic-ui-react";
import Modal from "../timeentrymodal/modalForm";
import ModalFormComponent from "../timeentrymodal/ModalFormComponent";
import { useSelector, useDispatch } from "react-redux";
import { makeGetEmployeesWithTime } from "../timesheet/timesheetSelectors";
import { API } from "aws-amplify";
import { UPDATE_MISSING_EMPLOYEE_DATA } from "../timesheet/TimesheetActions";

const TimeSummary = props => {
  const [mobileScreen, setMobileScreen] = useState(false);
  const { cmpny, onLoading } = props;
  const getListofEmployeesOffJob = React.useMemo(makeGetEmployeesWithTime, []);
  const newList = useSelector(state => getListofEmployeesOffJob(state, props));
  console.log("actual selector values: ", newList);
  const thing = newList.length;
  const reduxDispatch = useDispatch();
  const [employeesOffJob, dispatch] = useReducer(reducer, {});
  const { employees, time, costCodes, jobInfo, totals } = props;
  const selectedJob = props.match.params.selectedJob;
  const selectedDay = props.match.params.selectedDay;
  console.log(employees, time);
  console.log("time totals are :", totals);

  function reducer(state, action) {
    if (action.type === "fetched") {
      const filteredResponse = action.response.filter(x =>
        newList.includes(x.empl)
      );
      const data = filteredResponse.map(({ empl: info, ...rest }) => ({
        emp: info,
        ...rest
      }));
      reduxDispatch({
        type: UPDATE_MISSING_EMPLOYEE_DATA,
        data,
        job: props.match.params.selectedJob
      });
      props.offLoading();
      return;
    } else {
      props.offLoading();
      throw new Error();
    }
  }

  useEffect(() => {
    async function fetchMyAPI() {
      console.log("API IS CALLED HERE");
      onLoading();
      let response = await API.get("time", `time/${cmpny}/search`);
      console.log("API IS FINISHED HERE", response);
      dispatch({ type: "fetched", response });
    }
    if (thing > 0) {
      fetchMyAPI();
    }
  }, [thing, cmpny, onLoading]);
  useEffect(() => {
    window.innerWidth <= 760 ? setMobileScreen(true) : setMobileScreen(false);
  });

  return time &&
    employees &&
    Object.keys(employees).length !== 0 &&
    costCodes &&
    totals ? (
    <Table unstackable celled structured>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell rowSpan="1">Name</Table.HeaderCell>
          <Table.HeaderCell rowSpan="1">Cost Code</Table.HeaderCell>
          <Table.HeaderCell rowSpan="1">Hours</Table.HeaderCell>
          <Table.HeaderCell rowSpan="1">Edit</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {time.sortedList.map(employee => {
          return (
            employees[employee] &&
            time.byEmp[employee].map((item, index) =>
              index === 0 ? (
                <Table.Row key={`${employee}_${index}`}>
                  <Table.Cell
                    rowSpan={time.byEmp[employee].length}
                    verticalAlign="top"
                  >
                    {employees[employee].lname}, {employees[employee].fname}
                    <br />
                    {employees[employee].clss === "J" ||
                    typeof employees[employee].clss === "undefined"
                      ? `${employees[employee].trade}`
                      : `${employees[employee].trade} (${employees[employee].clss})`}
                    <br />
                    Reg: {(totals[employee] && totals[employee].r) ||
                      0} OT: {(totals[employee] && totals[employee].ot) || 0}{" "}
                    {totals[employee] && totals[employee].hasOwnProperty("2x")
                      ? `2x: ${totals[employee]["2x"]}`
                      : ""}
                    {totals[employee] && totals[employee].hasOwnProperty("2nd")
                      ? `2nd: ${totals[employee]["2nd"]}`
                      : ""}
                    <br />
                  </Table.Cell>
                  <Table.Cell>
                    {item.cc}
                    {!mobileScreen && <br />}
                    {!mobileScreen && item.spcl}
                    {!mobileScreen && <br />}
                    {!mobileScreen && item.desc}
                  </Table.Cell>
                  <Table.Cell textAlign="right">
                    {item.type !== "r"
                      ? `${item.hours} ${item.type}`
                      : item.hours}
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    <Modal
                      modalEmployee={{
                        key: employee,
                        value: employee,
                        text: `${employees[employee].fname} ${employees[employee].lname}`
                      }}
                      selectedCostCode={item.cc}
                      selectedSpcl={item.spcl}
                      selectedType={item.type}
                      currentHours={item.hours}
                      currentDesc={item.desc}
                      selectedJob={selectedJob}
                      selectedDay={selectedDay}
                      costCodes={costCodes}
                      jobInfo={jobInfo}
                      buttonName="Edit"
                      disabled={typeof employees[employee].clss === "undefined"}
                      render={props => (
                        <ModalFormComponent
                          handleClose={props.handleClose}
                          {...props}
                        />
                      )}
                    />
                  </Table.Cell>
                </Table.Row>
              ) : (
                <Table.Row key={employee + index}>
                  <Table.Cell>
                    {item.cc}
                    {!mobileScreen && <br />}
                    {!mobileScreen && item.spcl}
                    {!mobileScreen && <br />}
                    {!mobileScreen && item.desc}
                  </Table.Cell>
                  <Table.Cell textAlign="right">
                    {item.type !== "r"
                      ? `${item.hours} ${item.type}`
                      : item.hours}
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    <Modal
                      modalEmployee={{
                        key: employee,
                        value: employee,
                        text: `${employees[employee].fname} ${employees[employee].lname}`
                      }}
                      selectedCostCode={item.cc}
                      selectedType={item.type}
                      selectedSpcl={item.spcl}
                      currentHours={item.hours}
                      currentDesc={item.desc}
                      selectedJob={selectedJob}
                      selectedDay={selectedDay}
                      costCodes={costCodes}
                      jobInfo={jobInfo}
                      buttonName="Edit"
                      disabled={typeof employees[employee].clss === "undefined"}
                      render={props => (
                        <ModalFormComponent
                          handleClose={props.handleClose}
                          {...props}
                        />
                      )}
                    />
                  </Table.Cell>
                </Table.Row>
              )
            )
          );
        })}
      </Table.Body>
    </Table>
  ) : (
    <div>There is no data to display for this date.</div>
  );
};
export default TimeSummary;
