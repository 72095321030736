import React, { Component } from "react";
import LandingContainer from "../landing/LandingContainer";
import "./Home.css";
import styled from "styled-components";

export default class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      notes: []
    };
  }
  renderNotesList(notes) {
    return null;
  }

  renderLander() {
    return (
      <div className="Home">
        <div className="lander">
          <h1>Murnane Timekeeper</h1>
          <p>Daily Report Application for Contractors</p>
        </div>
      </div>
    );
  }

  renderNotes() {
    const Header = styled.h1`
      text-align: center;
    `;
    return (
      <div className="notes">
        <Header>Job List</Header>
        <LandingContainer />
      </div>
    );
  }

  render() {
    return (
      <div className="Home">
        {this.props.isAuthenticated ? this.renderNotes() : this.renderLander()}
      </div>
    );
  }
}
