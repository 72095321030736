import React, { useReducer, useEffect } from "react";

const ReportStateContext = React.createContext();
const ReportDispatchContext = React.createContext();

function reducer(state, action) {
  switch (action.type) {
    case "changeField":
      console.log("THIS IS THE PROBLEM: ", typeof action.date === "undefined");
      return {
        ...state,
        isSaveDisabled: false,
        [action.fieldType]: {
          ...state[action.fieldType],
          [action.id]: action.value,
          ...(typeof action.date !== "undefined" && { date: action.date })
        }
      };
    case "deleteField":
      console.log("we got to delete field", state[action.fieldType]);
      let newState = Object.keys(state[action.fieldType]).reduce((r, e) => {
        if (e !== action.id) {
          r[e] = state[action.fieldType][e];
        }
        return r;
      }, {});
      return {
        ...state,
        isSaveDisabled: false,
        [action.fieldType]: newState
      };
    case "addField":
      return {
        ...state,
        isSaveDisabled: false,
        [action.fieldType]: {
          ...state[action.fieldType],
          [action.id]: action.value
        }
      };
    case "replaceField":
      console.log("action: ", action);
      console.log(state[action.fieldType]);
      const { [action.id]: tempVal, ...restOfFieldState } = state[
        action.fieldType
      ];
      const {
        [action.nameFieldName]: x,
        [action.idFieldName]: y,
        ...restOfCurrentObject
      } = tempVal;
      const fieldObj = {
        [action.idFieldName]: action.value,
        [action.nameFieldName]: action.name,
        ...restOfCurrentObject
      };
      const newStateObj = { ...restOfFieldState, [action.value]: fieldObj };
      return {
        ...state,
        isSaveDisabled: false,
        [action.fieldType]: newStateObj
      };

    case "replaceWithGlobalProps":
      return action.value;

    default:
      throw new Error();
  }
}

function ReportProvider({ children, reduxState }) {
  //keep two state objects to track changes to add or delete items in the database
  const [state, dispatch] = useReducer(reducer, reduxState);
  useEffect(() => {
    console.log("USEEFFECT CALLED - UPDATING STATE WITH: ", reduxState);
    dispatch({ type: "replaceWithGlobalProps", value: reduxState });
  }, [reduxState]);
  return (
    <ReportStateContext.Provider value={{ state }}>
      <ReportDispatchContext.Provider value={{ dispatch }}>
        {children}
      </ReportDispatchContext.Provider>
    </ReportStateContext.Provider>
  );
}

function useReportState() {
  const context = React.useContext(ReportStateContext);
  if (context === undefined) {
    throw new Error("useCountState must be used within a CountProvider");
  }
  return context;
}
function useReportDispatch() {
  const context = React.useContext(ReportDispatchContext);
  if (context === undefined) {
    throw new Error("useCountDispatch must be used within a CountProvider");
  }
  return context;
}
export { ReportProvider, useReportState, useReportDispatch };
