import React, { useState, useEffect, useMemo } from "react";
import { useStoreState, useStoreDispatch, useStoreActions } from "easy-peasy";
import {
  Dropdown,
  Header,
  Button,
  Segment,
  List,
  Dimmer,
  Loader
} from "semantic-ui-react";
import { ccActionParams } from "../timesheet/TimesheetApiParams";
import { requestData } from "../api/actions";
import { shapeCCData } from "../utils/shapeFormData";
import styled from "styled-components";

const DD = styled(Dropdown)`
  margin: 10px 0px 10px;
`;

const AddButton = styled(Button)`
  margin-bottom: 10px;
`;

function AdminEditJobCodes({ job }) {
  const dispatch = useStoreDispatch();
  const [isDisabled, setIsDisabled] = useState(true);
  const [currentValue, setCurrentValue] = useState([]);
  //this is not part of the admin state, just a selector for the costCodesByJob part of the state.
  const costCodes = useStoreState(state => state.costCodesByJob);
  const cmpnyCostCodes = useStoreState(state => state.admin.cmpnyCostCodes);
  const jobInfo = useStoreState(state => state.jobs.entities.jobs[job]);
  const cmpny = useStoreState(state => state.group);
  const isCCLoading = useStoreState(state => state.admin.isCCLoading);
  console.log("LOADING STATUS: ", isCCLoading);

  const fetchCmpnyCostCodes = useStoreActions(
    actions => actions.admin.fetchCmpnyCostCodes
  );
  const addCmpnyCostCodes = useStoreActions(
    actions => actions.admin.addJobCostCodes
  );
  const delCmpnyCostCodes = useStoreActions(
    actions => actions.admin.delJobCostCodes
  );
  const jobCostCodes = useMemo(() => {
    console.log("LARGE LIST RENDERED");
    return (
      costCodes[job] &&
      costCodes[job].sort((a, b) =>
        a.cc.localeCompare(b.cc, undefined, {
          numeric: true,
          sensitivity: "base"
        })
      )
    );
  }, [job, costCodes]);
  const cmpnyCodes = useMemo(
    () =>
      Object.values(cmpnyCostCodes)
        .map(item => ({
          key: item.cc,
          value: item.cc,
          text: `${item.cc} - ${item.descr}`
        }))
        .sort((a, b) =>
          a.key.localeCompare(b.key, undefined, {
            numeric: true,
            sensitivity: "base"
          })
        ),
    [cmpnyCostCodes]
  );
  useEffect(() => {
    dispatch(requestData(ccActionParams(cmpny, job)));
  }, [cmpny, job, dispatch]);
  useEffect(() => {
    fetchCmpnyCostCodes(cmpny);
  }, [cmpny, fetchCmpnyCostCodes]);

  return (
    <Dimmer.Dimmable
      as={Segment}
      dimmed={isCCLoading}
      blurring
      style={{ maxWidth: "800px" }}
    >
      <Dimmer active={isCCLoading} inverted>
        <Loader>Loading</Loader>
      </Dimmer>
      <Header as="h3">Edit Cost Codes</Header>
      <DD
        placeholder="Select Company Cost Codes"
        fluid
        search
        multiple
        selection
        clearable
        options={cmpnyCodes}
        value={currentValue}
        onChange={(e, { value }) => {
          console.log("VALUE: ", value);
          setCurrentValue(value);
          value.length !== 0 ? setIsDisabled(false) : setIsDisabled(true);
        }}
      />
      <AddButton
        disabled={isDisabled}
        primary
        onClick={() => {
          addCmpnyCostCodes({
            data: shapeCCData(
              currentValue,
              cmpnyCostCodes,
              cmpny,
              jobInfo,
              job
            ),
            job,
            cmpny
          });
          setCurrentValue([]);
        }}
      >
        Add Cost Codes
      </AddButton>
      <List divided relaxed>
        {jobCostCodes &&
          jobCostCodes.map(code => {
            return (
              <List.Item key={code.cc}>
                {code.cc} - {code.descr}
                <List.Content floated="right">
                  <Button
                    onClick={() => {
                      delCmpnyCostCodes({
                        cc: code.cc,
                        job,
                        cmpny
                      });
                    }}
                    negative
                    size="small"
                  >
                    Delete
                  </Button>
                </List.Content>
              </List.Item>
            );
          })}
      </List>
    </Dimmer.Dimmable>
  );
}

export default AdminEditJobCodes;
