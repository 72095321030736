import React from "react";
import styled from "styled-components";
import ReportSummaryComponent from "./ReportSummaryComponent";
import TimeSummary from "./TimeSummary";

const TimeMargin = styled.div`
  margin-top: 16px;
`;
const ReportMargin = styled.div`
  padding-top: 40px;
  line-height: 22px;
`;

const TimeSummaryContainer = props => {
  //Introducing hooks for report component, since this was added after original app
  //Idea is to keep store, pass initial state to form as props
  //Track modifications to the form and only submit these, to prevent unneccessary api calls

  return (
    <div>
      {props.activeItem === "clock outline" && (
        <TimeMargin>
          <TimeSummary {...props} />
        </TimeMargin>
      )}
      {props.activeItem === "file alternate outline" && (
        <ReportMargin>
          <ReportSummaryComponent {...props} />
        </ReportMargin>
      )}
    </div>
  );
};

export default TimeSummaryContainer;
