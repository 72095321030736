import React from "react";
import "./Landing.css";
import { Button } from "semantic-ui-react";

const JobList = ({ jobs, handleClick }) => {
  console.log(jobs);
  console.log(Object.keys(jobs).length === 0 ? "" : jobs.entities.jobs);
  /* {jobs.map(job => (
      <div>${job.name}</div>
))} */

  return Object.keys(jobs).length === 0 ? (
    <div>Loading...</div>
  ) : (
    <div className="button-grid">
      {jobs.result.map(item => (
        <Button size="large" key={item} onClick={() => handleClick(item)}>
          {item} - {jobs.entities.jobs[item].job_name}
        </Button>
      ))}
    </div>
  );
};

export default JobList;
