import React, { Fragment, useEffect, useMemo } from "react";
import { Header, Grid } from "semantic-ui-react";
import AdminSubsAdd from "./AdminSubsAdd";
import AdminSubsEdit from "./AdminSubsEdit";
import { useStoreState, useStoreActions } from "easy-peasy";

const AdminUsers = props => {
  const cmpnySubs = useStoreState(state => {
    console.log(state);
    return state.admin.subs.cmpnySubs;
  });
  const cmpny = useStoreState(state => state.group);
  const fetchSubs = useStoreActions(
    actions => actions.admin.subs.fetchCmpnySubs
  );
  console.log(cmpnySubs);
  const cmpnySubList = useMemo(
    () =>
      Object.values(cmpnySubs)
        .map(item => ({
          key: item.sub_no,
          value: item.sub_no,
          text: `${item.sub_no} - ${item.sub_name}`
        }))
        .sort((a, b) =>
          a.key.localeCompare(b.key, undefined, {
            numeric: true,
            sensitivity: "base"
          })
        ),
    [cmpnySubs]
  );
  useEffect(() => {
    fetchSubs(cmpny);
  }, [cmpny, fetchSubs]);
  return (
    <Fragment>
      <Header block>EDIT COMPANY SUBCONTRACTORS</Header>
      <Grid stackable columns={2}>
        <Grid.Column>
          <AdminSubsEdit
            cmpnySubList={cmpnySubList}
            cmpnySubs={cmpnySubs}
            cmpny={cmpny}
          />
        </Grid.Column>
        <Grid.Column>
          <AdminSubsAdd
            cmpnySubList={cmpnySubList}
            cmpnySubs={cmpnySubs}
            cmpny={cmpny}
          />
        </Grid.Column>
      </Grid>
    </Fragment>
  );
};

export default AdminUsers;
