import React from "react";
import { Switch } from "react-router-dom";
import Home from "./containers/Home";
import LoginContainer from "./login/LoginContainer";
import NotFound from "./notfound/NotFound";
import AdminContainer from "./admin/AdminContainer";
import CalendarContainer from "./calendar/CalendarContainer";
import Timesheet from "./timesheet/Timesheet";
import EmployeeContainer from "./employee/EmployeeContainer";
import AppliedRoute from "./components/AppliedRoute";
import AuthenticatedJobRoute from "./components/AuthenticatedJobRoute";
import AdminRoute from "./components/AdminRoute";

export default ({ childProps }) => (
  <Switch>
    <AppliedRoute path="/" exact component={Home} props={childProps} />
    <AppliedRoute
      path="/login"
      exact
      component={LoginContainer}
      props={childProps}
    />
    <AppliedRoute
      path="/notfound"
      exact
      props={childProps}
      component={NotFound}
    />
    <AdminRoute path="/admin" props={childProps} component={AdminContainer} />
    <AuthenticatedJobRoute
      path="/:selectedJob"
      exact
      component={CalendarContainer}
      props={childProps}
    />
    <AuthenticatedJobRoute
      path="/:selectedJob/employees"
      exact
      component={EmployeeContainer}
      props={childProps}
    />
    <AuthenticatedJobRoute
      path="/:selectedJob/:selectedDay"
      exact
      component={Timesheet}
      props={childProps}
    />
  </Switch>
);
