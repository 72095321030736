import React, { useState, useEffect, useMemo } from "react";
import { useStoreState, useStoreActions } from "easy-peasy";
import {
  Dropdown,
  Header,
  Button,
  Segment,
  List,
  Dimmer,
  Loader,
  Radio
} from "semantic-ui-react";
import styled from "styled-components";

const DD = styled(Dropdown)`
  margin: 10px 0px 10px;
`;

const AddButton = styled(Button)`
  margin-bottom: 10px;
`;

function AdminEditJobUser({ job }) {
  const [isDisabled, setIsDisabled] = useState(true);
  const [currentValue, setCurrentValue] = useState("");
  const [currentRole, setCurrentRole] = useState("PM");
  //this is not part of the admin state, just a selector for the costCodesByJob part of the state.
  const cmpnyUsers = useStoreState(state => state.admin.cmpnyUsers);
  const usersByJob = useStoreState(state => state.admin.jobUsers.jobUsers);
  const cmpny = useStoreState(state => state.group);
  const isJobUserLoading = useStoreState(
    state => state.admin.jobUsers.isJobUserLoading
  );
  console.log("LOADING STATUS: ", isJobUserLoading);

  const fetchCmpnyUsers = useStoreActions(
    actions => actions.admin.fetchCmpnyUsers
  );
  const fetchJobUsers = useStoreActions(
    actions => actions.admin.jobUsers.fetchJobUsers
  );
  const addJobUser = useStoreActions(
    actions => actions.admin.jobUsers.addJobUser
  );
  const deleteJobUser = useStoreActions(
    actions => actions.admin.jobUsers.deleteJobUser
  );
  const jobUsers = useMemo(() => {
    console.log("LARGE LIST RENDERED");
    return (
      usersByJob[job] &&
      Object.values(usersByJob[job]).sort((a, b) =>
        a.email.localeCompare(b.email)
      )
    );
  }, [job, usersByJob]);
  console.log("JOBUSERS HERE:", jobUsers);
  const cmpnyUsersList = useMemo(
    () =>
      Object.values(cmpnyUsers)
        .map(item => ({
          key: item.sort_key.split("#")[1],
          value: `${item.sort_key}`,
          text: item.email
        }))
        .sort((a, b) => a.text.localeCompare(b.text)),
    [cmpnyUsers]
  );
  useEffect(() => {
    fetchCmpnyUsers(cmpny);
  }, [cmpny, fetchCmpnyUsers]);
  useEffect(() => {
    fetchJobUsers({ cmpny, job });
    console.log("CHECK THIS OUT", cmpny, job);
  }, [cmpny, job, fetchJobUsers]);

  return (
    <Dimmer.Dimmable
      as={Segment}
      dimmed={isJobUserLoading}
      blurring
      style={{ maxWidth: "800px" }}
    >
      <Dimmer active={isJobUserLoading} inverted>
        <Loader>Loading</Loader>
      </Dimmer>
      <Header as="h3">Edit Users</Header>
      <div>
        <DD
          placeholder="Select Company Users"
          fluid
          search
          selection
          clearable
          options={cmpnyUsersList}
          value={currentValue}
          onChange={(e, { value }) => {
            console.log("VALUE: ", value);
            setCurrentValue(value);
            value === "" ? setIsDisabled(true) : setIsDisabled(false);
          }}
        />
        <Radio
          label="PM"
          name="role"
          value="PM"
          checked={currentRole === "PM"}
          onChange={(e, { value }) => {
            setCurrentRole(value);
          }}
        />
        <Radio
          label="SUPER"
          name="role"
          value="SUPER"
          checked={currentRole === "SUPER"}
          onChange={(e, { value }) => {
            setCurrentRole(value);
          }}
        />
        <div>
          <AddButton
            disabled={isDisabled}
            primary
            //cmpny, job, sub, role
            onClick={() => {
              console.log("CMPNY USER DATA IN CLICK HANDLER: ", cmpnyUsers);
              addJobUser({
                data: {
                  sub: currentValue.split("#")[1],
                  role: currentRole
                },
                job,
                cmpny
              });
              setCurrentValue("");
            }}
          >
            Add User
          </AddButton>
        </div>
      </div>
      <List divided relaxed>
        {jobUsers &&
          jobUsers.map(x => {
            return (
              <List.Item key={x.sort_key}>
                {x.email} - {x.role}
                <List.Content floated="right">
                  <Button
                    onClick={() => {
                      deleteJobUser({
                        sort_key: x.sort_key,
                        job,
                        cmpny
                      });
                    }}
                    negative
                    size="small"
                  >
                    Delete
                  </Button>
                </List.Content>
              </List.Item>
            );
          })}
      </List>
    </Dimmer.Dimmable>
  );
}

export default AdminEditJobUser;
