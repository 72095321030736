import { SELECT_DATE, CHANGE_FOCUS } from "./CalendarActions";
import { RECEIVE_TIME_DATA } from "../timesheet/TimesheetActions";
import moment from "moment";

export const selectedDay = (state = moment(), action) => {
  switch (action.type) {
    case SELECT_DATE:
      return action.payload;
    default:
      return state;
  }
};

export const calendarFocus = (state = {}, action) => {
  switch (action.type) {
    case CHANGE_FOCUS:
      return action.payload;
    default:
      return state;
  }
};

export const calendarDaysWithTime = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_TIME_DATA:
      const unique = [
        ...new Set(
          action.data.reduce((agg, item) => {
            item.date && agg.push(item.date);
            return agg;
          }, [])
        )
      ];
      console.log(state);
      const newList =
        state[action.payload.job] == null
          ? unique
          : [...new Set([...state[action.payload.job], ...unique])];
      console.log(Object.assign({}, state, { [action.payload.job]: newList }));
      return Object.assign({}, state, { [action.payload.job]: newList });
    default:
      return state;
  }
};
