import React from "react";
import { useReportState } from "../timesummary/ReportContext";
import { Button } from "semantic-ui-react";
import { shapeReportData } from "../utils/shapeFormData";
import { API } from "aws-amplify";
import { toast } from "react-toastify";

const ReportHeaderComponent = props => {
  const state = useReportState();
  const handleSubmit = e => {
    //shapeReportData = (formData, stateobj, jobInfo, job, day)
    //check that no blank entries for sub or no information at all for selected sub.
    if (state.state.subs["TEMP"]) {
      toast.error(
        "There is an empty Subcontractor selection. Check the Subcontractor tab.",
        { containerId: "Time" }
      );
      return;
    }
    if (
      Object.values(state.state.subs).length &&
      Object.values(state.state.subs).some(
        x => x.descr === "" && x.count === ""
      )
    ) {
      toast.error(
        "One selected sub doesn't have any information entered. Check the Subcontractor tab.",
        { containerId: "Time" }
      );
      return;
    }
    const values = shapeReportData(
      state.state,
      props.report,
      props.jobInfo,
      props.match.params.selectedJob,
      props.match.params.selectedDay
    );
    props.onSaveReport(values, props);
  };

  const handlePrint = e => {
    //shapeReportData = (formData, stateobj, jobInfo, job, day)
    console.log(props.jobInfo.latitude, props.jobInfo.longitude);
    const values = {
      jobName: props.jobInfo.job_name,
      dateString: props.match.params.selectedDay,
      latitude: props.jobInfo.latitude,
      longitude: props.jobInfo.longitude
    };
    props.onLoading();
    return API.get(
      "time",
      `report/${props.cmpny}/${props.match.params.selectedJob}/print`,
      {
        queryStringParameters: values,
        responseType: "json"
      }
    )
      .then(response => {
        console.log(response);
        props.offLoading();
        const link = document.createElement("a");
        link.href = response.url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch(e => {
        props.offLoading();
        console.log(e);
      });
  };

  console.log(state.state.isSaveDisabled);
  return (
    <div>
      <Button
        onClick={handleSubmit}
        disabled={state.state.isSaveDisabled}
        primary
      >
        Save
      </Button>
      <Button
        onClick={handlePrint}
        disabled={state.state.isPrintDisabled}
        color="orange"
      >
        Print Report
      </Button>
    </div>
  );
};

export default ReportHeaderComponent;
