import "./Landing.css";
import React, { Component } from "react";
import { connect } from "react-redux";
import { selectJob, RECEIVE_JOB_DATA } from "./LandingActions";
import { requestData } from "../api/actions";
import JobList from "./Landing";
import history from "../history";

class JobContainer extends Component {
  componentDidMount() {
    console.log(this.props);
    this.props.requestData({
      actionType: RECEIVE_JOB_DATA,
      name: "time",
      url: `login`
    });
  }
  render() {
    return <JobList {...this.props} />;
  }
}
const mapStateToProps = state => {
  return {
    jobs: state.jobs,
    userName: state.userName
  };
};

const mapDispatchToProps = dispatch => {
  return {
    requestData: (actionType, name, url) =>
      dispatch(requestData(actionType, name, url)),
    handleClick: toClickedJob => {
      console.log(toClickedJob);
      dispatch(selectJob(toClickedJob));
      history.push(toClickedJob);
    }
  };
};

JobContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(JobContainer);

export default JobContainer;
