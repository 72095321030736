import { createSelector } from "reselect";

const getRole = state => state.role;
const getJobList = state => state.jobs.result;

export const makeIsAdmin = () =>
  createSelector(
    getRole,
    role => {
      return ["PM", "Admin", "RegionAdmin"].includes(role);
    }
  );

export const makeIsRegionAdmin = () =>
  createSelector(
    getRole,
    role => {
      return ["Admin", "RegionAdmin"].includes(role);
    }
  );

export const makeIsSuperAdmin = () =>
  createSelector(
    getRole,
    role => {
      return ["Admin"].includes(role);
    }
  );

export const makeJobList = () =>
  createSelector(
    getJobList,
    result => {
      return result;
    }
  );
