import React, { useState } from "react";
import {
  Button,
  Form as AltForm,
  Input,
  Radio,
  Select,
  TextArea,
  Dropdown,
  Segment,
  Checkbox
} from "semantic-ui-react";
import { Formik, Field } from "formik";
import styled from "styled-components";
import { shapeFormData } from "../utils/shapeFormData";

const Grid = styled(AltForm.Field)`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-column-gap: 30px;
  justify-content: center;
`;

const renderRadioField = ({
  field,
  setFieldValue,
  label,
  radioValue,
  ...props
}) => {
  return (
    <AltForm.Field>
      <Radio
        value={radioValue}
        checked={field.value === radioValue}
        onChange={(e, { value }) => {
          setFieldValue(field.name, value);
        }}
        label={label}
      />
    </AltForm.Field>
  );
};

const renderField = ({ field, label, setFieldValue, ...props }) => (
  <AltForm.Field
    label={label}
    {...props}
    value={field.value}
    onChange={(param, { value }) => {
      setFieldValue(field.name, value);
    }}
  />
);

const renderTextField = ({ field, label, setFieldValue, ...props }) => (
  <AltForm.Field>
    <TextArea
      onChange={(e, { value }) => {
        setFieldValue(field.name, value);
      }}
      label={label}
      {...props}
      value={field.value}
    />
  </AltForm.Field>
);

const ModalFormComponent = props => {
  const {
    modalEmployee,
    selectedCostCode,
    selectedType,
    selectedSpcl,
    currentHours,
    currentDesc,
    costCodes,
    onSubmitTime,
    onDeleteTime,
    onModifyTime,
    handleClose
  } = props;

  const [specialToggle, setSpecialToggle] = useState(
    (selectedSpcl && true) || false
  );

  const costCodeList = costCodes
    ? costCodes.reduce(
        (agg, item) =>
          agg.concat({
            key: item.cc,
            value: item.cc,
            text: `${item.cc} - ${item.descr}`
          }),
        []
      )
    : [];

  const oldValues = {
    employee: modalEmployee.value,
    cc: selectedCostCode,
    hours: currentHours,
    type: selectedType,
    spcl: selectedSpcl,
    desc: currentDesc
  };

  return (
    <Formik
      initialValues={{
        employee: [modalEmployee.value],
        cc: selectedCostCode,
        hours: currentHours,
        type: selectedType,
        spcl: selectedSpcl,
        desc: currentDesc
      }}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        console.log("submitting", props);
        const newValues = shapeFormData(
          values,
          props.employees,
          props.jobInfo,
          props.selectedJob,
          props.selectedDay
        );

        //Needs hour difference in order to "update" item with same type and cost code. Delete item if type or cost code changes, causes a different pk and sk.
        //because first changes the props, calling arrow function.
        //second only changes the item, but overall props same. non-arrow function works for some reason.
        if (
          values.type !== selectedType ||
          values.cc !== selectedCostCode ||
          values.spcl !== selectedSpcl
        ) {
          onModifyTime(oldValues, newValues, props);
          handleClose();
        } else {
          console.log(newValues, values.hours, props.currentHours);
          newValues[0].hoursDiff = Number(values.hours) - props.currentHours;
          onSubmitTime(newValues, props);
          console.log("we are here");
          handleClose();
        }
      }}
      render={({ handleSubmit, setFieldValue, resetForm }) => {
        return (
          <Segment>
            <AltForm onSubmit={handleSubmit}>
              <Grid>
                <Field
                  name="employee"
                  component={renderField}
                  control={Dropdown}
                  label="Employee"
                  multiple
                  disabled
                  text={modalEmployee.text}
                  options={[modalEmployee]}
                  setFieldValue={setFieldValue}
                />
                <Field
                  name="cc"
                  component={renderField}
                  control={Select}
                  label="Cost Code"
                  placeholder="Select Cost Code"
                  fluid
                  selection
                  options={costCodeList || []}
                  setFieldValue={setFieldValue}
                />
                <Field
                  name="hours"
                  component={renderField}
                  control={Input}
                  label="Hours"
                  type="number"
                  placeholder="Hours"
                  min="0.0"
                  step="0.5"
                  max="12.0"
                  setFieldValue={setFieldValue}
                />
              </Grid>
              <AltForm.Group>
                <Field
                  name="type"
                  component={renderRadioField}
                  value="r"
                  radioValue="r"
                  label="Reg"
                  setFieldValue={setFieldValue}
                />
                <Field
                  name="type"
                  component={renderRadioField}
                  value="ot"
                  radioValue="ot"
                  label="OT"
                  setFieldValue={setFieldValue}
                />
                <Field
                  name="type"
                  component={renderRadioField}
                  value="2nd"
                  radioValue="2nd"
                  label="2nd Shift"
                  setFieldValue={setFieldValue}
                />
                <Field
                  name="type"
                  component={renderRadioField}
                  //component="input"
                  //control={Radio}
                  //type="radio"
                  value="2x"
                  radioValue="2x"
                  label="2x OT"
                  setFieldValue={setFieldValue}
                />
              </AltForm.Group>
              <AltForm.Field>
                <Checkbox
                  label="Special Rate?"
                  checked={specialToggle}
                  onChange={() => {
                    setSpecialToggle(!specialToggle);
                  }}
                />
              </AltForm.Field>
              <AltForm.Field>
                {specialToggle && (
                  <Field
                    name="spcl"
                    component={renderField}
                    control={TextArea}
                    rows={1}
                    placeholder="Describe Special Rate..."
                    setFieldValue={setFieldValue}
                  />
                )}
              </AltForm.Field>
              <Field
                name="desc"
                component={renderTextField}
                label="Notes"
                placeholder="Enter notes here..."
                setFieldValue={setFieldValue}
              />
              <Button type="submit">Submit</Button>
              <Button
                type="button"
                onClick={() => {
                  onDeleteTime(oldValues, props);
                  handleClose();
                }}
                negative
              >
                Delete
              </Button>
            </AltForm>
          </Segment>
        );
      }}
    />
  );
};

export default ModalFormComponent;
