import React, { Fragment, useEffect, useMemo } from "react";
import { Header, Grid } from "semantic-ui-react";
import AdminUserAdd from "./AdminUserAdd";
import AdminUserEdit from "./AdminUserEdit";
import { useStoreState, useStoreActions } from "easy-peasy";

const AdminUsers = props => {
  const cmpny = useStoreState(state => state.group);
  const fetchCmpnyRegions = useStoreActions(
    actions => actions.admin.fetchRegions
  );
  const cmpnyRegions = useStoreState(state => state.admin.cmpnyRegions);
  const cmpnyRegionList = useMemo(
    () =>
      Object.values(cmpnyRegions).map(item => ({
        value: item.region,
        text: item.region_name,
        key: item.region
      })),
    [cmpnyRegions]
  );
  useEffect(() => {
    fetchCmpnyRegions(cmpny);
  }, [cmpny, fetchCmpnyRegions]);
  return (
    <Fragment>
      <Header block>EDIT COMPANY USERS</Header>
      <Grid stackable columns={2}>
        <Grid.Column>
          <AdminUserEdit cmpnyRegionList={cmpnyRegionList} />
        </Grid.Column>
        <Grid.Column>
          <AdminUserAdd cmpnyRegionList={cmpnyRegionList} />
        </Grid.Column>
      </Grid>
    </Fragment>
  );
};

export default AdminUsers;
