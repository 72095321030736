import React from "react";
import { Button } from "semantic-ui-react";

export default ({
  isSubmitting,
  text,
  loadingText,
  disabled = false,
  ...props
}) => (
  <Button loading={isSubmitting} disabled={disabled || isSubmitting} {...props}>
    {!isSubmitting ? text : loadingText}
  </Button>
);
