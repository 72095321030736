import React from "react";
import { useStoreState, useStoreActions } from "easy-peasy";
import { Header, Segment, Form, Dimmer, Loader } from "semantic-ui-react";

import { Formik } from "formik";
import { subFormSchema } from "./FormSchema";
import { SemanticField } from "../utils/SemanticField";

function AdminAddCode({ cmpny, cmpnySubs }) {
  const isSubLoading = useStoreState(state => state.admin.subs.isSubLoading);
  console.log("LOADING STATUS: ", isSubLoading);
  const addCmpnySub = useStoreActions(
    actions => actions.admin.subs.addCmpnySub
  );

  return (
    <Formik
      initialValues={{
        sub_no: "",
        sub_name: ""
      }}
      validationSchema={subFormSchema(cmpnySubs)}
      onSubmit={(data, { resetForm }) => {
        // same shape as initial values
        addCmpnySub({ cmpny, data });
        console.log("getting here");
        resetForm();
      }}
    >
      {({
        values,
        errors,
        setFieldValue,
        setTouched,
        isSubmitting,
        isValid,
        touched,
        handleSubmit,
        handleBlur,
        ...rest
      }) => {
        console.log(values, errors, touched, "REST:", rest);
        return (
          <Dimmer.Dimmable as={Segment} dimmed={isSubLoading} blurring>
            <Dimmer active={isSubLoading} inverted>
              <Loader>Loading</Loader>
            </Dimmer>
            <Header as="h3">Add Subcontractor</Header>
            <Form>
              <SemanticField
                component={Form.Input}
                fluid
                label="Subcontractor Number"
                placeholder="Enter Company Subcontractor Number"
                name="sub_no"
                error={errors.sub_no && touched.sub_no ? errors.sub_no : false}
              />
              <SemanticField
                component={Form.Input}
                fluid
                label="Subcontractor Name"
                placeholder="Subcontractor Name"
                name="sub_name"
                error={
                  errors.sub_name && touched.sub_name ? errors.sub_name : false
                }
              />
              <Form.Group>
                <Form.Button
                  disabled={isSubmitting || !isValid}
                  type="submit"
                  onClick={handleSubmit}
                  primary
                >
                  Add Subcontractor
                </Form.Button>
              </Form.Group>
            </Form>
          </Dimmer.Dimmable>
        );
      }}
    </Formik>
  );
}

export default AdminAddCode;
