import * as Yup from "yup";

export const empFormSchema = empList => {
  return Yup.lazy(values => {
    return Yup.object().shape({
      empl: Yup.string()
        .test("day-hours-test", `Employee Number Already Exists.`, value => {
          return !Object.keys(empList).includes(value);
        })
        .required("Required"),
      fname: Yup.string().required("Required"),
      lname: Yup.string().required("Required"),
      minit: Yup.string(),
      ethnic: Yup.string().required("Required"),
      sex: Yup.string().required("Required"),
      trade: Yup.string().required("Required")
    });
  });
};

export const empFormEditSchema = (empList, initialEmp) => {
  return Yup.lazy(values => {
    return Yup.object().shape({
      empl: Yup.string()
        .test("day-hours-test", `Employee Number Already Exists.`, value => {
          return !Object.keys(empList)
            .filter(x => x !== initialEmp)
            .includes(value);
        })
        .required("Required"),
      fname: Yup.string().required("Required"),
      lname: Yup.string().required("Required"),
      minit: Yup.string(),
      ethnic: Yup.string().required("Required"),
      sex: Yup.string().required("Required"),
      trade: Yup.string().required("Required")
    });
  });
};

export const ccFormSchema = ccList => {
  return Yup.lazy(values => {
    return Yup.object().shape({
      cc: Yup.string()
        .test("cc-exists-test", `Cost Code Already Exists.`, value => {
          return !Object.keys(ccList).includes(value);
        })
        .required("Required"),
      descr: Yup.string().required("Required")
    });
  });
};

export const ccFormEditSchema = (ccList, initialCC) => {
  return Yup.lazy(values => {
    return Yup.object().shape({
      cc: Yup.string()
        .test("cc-edit-exists", `Cost Code Already Exists.`, value => {
          return !Object.keys(ccList)
            .filter(x => x !== initialCC)
            .includes(value);
        })
        .required("Required"),
      descr: Yup.string().required("Required")
    });
  });
};

export const userFormSchema = userList => {
  return Yup.lazy(values => {
    let phoneRegExp = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;
    return Yup.object().shape({
      email: Yup.string()
        .email()
        .test("email-test", `Email Already Exists.`, value => {
          return !userList.includes(value);
        })
        .required("Required"),
      fname: Yup.string().required("Required"),
      lname: Yup.string().required("Required"),
      role: Yup.required(),
      region: Yup.required(),
      phoneNumber: Yup.string().test(
        "phone-test",
        `Enter a Valid Phone Number.`,
        value => {
          console.log(phoneRegExp.test(value));
          return phoneRegExp.test(value);
        }
      )
    });
  });
};

export const userFormEditSchema = userList => {
  return Yup.lazy(values => {
    console.log(values);
    let phoneRegExp = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;
    return Yup.object().shape({
      email: Yup.string()
        .email()
        .test("email-test", `Email Already Exists.`, value => {
          console.log("geting here");
          return !userList.includes(value);
        })
        .required("Required"),
      fname: Yup.string().required("Required"),
      lname: Yup.string().required("Required"),
      role: Yup.required(),
      region: Yup.required(),
      phoneNumber: Yup.string().test(
        "phone-test",
        `Enter a Valid Phone Number.`,
        value => {
          console.log(phoneRegExp.test(value));
          return phoneRegExp.test(value);
        }
      )
    });
  });
};

export const addJobFormSchema = jobList => {
  return Yup.lazy(values => {
    return Yup.object().shape({
      job: Yup.string()
        .test("job-exists-test", `Job Number Already Exists.`, value => {
          return !jobList.includes(value);
        })
        .required("Required"),
      job_name: Yup.string().required("Required"),
      latitude: Yup.number()
        .min(-90)
        .max(90)
        .required(),
      longitude: Yup.number()
        .min(-180)
        .max(180)
        .required(),
      maxRegHours: Yup.number()
        .integer()
        .moreThan(7)
        .lessThan(13)
        .required("Required"),
      //region: Yup.required("Required")
      expire: Yup.string().required("Required")
    });
  });
};

export const subFormSchema = subList => {
  return Yup.lazy(values => {
    return Yup.object().shape({
      sub_no: Yup.string()
        .test("cc-exists-test", `Sub Number Already Exists.`, value => {
          return !Object.keys(subList).includes(value);
        })
        .required("Required"),
      sub_name: Yup.string().required("Required")
    });
  });
};

export const subFormEditSchema = (subList, initialSub) => {
  return Yup.lazy(values => {
    return Yup.object().shape({
      sub_no: Yup.string()
        .test("cc-edit-exists", `Sub Number Already Exists.`, value => {
          return !Object.keys(subList)
            .filter(x => x !== initialSub)
            .includes(value);
        })
        .required("Required"),
      sub_name: Yup.string().required("Required")
    });
  });
};

export const timeFormSchema = Yup.object().shape({
  weekNo: Yup.string()
    .test("len", `must be two characters long.`, val => val && val.length === 2)
    .required("Required"),
  year: Yup.string()
    .test(
      "len",
      `must be four characters long.`,
      val => val && val.length === 4
    )
    .required("Required")
});
