import React, { useState } from "react";
import { Grid, Dimmer, Loader } from "semantic-ui-react";
import { Auth } from "aws-amplify";
import useIsMounted from "../components/useIsMounted";
import "./Login.css";
import LoginDefault from "./LoginDefault";
import LoginNewPassword from "./LoginNewPassword";
import LoginResetPassword from "./LoginResetPassword";
import LoginResetNewPassword from "./LoginResetNewPassword";

export default props => {
  const [email, setEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loginResponse, setLoginResponse] = useState("");
  const [view, setView] = useState("login");
  const isMounted = useIsMounted();

  const handleSubmit = async values => {
    try {
      const formattedEmail = values.email.toLowerCase();
      const loginResult = await Auth.signIn(formattedEmail, values.password);
      if (
        loginResult.challengeName &&
        loginResult.challengeName === "NEW_PASSWORD_REQUIRED"
      ) {
        isMounted.current && setIsSubmitting(false);
        setLoginResponse(loginResult);
        setView("newPassword");
        return;
      }
      props.userHasAuthenticated(true);
      props.setUserName(formattedEmail);
      isMounted.current && setIsSubmitting(false);
      props.history.push("/");
      return;
    } catch (e) {
      isMounted.current && setIsSubmitting(false);
      alert(e.message);
      return;
    }
  };

  const handleNewPassword = async values => {
    try {
      await Auth.completeNewPassword(loginResponse, values.newPassword);
      props.userHasAuthenticated(true);
      props.setUserName(email);
      isMounted.current && setIsSubmitting(false);
      props.history.push("/");
      return;
    } catch (e) {
      isMounted.current && setIsSubmitting(false);
      alert(e.message);
    }
  };

  const handleSendConfirmation = async values => {
    try {
      await Auth.forgotPassword(values.email.toLowerCase());
      setEmail(values.email.toLowerCase());
      setView("resetNewPassword");
      isMounted.current && setIsSubmitting(false);
      return;
    } catch (e) {
      isMounted.current && setIsSubmitting(false);
      setView("login");
    }
  };

  const handleResetPassword = async values => {
    try {
      await Auth.forgotPasswordSubmit(
        email,
        values.confirmation,
        values.newPassword
      );
      isMounted.current && setIsSubmitting(false);
      setView("login");
      return;
    } catch (e) {
      isMounted.current && setIsSubmitting(false);
      alert(e.message);
    }
  };

  return (
    <div className="Login">
      <Grid
        textAlign="center"
        style={{ height: "100%" }}
        verticalAlign="middle"
      >
        <Dimmer active={isSubmitting} page={true} inverted>
          <Loader />
        </Dimmer>
        {view === "login" && (
          <LoginDefault
            setView={setView}
            setIsSubmitting={setIsSubmitting}
            submitFuction={handleSubmit}
          />
        )}
        {view === "resetPassword" && (
          <LoginResetPassword
            setIsSubmitting={setIsSubmitting}
            submitFuction={handleSendConfirmation}
          />
        )}
        {view === "resetNewPassword" && (
          <LoginResetNewPassword
            setIsSubmitting={setIsSubmitting}
            submitFuction={handleResetPassword}
          />
        )}
        {view === "newPassword" && (
          <LoginNewPassword
            setIsSubmitting={setIsSubmitting}
            submitFuction={handleNewPassword}
          />
        )}
      </Grid>
    </div>
  );
};
