import React, { Fragment } from "react";
import { List, Segment, Header, Button } from "semantic-ui-react";
import { useStoreState } from "easy-peasy";
import { Link } from "react-router-dom";

export default props => {
  const jobs = useStoreState(state => state.jobs.entities.jobs);
  console.log(jobs && Object.values(jobs));
  const handleClick = () => {
    props.history.push(`/admin/addjob`);
  };
  const handleTimeClick = () => {
    props.history.push(`/admin/time`);
  };
  return (
    <Fragment>
      <Header block>ADMIN HOME</Header>
      <Button primary onClick={handleClick}>
        Add New Job
      </Button>
      <Button primary onClick={handleTimeClick}>
        Download Time
      </Button>
      <Segment>
        <Header>Edit Job Information</Header>
        <List divided relaxed size="small">
          {jobs &&
            Object.values(jobs).map(job => {
              return (
                <List.Item key={job.job}>
                  <List.Header as={Link} to={`/admin/${job.job}`}>
                    {job.job} - {job.job_name}
                  </List.Header>
                  <List.Description>
                    <span style={{ fontWeight: "bold", fontStyle: "italic" }}>
                      Region:
                    </span>{" "}
                    {job.region}{" "}
                    <span style={{ marginLeft: "10px" }}>
                      <span style={{ fontWeight: "bold", fontStyle: "italic" }}>
                        Max Daily Hours:
                      </span>{" "}
                      {job.maxRegHours}
                    </span>
                  </List.Description>
                </List.Item>
              );
            })}
        </List>
      </Segment>
    </Fragment>
  );
};
