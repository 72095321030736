import React from "react";
import { useStoreState, useStoreActions } from "easy-peasy";
import {
  Dimmer,
  Header,
  Button,
  Segment,
  Loader,
  Form,
  Radio
} from "semantic-ui-react";

import { Formik, Field } from "formik";
import { SemanticField } from "../utils/SemanticField";
import { empFormSchema } from "./FormSchema";

const trades = [
  "Carpenter",
  "Mason",
  "Laborer",
  "Oper.Engineer",
  "Ironworker",
  "Mason Apprentice",
  "Carp Apprentice",
  "Taper-Painter",
  "Laborer Apprentice",
  "Ironwork Aprentice",
  "Office Engineer",
  "Estimating",
  "Timekeeper",
  "Rodperson"
];

const tradeList = trades.map(key => {
  return { key, value: key, text: key };
});

const ethList = [
  { value: "1", name: "African-American" },
  { value: "2", name: "Hispanic" },
  { value: "3", name: "Native American/Alaskan" },
  { value: "4", name: "Asian" },
  { value: "5", name: "Caucasion" },
  { value: "6", name: "Two or More Ethnicities" },
  { value: "7", name: "Pacific Islander/Other" }
];

const renderRadioField = ({
  label,
  error,
  checked,
  form: { setFieldValue, handleBlur, values },
  field: { name, value },
  ...otherProps
}) => {
  return (
    <Radio
      value={value}
      name={name}
      checked={values.ethnic === checked}
      label={label}
      onChange={(e, { value }) => {
        setFieldValue(name, value);
      }}
      onBlur={handleBlur}
      {...otherProps}
    />
  );
};

function AdminAddEmployee() {
  const cmpnyEmps = useStoreState(state => state.admin.employees);
  const cmpny = useStoreState(state => state.group);
  const isEmpLoading = useStoreState(state => state.admin.isEmpLoading);
  const addCmpnyEmp = useStoreActions(actions => actions.admin.addCmpnyEmp);

  return (
    <Formik
      initialValues={{
        empl: "",
        fname: "",
        lname: "",
        minit: "",
        ethnic: "1",
        sex: "M",
        trade: "Carpenter"
      }}
      validationSchema={empFormSchema(cmpnyEmps)}
      onSubmit={(data, { resetForm }) => {
        addCmpnyEmp({ cmpny, data });
        console.log("getting here");
        resetForm();
      }}
    >
      {({
        values,
        errors,
        setFieldValue,
        setTouched,
        isSubmitting,
        isValid,
        touched,
        handleSubmit,
        handleBlur,
        ...rest
      }) => {
        console.log(values, errors, touched, "REST:", rest);
        return (
          <Dimmer.Dimmable as={Segment} dimmed={isEmpLoading} blurring>
            <Dimmer active={isEmpLoading} inverted>
              <Loader>Loading</Loader>
            </Dimmer>
            <Header as="h3">Add Employee</Header>
            <Form>
              <SemanticField
                component={Form.Input}
                fluid
                label="Employee Number"
                placeholder="Employee Number"
                name="empl"
                error={errors.empl && touched.empl ? errors.empl : false}
              />
              <SemanticField
                component={Form.Input}
                fluid
                label="First Name"
                placeholder="First Name"
                name="fname"
                error={errors.fname && touched.fname ? errors.fname : false}
              />
              <SemanticField
                component={Form.Input}
                fluid
                label="Last Name"
                placeholder="Last Name"
                name="lname"
                error={errors.lname && touched.lname ? errors.lname : false}
              />
              <SemanticField
                component={Form.Input}
                fluid
                label="M"
                placeholder="Middle Initial (optional)"
                name="minit"
                error={errors.minit && touched.minit ? errors.minit : false}
              />
              <Form.Field label="Ethnicity">Ethnicity</Form.Field>
              {ethList.map(item => {
                return (
                  <Form.Field key={item.value}>
                    <Field
                      component={renderRadioField}
                      label={item.name}
                      value={item.value}
                      checked={item.value}
                      name="ethnic"
                    />
                  </Form.Field>
                );
              })}
              <Form.Field label="Gender">Gender</Form.Field>
              <Form.Field>
                <Button.Group>
                  <Field
                    component={Button}
                    name="sex"
                    value="M"
                    active={values.sex === "M"}
                    onClick={(e, { value }) => setFieldValue("sex", value)}
                  >
                    M
                  </Field>
                  <Field
                    component={Button}
                    name="sex"
                    value="F"
                    active={values.sex === "F"}
                    onClick={(e, { value }) => {
                      setFieldValue("sex", value);
                    }}
                  >
                    F
                  </Field>
                </Button.Group>
              </Form.Field>
              <SemanticField
                component={Form.Dropdown}
                search
                selection
                label="Trade"
                placeholder="Employee Trade"
                name="trade"
                options={tradeList}
              />
              <Form.Group>
                <Form.Button
                  disabled={isSubmitting || !isValid}
                  type="submit"
                  onClick={handleSubmit}
                  primary
                >
                  Add Emp
                </Form.Button>
              </Form.Group>
            </Form>
          </Dimmer.Dimmable>
        );
      }}
    </Formik>
  );
}

export default AdminAddEmployee;

/* export const ValidationSchemaExample = () => (
  <div>
    <h1>Signup</h1>
    <Formik
      initialValues={{
        firstName: "",
        lastName: "",
        email: ""
      }}
      validationSchema={SignupSchema}
      onSubmit={values => {
        // same shape as initial values
        console.log(values);
      }}
    >
      {({ errors, touched }) => (
        <Form>
          <Field name="firstName" />
          {errors.firstName && touched.firstName ? (
            <div>{errors.firstName}</div>
          ) : null}
          <Field name="lastName" />
          {errors.lastName && touched.lastName ? (
            <div>{errors.lastName}</div>
          ) : null}
          <Field name="email" type="email" />
          {errors.email && touched.email ? <div>{errors.email}</div> : null}
          <button type="submit">Submit</button>
        </Form>
      )}
    </Formik>
  </div>
); */
