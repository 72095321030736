import {
  RECEIVE_EMPLOYEE_DATA,
  RECEIVE_COST_CODE_DATA,
  RECEIVE_TIME_DATA,
  DELETE_TIME_DATA,
  RECEIVE_REPORT_DATA,
  DELETE_REPORT_DATA,
  RECEIVE_TOTALS_DATA,
  SUBMIT_TIME_DATA,
  SUBMIT_REPORT_DATA,
  FAILED_SUBMIT_TIME,
  ADD_COST_CODE_DATA,
  DELETE_COST_CODE_DATA,
  ADD_SUB_DATA,
  RECEIVE_SUB_DATA,
  DELETE_SUB_DATA,
  UPDATE_MISSING_EMPLOYEE_DATA
} from "./TimesheetActions";
import { DELETE_EMPLOYEE_DATA } from "../employee/EmployeeActions";
import { IS_LOADING } from "../api/actions";
import {
  normalizedTimeData,
  normalizedTotalsData,
  normalizedReportData
} from "../schemas";
import merge from "lodash/merge";
import unionBy from "lodash/unionBy";
import { combineReducers } from "redux";
import moment from "moment";
import { toast } from "react-toastify";

const byId = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_TIME_DATA:
      return merge({}, state, normalizedTimeData(action.data).entities.time);
    case SUBMIT_TIME_DATA:
      return merge({}, state, normalizedTimeData(action.data).entities.time);
    case DELETE_TIME_DATA:
      console.log("we got here with action:", normalizedTimeData(action.data));
      const {
        [action.data.pk + action.data.sk]: value,
        ...restOfState
      } = state;
      console.log(restOfState);
      return restOfState;
    default:
      return state;
  }
};

const allIds = (state = [], action) => {
  switch (action.type) {
    case RECEIVE_TIME_DATA:
      let newList =
        state == null
          ? normalizedTimeData(action.data).result
          : [...new Set([...state, ...normalizedTimeData(action.data).result])];
      return newList;
    case SUBMIT_TIME_DATA:
      let newItem =
        state == null
          ? normalizedTimeData(action.data).result
          : [...new Set([...state, ...normalizedTimeData(action.data).result])];
      return newItem;
    case DELETE_TIME_DATA:
      console.log(action.data);
      const revisedArray = state.filter(
        el => el !== `${action.data.pk}${action.data.sk}`
      );
      return revisedArray;
    default:
      return state;
  }
};

export const time = combineReducers({
  byId,
  allIds
});

export const isLoading = (state = false, action) => {
  switch (action.type) {
    case IS_LOADING:
      return action.payload;
    default:
      return state;
  }
};

export const timeTotalsByWeek = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_TOTALS_DATA:
      return merge({}, state, normalizedTotalsData(action.data).entities.time);
    case SUBMIT_TIME_DATA:
      console.log("we got here submittedKeyItem:", action.data);
      const newObjs = action.data.reduce((agg, item) => {
        if (item.sk.includes("#updated")) {
          return agg;
        } else {
          let tempKey = `${item.sk.split("#")[1]}#${item.emp}`;
          //console.log("state is: ", state[tempKey]);
          console.log("temp key is:", tempKey);
          console.log(
            "first is: ",
            (state[tempKey] && state[tempKey][item.type]) || 0
          );
          console.log(
            "second is: ",
            item.hasOwnProperty("hoursDiff") ? item.hoursDiff : item.hours
          );
          let itemHours = item.hasOwnProperty("hoursDiff")
            ? item.hoursDiff
            : item.hours;
          let itemTotal =
            ((state[tempKey] && state[tempKey][item.type]) || 0) + itemHours;
          let dayNumber = `day${moment(item.date).day() + 1}`;
          let dayTotal =
            ((state[tempKey] &&
              state[tempKey][dayNumber] &&
              state[tempKey][dayNumber][item.type]) ||
              0) + itemHours;
          console.log(itemTotal);
          console.log(state[tempKey] ? state[tempKey][dayNumber] : {});
          console.log(state[tempKey] && false);
          return {
            ...agg,
            [tempKey]: {
              ...(state[tempKey] ? state[tempKey] : {}),
              [item.type]: itemTotal,
              sk: item.emp,
              pk: `${item.pk.split("#")[0]}#a#${item.sk.split("#")[1]}`,
              [dayNumber]: {
                ...(state[tempKey] && state[tempKey][dayNumber]
                  ? state[tempKey][dayNumber]
                  : {}),
                [item.type]: dayTotal
              }
            }
          };
        }
      }, {});
      console.log(newObjs);
      return merge({}, state, newObjs);
    case DELETE_TIME_DATA:
      const itemKey = `${action.data.sk.split("#")[1]}#${action.data.emp}`;
      const newTotal = state[itemKey][action.data.type] - action.data.hours;
      const deletedDay = `day${moment(action.data.date).day() + 1}`;
      const dayTotal =
        ((state[itemKey] &&
          state[itemKey][deletedDay] &&
          state[itemKey][deletedDay][action.data.type]) ||
          0) - action.data.hours;
      return {
        ...state,
        [itemKey]: {
          ...state[itemKey],
          [action.data.type]: newTotal,
          [deletedDay]: {
            ...state[itemKey][deletedDay],
            [action.data.type]: dayTotal
          }
        }
      };
    default:
      return state;
  }
};

export const employeesByJob = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_EMPLOYEE_DATA:
      const result = unionBy(state[action.payload.job], action.data, "emp");
      return Object.assign({}, state, {
        [action.payload.job]: result
      }); //addTimeEntry(state, action);
    case DELETE_EMPLOYEE_DATA:
      console.log(action.data.emp);
      const revisedArray = state[action.payload.job].filter(function(obj) {
        return obj.emp !== action.data.emp;
      });
      return Object.assign({}, state, {
        [action.payload.job]: revisedArray
      });
    default:
      return state;
  }
};

export const employeesInSummary = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_EMPLOYEE_DATA:
      const newResult = action.data.reduce((agg, item) => {
        return { ...agg, [item.emp]: item };
      }, {});
      return Object.assign({}, state, {
        [action.payload.job]: newResult
      }); //addTimeEntry(state, action);
    case UPDATE_MISSING_EMPLOYEE_DATA:
      console.log("LOOOOK: ", action.data);
      const reducedList = action.data.reduce((agg, item) => {
        return { ...agg, [item.emp]: item };
      }, {});
      const updateEmpData = Object.assign({}, state, {
        [action.job]: { ...state[action.job], ...reducedList }
      });

      return Object.assign({}, state, updateEmpData); //addTimeEntry(state, action);

    default:
      return state;
  }
};

export const allEmployees = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_EMPLOYEE_DATA:
      const empData = action.data.reduce(
        (agg, item) => Object.assign({}, agg, { [item.emp]: item }),
        {}
      );
      return Object.assign({}, state, empData); //addTimeEntry(state, action);
    case UPDATE_MISSING_EMPLOYEE_DATA:
      const empTotalData = action.data.reduce(
        (agg, item) => Object.assign({}, agg, { [item.emp]: item }),
        {}
      );
      return Object.assign({}, state, empTotalData); //addTimeEntry(state, action);
    case DELETE_EMPLOYEE_DATA:
      console.log(action.data.emp);
      let { [action.data.emp]: _, ...finalObj } = state;
      return finalObj;
    case FAILED_SUBMIT_TIME:
      action.data.map(item =>
        toast.error(
          `${state[item.emp].fname} ${state[item.emp].lname}'s time was not added, check if there are too many hours in day or week.`,
          { containerId: "Time" }
        )
      );
      return state;

    default:
      return state;
  }
};

export const costCodesByJob = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_COST_CODE_DATA:
      return Object.assign({}, state, { [action.payload.job]: action.data }); //addTimeEntry(state, action);
    case ADD_COST_CODE_DATA:
      console.log("THIS IS ACTION: ", action);
      console.log("THIS IS STATE: ", state);
      let arr1 = state[action.payload.job];
      let arr2 = action.payload.data;
      console.log(
        "MERGE RESULT IN ADD COST CODE: ",
        arr1,
        arr2,
        "RESULT: ",
        unionBy(arr1, arr2, "cc")
      );
      return Object.assign({}, state, {
        [action.payload.job]: unionBy(arr1, arr2, "cc")
      }); //addTimeEntry(state, action);
    case DELETE_COST_CODE_DATA:
      return Object.assign({}, state, {
        [action.payload.job]: state[action.payload.job].filter(
          item => item.cc !== action.payload.cc
        )
      });
    default:
      return state;
  }
};

export const subsByJob = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_SUB_DATA:
      return Object.assign({}, state, { [action.payload.job]: action.data }); //addTimeEntry(state, action);
    case ADD_SUB_DATA:
      console.log("THIS IS ACTION: ", action);
      console.log("THIS IS STATE: ", state);
      let arr1 = state[action.payload.job];
      let arr2 = action.payload.data;
      console.log(
        "MERGE RESULT IN ADD COST CODE: ",
        arr1,
        arr2,
        "RESULT: ",
        unionBy(arr1, arr2, "sub_no")
      );
      return Object.assign({}, state, {
        [action.payload.job]: unionBy(arr1, arr2, "sub_no")
      }); //addTimeEntry(state, action);
    case DELETE_SUB_DATA:
      return Object.assign({}, state, {
        [action.payload.job]: state[action.payload.job].filter(
          item => item.sub_no !== action.payload.sub_no
        )
      });
    default:
      return state;
  }
};

const byReportId = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_REPORT_DATA:
      return merge(
        {},
        state,
        normalizedReportData(action.data).entities.report
      );
    case SUBMIT_REPORT_DATA:
      return merge(
        {},
        state,
        normalizedReportData(action.data).entities.report
      );
    case DELETE_REPORT_DATA:
      console.log(
        "we got here with action:",
        normalizedReportData(action.data)
      );
      const {
        [action.data.pk + action.data.sk]: value,
        ...restOfState
      } = state;
      console.log(restOfState);
      return restOfState;
    default:
      return state;
  }
};

const allReportIds = (state = [], action) => {
  switch (action.type) {
    case RECEIVE_REPORT_DATA:
      let newList =
        state == null
          ? normalizedReportData(action.data).result
          : [
              ...new Set([
                ...state,
                ...normalizedReportData(action.data).result
              ])
            ];
      return newList;
    case SUBMIT_REPORT_DATA:
      let newItem =
        state == null
          ? normalizedReportData(action.data).result
          : [
              ...new Set([
                ...state,
                ...normalizedReportData(action.data).result
              ])
            ];
      return newItem;
    case DELETE_REPORT_DATA:
      console.log(action.data);
      const revisedArray = state.filter(
        el => el !== `${action.data.pk}${action.data.sk}`
      );
      return revisedArray;
    default:
      return state;
  }
};

export const report = combineReducers({
  byId: byReportId,
  allIds: allReportIds
});
