import { Field } from "formik";
import MaskedInput from "react-text-mask";
import { Form } from "semantic-ui-react";
import React from "react";

export const SemanticField = ({ component, ...fieldProps }) => {
  return (
    <Field
      {...fieldProps}
      render={({
        field: { value, onBlur, ...field },
        form: { setFieldValue, submitCount, touched, errors, handleBlur },
        ...props
      }) => {
        return React.createElement(component, {
          ...fieldProps,
          ...field,
          ...props,
          ...(component.name === ("FormRadio" || "Button")
            ? {
                ...(component.name === "FormRadio" && {
                  checked: fieldProps.value === value
                })
              }
            : {
                value: value ? value : ""
              }),
          ...((submitCount > 1 || touched[field.name]) && errors[field.name]
            ? {
                error: {
                  content: errors[field.name]
                }
              }
            : {}),
          onChange: (e, { value: newValue, checked }) => {
            setFieldValue(fieldProps.name, newValue ? newValue : checked);
          },
          ...(component.name === "Button" && {
            onClick: e => {
              setFieldValue(fieldProps.name, fieldProps.value);
            }
          }),
          onBlur: handleBlur
        });
      }}
    />
  );
};

export const SemanticRenderField = ({ ...fieldProps }) => {
  return (
    <Field {...fieldProps}>
      {({ field, form, ...otherProps }) => {
        console.log("FIELD: ", field);
        console.log("Value: ", field.value);
        console.log("PROPS: ", otherProps);
        return (
          <Form.Input
            label="Phone"
            name={field.name}
            {...otherProps}
            children={<MaskedInput {...otherProps} />}
          />
        );
      }}
    </Field>
  );
};
