import React, { Component } from "react";
import { connect } from "react-redux";
import EmployeeComponent from "./EmployeeComponent";
import { API } from "aws-amplify";
import {
  makeGetEmployeesByJob,
  makeGetJobInfo
} from "../timesheet/timesheetSelectors";
import { submitData, deleteData } from "../api/actions";
import { RECEIVE_EMPLOYEE_DATA, DELETE_EMPLOYEE_DATA } from "./EmployeeActions";

class EmployeeContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchList: []
    };
  }
  async componentDidMount() {
    console.log(this.props);
    try {
      const cmpnyEmployees = await this.fetchEmployees();
      console.log("we got here");
      const cmpnyEmployeesSorted = cmpnyEmployees.sort(function(a, b) {
        if (a.trade < b.trade) {
          return -1;
        }
        if (a.trade > b.trade) {
          return 1;
        }
        if (a.lname < b.lname) {
          return -1;
        }
        if (a.lname > b.lname) {
          return 1;
        }
        // names must be equal
        return 0;
      });
      console.log("sorted list: ", cmpnyEmployeesSorted);
      //used as actual search list in employee component
      const searchList = cmpnyEmployeesSorted.reduce((agg, x) => {
        agg.push({
          key: x.empl,
          value: x.empl,
          text: `${x.fname} ${x.lname} - ${x.trade} (${x.empl})`
        });
        return agg;
      }, []);
      //used as data source to dispatch api request below
      const cmpnyList = cmpnyEmployeesSorted.reduce(
        (agg, item) => Object.assign({}, agg, { [item.empl]: item }),
        {}
      );
      console.log(cmpnyList);
      this.setState({ searchList: searchList, cmpnyList: cmpnyList });
    } catch (e) {
      alert(e);
    }

    this.setState({ isLoading: false });
  }

  fetchEmployees() {
    return API.get("time", `time/${this.props.cmpny}/search`);
  }
  render() {
    return (
      <EmployeeComponent
        cmpnyList={this.state.cmpnyList}
        searchList={this.state.searchList}
        {...this.props}
      />
    );
  }
}

const makeMapStateToProps = () => {
  const getEmployees = makeGetEmployeesByJob();
  const getJobInfo = makeGetJobInfo();
  const mapStateToProps = (state, props) => {
    return {
      employeesByJob: getEmployees(state, props),
      jobInfo: getJobInfo(state, props),
      cmpny: state.group
    };
  };
  return mapStateToProps;
};

const mapDispatchToProps = dispatch => {
  return {
    onAddEmployee: (value, props) => {
      console.log(value);
      const { dropdownValue, radioValue } = value;
      dispatch(
        //note data is in list so API call can map over item.
        submitData({
          name: "time",
          url: `time/${props.cmpny}/${props.match.params.selectedJob}/emp`,
          data: [
            {
              emp: String(dropdownValue),
              trade: props.cmpnyList[dropdownValue].trade,
              fname: props.cmpnyList[dropdownValue].fname,
              lname: props.cmpnyList[dropdownValue].lname,
              minit: props.cmpnyList[dropdownValue].minit,
              clss: radioValue,
              ttl: props.jobInfo.ttl
            }
          ],
          actionType: RECEIVE_EMPLOYEE_DATA,
          job: props.match.params.selectedJob
        })
      );
    },
    onDeleteEmployee: (value, props) => {
      console.log(value);
      dispatch(
        //note data is in list so API call can map over item.
        deleteData({
          name: "time",
          url: `time/${props.cmpny}/${props.match.params.selectedJob}/emp/${value}`,
          data: {
            emp: value
          },
          actionType: DELETE_EMPLOYEE_DATA,
          job: props.match.params.selectedJob
        })
      );
    }
  };
};

EmployeeContainer = connect(
  makeMapStateToProps,
  mapDispatchToProps
)(EmployeeContainer);

export default EmployeeContainer;
