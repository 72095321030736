import React, { useState, useEffect, useMemo } from "react";
import { useStoreState, useStoreDispatch, useStoreActions } from "easy-peasy";
import {
  Dropdown,
  Header,
  Button,
  Segment,
  List,
  Dimmer,
  Loader
} from "semantic-ui-react";
import { requestData } from "../api/actions";
import { subActionParams } from "../timesheet/TimesheetApiParams";
import { shapeSubData } from "../utils/shapeFormData";
import styled from "styled-components";

const DD = styled(Dropdown)`
  margin: 10px 0px 10px;
`;

const AddButton = styled(Button)`
  margin-bottom: 10px;
`;

function AdminEditJobSubs({ job }) {
  const dispatch = useStoreDispatch();
  const [isDisabled, setIsDisabled] = useState(true);
  const [currentValue, setCurrentValue] = useState([]);
  //this is not part of the admin state, just a selector for the subsByJob part of the state.
  const subs = useStoreState(state => state.subsByJob);
  const cmpnySubs = useStoreState(state => state.admin.subs.cmpnySubs);
  const jobInfo = useStoreState(state => state.jobs.entities.jobs[job]);
  const cmpny = useStoreState(state => state.group);
  const isSubLoading = useStoreState(state => state.admin.subs.isSubLoading);
  console.log("LOADING STATUS: ", isSubLoading);

  const fetchCmpnySubs = useStoreActions(
    actions => actions.admin.subs.fetchCmpnySubs
  );
  const addCmpnySubs = useStoreActions(
    actions => actions.admin.subs.addJobSubs
  );
  const delCmpnySubs = useStoreActions(
    actions => actions.admin.subs.delJobSubs
  );
  const jobSubs = useMemo(() => {
    console.log("LARGE LIST RENDERED");
    return (
      subs[job] &&
      subs[job].sort((a, b) =>
        a.sub_no.localeCompare(b.sub_no, undefined, {
          numeric: true,
          sensitivity: "base"
        })
      )
    );
  }, [job, subs]);
  const cmpnySubList = useMemo(
    () =>
      Object.values(cmpnySubs)
        .map(item => ({
          key: item.sub_no,
          value: item.sub_no,
          text: `${item.sub_no} - ${item.sub_name}`
        }))
        .sort((a, b) =>
          a.key.localeCompare(b.key, undefined, {
            numeric: true,
            sensitivity: "base"
          })
        ),
    [cmpnySubs]
  );
  useEffect(() => {
    dispatch(requestData(subActionParams(cmpny, job)));
  }, [cmpny, job, dispatch]);
  useEffect(() => {
    fetchCmpnySubs(cmpny);
  }, [cmpny, fetchCmpnySubs]);

  return (
    <Dimmer.Dimmable
      as={Segment}
      dimmed={isSubLoading}
      blurring
      style={{ maxWidth: "800px" }}
    >
      <Dimmer active={isSubLoading} inverted>
        <Loader>Loading</Loader>
      </Dimmer>
      <Header as="h3">Edit Subcontractors</Header>
      <DD
        placeholder="Select Company Subcontractor"
        fluid
        search
        multiple
        selection
        clearable
        options={cmpnySubList}
        value={currentValue}
        onChange={(e, { value }) => {
          console.log("VALUE: ", value);
          setCurrentValue(value);
          value.length !== 0 ? setIsDisabled(false) : setIsDisabled(true);
        }}
      />
      <AddButton
        disabled={isDisabled}
        primary
        onClick={() => {
          addCmpnySubs({
            data: shapeSubData(currentValue, cmpnySubs, cmpny, jobInfo, job),
            job,
            cmpny
          });
          setCurrentValue([]);
        }}
      >
        Add Subcontractor
      </AddButton>
      <List divided relaxed>
        {jobSubs &&
          jobSubs.map(x => {
            return (
              <List.Item key={x.sub_no}>
                {x.sub_no} - {x.sub_name}
                <List.Content floated="right">
                  <Button
                    onClick={() => {
                      delCmpnySubs({
                        sub_no: x.sub_no,
                        job,
                        cmpny
                      });
                    }}
                    negative
                    size="small"
                  >
                    Delete
                  </Button>
                </List.Content>
              </List.Item>
            );
          })}
      </List>
    </Dimmer.Dimmable>
  );
}

export default AdminEditJobSubs;
