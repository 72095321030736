import React, { useMemo } from "react";
import { useStoreState, useStoreActions } from "easy-peasy";
import {
  Header,
  Segment,
  Form,
  Dimmer,
  Loader,
  Radio
} from "semantic-ui-react";
import { Formik, Field } from "formik";
import { userFormSchema } from "./FormSchema";
import { SemanticField } from "../utils/SemanticField";
import MaskedInput from "react-text-mask";

const SemanticMaskInput = ({
  label,
  error,
  form: { handleChange, handleBlur },
  field: { name },
  ...otherProps
}) => {
  console.log(otherProps);
  return (
    <Form.Field>
      <label>{label}</label>
      <MaskedInput
        id={name}
        name={name}
        onChange={handleChange}
        onBlur={handleBlur}
        {...otherProps}
      />
    </Form.Field>
  );
};

const renderRadioField = ({
  label,
  error,
  checked,
  form: { setFieldValue, handleBlur, values },
  field: { name, value },
  ...otherProps
}) => {
  return (
    <Radio
      value={value}
      name={name}
      checked={values.role === checked}
      label={label}
      onChange={(e, { value }) => {
        setFieldValue(name, value);
      }}
      onBlur={handleBlur}
      {...otherProps}
    />
  );
};

const roleList = [
  { value: "PM", name: "Project Manager" },
  { value: "Super", name: "Superintendent" },
  { value: "RegionAdmin", name: "Regional Administrator" }
];

function AdminUserAdd(props) {
  const cmpnyUsers = useStoreState(state => state.admin.cmpnyUsers);
  const cmpnyRegions = useStoreState(state => state.admin.cmpnyRegions);
  const cmpny = useStoreState(state => state.group);
  const isUsersLoading = useStoreState(state => state.admin.isUsersLoading);
  console.log("LOADING STATUS: ", cmpnyRegions);
  const addCmpnyUser = useStoreActions(actions => actions.admin.addCmpnyUser);
  const cmpnyUserValidationList = useMemo(
    () =>
      Object.values(cmpnyUsers)
        .map(item => item.email)
        .sort((a, b) => a.localeCompare(b)),
    [cmpnyUsers]
  );
  const cmpnyRegionList = props.cmpnyRegionList;
  console.log("LIST", cmpnyUserValidationList);
  const handleAltSubmit = values => {
    const data = {
      ...values,
      phoneNumber: values.phoneNumber.replace(/^(\+)|\D/g, "$1")
    };
    console.log(data);
    addCmpnyUser({ cmpny, data });
    console.log("getting here");
  };

  return (
    <Formik
      initialValues={{
        email: "",
        fname: "",
        lname: "",
        role: "PM",
        region: "01",
        phoneNumber: ""
      }}
      validationSchema={userFormSchema(cmpnyUserValidationList)}
      onSubmit={(items, { resetForm }) => {
        console.log("getting here");
      }}
    >
      {({
        values,
        errors,
        setFieldValue,
        setTouched,
        isSubmitting,
        isValid,
        touched,
        handleSubmit,
        handleBlur,
        resetForm,
        ...rest
      }) => {
        console.log(values);
        return (
          <Dimmer.Dimmable as={Segment} dimmed={isUsersLoading} blurring>
            <Dimmer active={isUsersLoading} inverted>
              <Loader>Loading</Loader>
            </Dimmer>
            <Header as="h3">Add Company User</Header>
            <Form>
              <SemanticField
                component={Form.Input}
                fluid
                label="Email"
                placeholder="Email Address"
                name="email"
                error={errors.email && touched.email ? errors.email : false}
              />
              <SemanticField
                component={Form.Input}
                fluid
                label="First Name"
                placeholder="First Name"
                name="fname"
                error={errors.fname && touched.fname ? errors.fname : false}
              />
              <SemanticField
                component={Form.Input}
                fluid
                label="Last Name"
                placeholder="Last Name"
                name="lname"
                error={errors.lname && touched.lname ? errors.lname : false}
              />
              <Form.Field label="Role">Role</Form.Field>
              {roleList.map(item => {
                return (
                  <Form.Field key={item.value}>
                    <Field
                      component={renderRadioField}
                      label={item.name}
                      value={item.value}
                      checked={item.value}
                      name="role"
                    />
                  </Form.Field>
                );
              })}
              <SemanticField
                component={Form.Dropdown}
                options={cmpnyRegionList}
                fluid
                label="Region"
                placeholder="Select User Region"
                selection
                name="region"
                error={errors.region && touched.region ? errors.region : false}
              />
              <Field
                component={SemanticMaskInput}
                label="Mobile Number"
                placeholder="XXX-XXX-XXXX"
                mask={[
                  "+",
                  "1",
                  " ",
                  "(",
                  /[1-9]/,
                  /\d/,
                  /\d/,
                  ")",
                  " ",
                  /\d/,
                  /\d/,
                  /\d/,
                  "-",
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/
                ]}
                name="phoneNumber"
                error={
                  errors.phoneNumber && touched.phoneNumber
                    ? errors.phoneNumber
                    : false
                }
              />

              <Form.Group>
                <Form.Button
                  disabled={isSubmitting || !isValid}
                  type="submit"
                  onClick={() => {
                    handleAltSubmit(values);
                    resetForm();
                  }}
                  primary
                >
                  Create User
                </Form.Button>
              </Form.Group>
            </Form>
          </Dimmer.Dimmable>
        );
      }}
    </Formik>
  );
}

export default AdminUserAdd;
