import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Menu } from "semantic-ui-react";
import "./App.css";
import Routes from "../Routes";
import { Auth } from "aws-amplify";
import { connect } from "react-redux";
import { purgeStoredState } from "redux-persist";
import { persistConfig } from "../index";
import {
  makeIsAdmin,
  makeIsSuperAdmin,
  makeIsRegionAdmin,
  makeJobList
} from "./AppSelectors";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,
      isAuthenticating: true,
      activeItem: "clock outline"
    };
  }

  async componentDidMount() {
    try {
      console.log("Trying to Auth");
      if (await Auth.currentSession()) {
        this.userHasAuthenticated(true);
      }
    } catch (e) {
      if (e !== "No current user") {
        console.log(e);
      }
    }

    this.setState({ isAuthenticating: false });
  }

  userInfoUpdate = (user, cmpny) => {
    this.setState({ user, cmpny });
  };

  userHasAuthenticated = authenticated => {
    this.setState({ isAuthenticated: authenticated });
  };

  handleLogout = async event => {
    await Auth.signOut();
    this.userHasAuthenticated(false);
    //await store.dispatch.reset();
    await purgeStoredState(persistConfig);
    this.props.history.push("/login");
    window.location.reload();
  };

  render() {
    console.log(this.props);
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated,
      jobList: this.props.jobList,
      isAdmin: this.props.isAdmin,
      isRegionAdmin: this.props.isRegionAdmin,
      isSuperAdmin: this.props.isSuperAdmin
    };
    return (
      !this.state.isAuthenticating && (
        <div className="App-header">
          <Menu style={{ background: "white" }} pointing secondary fixed="top">
            <Menu.Item match="/" link as={Link} to="/">
              Murnane Timekeeper
            </Menu.Item>
            {this.state.isAuthenticated ? (
              <Menu.Menu position="right">
                {this.props.isAdmin && (
                  <Menu.Item
                    as={Link}
                    to="/admin"
                    match={this.props.match}
                    active={
                      this.props.location.pathname === "/admin" ? true : false
                    }
                    history={this.props.history}
                  >
                    Admin
                  </Menu.Item>
                )}
                <Menu.Item className="Change" onClick={this.handleLogout}>
                  Logout
                </Menu.Item>
              </Menu.Menu>
            ) : (
              <Menu.Menu position="right">
                <Menu.Item
                  as={Link}
                  to="/login"
                  match={this.props.match}
                  active={
                    this.props.location.pathname === "/login" ? true : false
                  }
                  history={this.props.history}
                >
                  Login
                </Menu.Item>
              </Menu.Menu>
            )}
          </Menu>
          <ToastContainer
            containerId={"general"}
            enableMultiContainer
            position="bottom-center"
            autoClose={1000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnVisibilityChange
            draggable
            pauseOnHover
          />
          <div style={{ marginTop: "5em" }}>
            <Routes childProps={childProps} />
          </div>
        </div>
      )
    );
  }
}

const makeMapStateToProps = () => {
  const getIsAdmin = makeIsAdmin();
  const getIsSuperAdmin = makeIsSuperAdmin();
  const getIsRegionAdmin = makeIsRegionAdmin();
  const getJobList = makeJobList();
  const mapStateToProps = (state, props) => {
    return {
      isAdmin: getIsAdmin(state),
      isRegionAdmin: getIsRegionAdmin(state),
      isSuperAdmin: getIsSuperAdmin(state),
      jobList: getJobList(state)
    };
  };
  return mapStateToProps;
};

App = connect(makeMapStateToProps)(App);

export default withRouter(App);
