import React, { Fragment, useState, useEffect } from "react";
import { useStoreState } from "easy-peasy";
import { Header, Button, Segment, Grid, Form } from "semantic-ui-react";
import AdminEditJobCodes from "./AdminEditJobCodes";
import AdminEditJobUsers from "./AdminEditJobUsers";
import AdminEditJobSubs from "./AdminEditJobSubs";

function AdminEditJob(props) {
  const job = props.match.params.selectedJob;
  const jobInfo = useStoreState(state => state.jobs.entities.jobs[job]);
  const isAdmin = ["RegionAdmin", "Admin"].includes(
    useStoreState(state => state.role)
  );
  const labels = {
    job_name: "Job Name",
    latitude: "Latitude",
    longitude: "Longitude",
    maxRegHours: "Max Daily Hours Allowed on Job",
    region: "Location Code",
    ttl: "Projected Job End Date"
  };
  const [jobState, setJobState] = useState({
    cmpny: "",
    isOpen: true,
    job: "",
    job_name: "",
    job_sort: "",
    latitude: "",
    longitude: "",
    maxRegHours: "",
    region: "",
    sort_key: "",
    ttl: ""
  });
  useEffect(() => {
    setJobState(jobInfo);
  }, [jobInfo, setJobState]);
  console.log(jobState);

  return (
    <Fragment>
      <Header block>EDIT JOB INFORMATION</Header>
      <Segment>
        <Header as="h3">
          {job} - {jobState.job_name}
        </Header>
        <Form>
          <Form.Group widths="equal">
            {Object.keys(labels).map(item => {
              return (
                <Form.Input
                  key={item}
                  label={labels[item]}
                  name={item}
                  value={jobState[item]}
                  onChange={(e, { value, name }) =>
                    setJobState({ ...jobState, [name]: value })
                  }
                />
              );
            })}
          </Form.Group>
          <Button primary>Update</Button>
          <Button negative>Delete</Button>
        </Form>
      </Segment>
      <Grid stackable columns={2}>
        <Grid.Column>
          <AdminEditJobCodes job={job} />
        </Grid.Column>
        <Grid.Column>
          <AdminEditJobSubs job={job} />
        </Grid.Column>
        <Grid.Column>{isAdmin && <AdminEditJobUsers job={job} />}</Grid.Column>
      </Grid>
    </Fragment>
  );
}

export default AdminEditJob;
