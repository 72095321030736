import React, { useState } from "react";
import { useStoreState } from "easy-peasy";
import {
  Header,
  Segment,
  Form,
  Dimmer,
  Loader,
  Button
} from "semantic-ui-react";
import { Formik } from "formik";
import { timeFormSchema } from "./FormSchema";
import { SemanticField } from "../utils/SemanticField";
import { API } from "aws-amplify";
import { toast } from "react-toastify";
import moment from "moment";

export default props => {
  const cmpny = useStoreState(state => state.group);
  const [isFetching, setIsFetching] = useState(false);
  const initialDate = new Date();

  return (
    <div style={{ maxWidth: "500px" }}>
      <Button
        labelPosition="left"
        icon="left chevron"
        content="Back"
        onClick={() => props.history.goBack()}
      />
      <Header as="h3">
        Get Time - Current Week: {moment(initialDate).format("ww")}
      </Header>
      <Formik
        initialValues={{
          weekNo: "",
          year: ""
        }}
        validationSchema={timeFormSchema}
        onSubmit={async (items, { resetForm }) => {
          setIsFetching(true);
          try {
            const response = await API.get(
              "time",
              `admin/${cmpny}/weektime/${items.weekNo}/${items.year}`,
              {
                Accept: "text/csv",
                responseType: "text"
              }
            );
            console.log(response);
            let link = document.createElement("a");
            link.setAttribute(
              "href",
              "data:text/csv;charset=utf-8,%EF%BB%BF" +
                encodeURIComponent(response)
            );
            console.log(link);
            link.setAttribute(
              "download",
              `${items.year} - ${items.weekNo} Time Download.csv`
            );
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setIsFetching(false);
            resetForm();
          } catch (e) {
            setIsFetching(false);
            resetForm();
            toast.error("No Time Found For This Week", {
              containerId: "general"
            });
            console.log(e);
          }
        }}
      >
        {({
          values,
          errors,
          setFieldValue,
          setTouched,
          isSubmitting,
          isValid,
          touched,
          handleSubmit,
          handleBlur,
          ...rest
        }) => {
          console.log(isValid);
          /* region: data.region,
        email: data.email,
        phone: data.phoneNumber,
        job_name: data.job_name,
        latitude: data.latitude,
        role: data.role */
          return (
            <Dimmer.Dimmable as={Segment} dimmed={isFetching} blurring>
              <Dimmer active={isFetching} inverted>
                <Loader>Loading</Loader>
              </Dimmer>
              <Form>
                <SemanticField
                  component={Form.Input}
                  fluid
                  label="Week Number"
                  placeholder="Enter Week of Year to Fetch Time"
                  name="weekNo"
                />
                <SemanticField
                  component={Form.Input}
                  fluid
                  label="Year"
                  placeholder="Year"
                  name="year"
                />
                <Form.Group>
                  <Form.Button
                    disabled={isSubmitting || !isValid}
                    type="submit"
                    onClick={handleSubmit}
                    primary
                  >
                    Get Week Time
                  </Form.Button>
                </Form.Group>
              </Form>
            </Dimmer.Dimmable>
          );
        }}
      </Formik>
    </div>
  );
};
