import React, { useState, useCallback, useEffect, memo } from "react";
import { useStoreState, useStoreActions } from "easy-peasy";
import {
  Dropdown,
  Header,
  Dimmer,
  Segment,
  Loader,
  Form
} from "semantic-ui-react";
import { Formik } from "formik";
import { subFormEditSchema } from "./FormSchema";
import { SemanticField } from "../utils/SemanticField";

function AdminEditCode({ cmpnySubs, cmpnySubList, cmpny }) {
  const isSubLoading = useStoreState(state => state.admin.subs.isSubLoading);
  const [currentSub, setCurrentSub] = useState("");
  const [currentSubInitialState, setCurrentSubInitialState] = useState({
    sub_no: "",
    sub_name: ""
  });

  const deleteCmpnySub = useStoreActions(
    actions => actions.admin.subs.delCmpnySub
  );
  const addCmpnySub = useStoreActions(
    actions => actions.admin.subs.addCmpnySub
  );

  const handleDropdownChange = useCallback(value => {
    console.log("handleDropdownChange re-rendered");
    setCurrentSub(value);
  }, []);

  const handleClick = () => {
    deleteCmpnySub({ cmpny, sub_no: currentSub });
    setCurrentSub("");
  };
  useEffect(() => {
    currentSub &&
      setCurrentSubInitialState({
        sub_no: currentSub,
        sub_name: cmpnySubs[currentSub].sub_name
      });
  }, [currentSub, cmpnySubs]);

  return (
    <Dimmer.Dimmable as={Segment} dimmed={isSubLoading} blurring>
      <Dimmer active={isSubLoading} inverted>
        <Loader>Loading</Loader>
      </Dimmer>
      <Header as="h3">Edit Subcontractor</Header>
      <ExpensiveDropdown
        subOptions={cmpnySubList}
        currentValue={currentSub}
        changeValue={handleDropdownChange}
      />
      {currentSub && (
        <Formik
          enableReinitialize //Allows Expensive Dropdown to set the Initial State
          initialValues={currentSubInitialState}
          validationSchema={subFormEditSchema(
            cmpnySubs,
            currentSubInitialState.sub_no
          )}
          onSubmit={async (data, { resetForm }) => {
            // same shape as initial values
            await addCmpnySub({ cmpny, data });
            if (currentSub !== data.sub_no) {
              let tempSub = currentSub;
              setCurrentSub("");
              await deleteCmpnySub({ cmpny, sub_no: tempSub });
              console.log("getting here");
            }
            //resetForm();
          }}
        >
          {({
            values,
            errors,
            setFieldValue,
            setTouched,
            isSubmitting,
            isValid,
            touched,
            handleSubmit,
            handleBlur,
            ...rest
          }) => {
            return (
              <Segment>
                <Form>
                  <SemanticField
                    component={Form.Input}
                    fluid
                    label="Subcontractor Number"
                    placeholder="Company Subcontractor Number"
                    name="sub_no"
                  />
                  <SemanticField
                    component={Form.Input}
                    fluid
                    label="Subcontractor Name"
                    placeholder="Subcontractor Name"
                    name="sub_name"
                  />
                  <Form.Button
                    disabled={isSubmitting || !isValid}
                    type="submit"
                    onClick={handleSubmit}
                    color="green"
                  >
                    Update Subcontractor
                  </Form.Button>
                  <Form.Button
                    disabled={isSubmitting}
                    onClick={handleClick}
                    negative
                  >
                    Delete Subcontractor
                  </Form.Button>
                </Form>
              </Segment>
            );
          }}
        </Formik>
      )}
    </Dimmer.Dimmable>
  );
}

const ExpensiveDropdown = memo(({ subOptions, currentValue, changeValue }) => {
  const handleChange = value => {
    changeValue(value);
  };
  return (
    <Dropdown
      placeholder="Subcontractor"
      fluid
      search
      selection
      clearable
      options={Object.values(subOptions)}
      value={currentValue}
      onChange={(e, { value }) => handleChange(value)}
    />
  );
});

export default AdminEditCode;
