import React from "react";
import { Button, Popup, List } from "semantic-ui-react";
import styled from "styled-components";
import Modal from "../timeentrymodal/modalForm";
import TimeEntry from "../timeentry/TimeEntry";

const TimeHeaderComponent = props => {
  const AlignedButtons = styled.div`
    display: flex;
  `;
  const empWithoutTime = props.employeesByJob
    ? props.employeesByJob
        .filter(x => !props.time.sortedList.includes(x.emp))
        .map(x => `${x.fname} ${x.lname}`)
    : [];

  const missingTimeList = (
    <div>
      <h5>No time entered for:</h5>
      <List>
        {empWithoutTime.map(x => (
          <List.Item key={x}>{x}</List.Item>
        ))}
      </List>
    </div>
  );

  return (
    <AlignedButtons>
      <Modal
        buttonName="Add Time"
        render={props => {
          console.log(props);
          return <TimeEntry handleClose={props.handleClose} {...props} />;
        }}
        {...props}
      />
      {empWithoutTime.length > 0 && (
        <Popup
          trigger={
            <Button basic color="red">
              {empWithoutTime.length}!
            </Button>
          }
          flowing
          content={missingTimeList}
          on="click"
          position="bottom center"
        />
      )}
    </AlignedButtons>
  );
};

export default TimeHeaderComponent;
