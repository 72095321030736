import React from "react";
import { Grid, Button, Icon, Label } from "semantic-ui-react";
import moment from "moment";
import styled from "styled-components";
import TimeHeaderComponent from "./TimeHeaderComponent";
import ReportHeaderComponent from "./ReportHeaderComponent";

const Header = styled(Grid)`
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
`;

const TimeHeader = props => {
  console.log("TIME HEADER PROPS: ", props);
  const { selectedDay, selectedJob } = props.match.params;

  const renderView = () => {
    switch (props.activeItem) {
      case "clock outline":
        return <TimeHeaderComponent {...props} />;
      case "file alternate outline":
        return <ReportHeaderComponent {...props} />;
      default:
        return <div />;
    }
  };

  return (
    <Header centered columns={1}>
      <Grid.Row>
        <Button.Group icon>
          <Button
            onClick={() =>
              props.history.push(
                `/${selectedJob}/${moment(selectedDay)
                  .subtract(1, "days")
                  .format("YYYY-MM-DD")}`
              )
            }
          >
            <Icon name="chevron left" />
          </Button>
          <Label size="large">
            {props.jobInfo.job_name}
            <br />
            {moment(props.match.params.selectedDay).format("MM/DD/YY dddd")}
          </Label>
          <Button
            onClick={() =>
              props.history.push(
                `/${selectedJob}/${moment(selectedDay)
                  .add(1, "days")
                  .format("YYYY-MM-DD")}`
              )
            }
          >
            <Icon name="chevron right" />
          </Button>
        </Button.Group>
      </Grid.Row>
      <Grid.Row>{renderView()}</Grid.Row>
    </Header>
  );
};

export default TimeHeader;
