import React from "react";
import { useStoreState, useStoreActions } from "easy-peasy";
import { Header, Segment, Form, Dimmer, Loader } from "semantic-ui-react";

import { Formik } from "formik";
import { ccFormSchema } from "./FormSchema";
import { SemanticField } from "../utils/SemanticField";

function AdminAddCode() {
  const cmpnyCostCodes = useStoreState(state => state.admin.cmpnyCostCodes);
  const cmpny = useStoreState(state => state.group);
  const isCCLoading = useStoreState(state => state.admin.isCCLoading);
  console.log("LOADING STATUS: ", isCCLoading);
  const addCmpnyCostCode = useStoreActions(
    actions => actions.admin.addCmpnyCostCode
  );

  return (
    <Formik
      initialValues={{
        cc: "",
        descr: ""
      }}
      validationSchema={ccFormSchema(cmpnyCostCodes)}
      onSubmit={(data, { resetForm }) => {
        // same shape as initial values
        addCmpnyCostCode({ cmpny, data });
        console.log("getting here");
        resetForm();
      }}
    >
      {({
        values,
        errors,
        setFieldValue,
        setTouched,
        isSubmitting,
        isValid,
        touched,
        handleSubmit,
        handleBlur,
        ...rest
      }) => {
        console.log(values, errors, touched, "REST:", rest);
        return (
          <Dimmer.Dimmable as={Segment} dimmed={isCCLoading} blurring>
            <Dimmer active={isCCLoading} inverted>
              <Loader>Loading</Loader>
            </Dimmer>
            <Header as="h3">Add Cost Code</Header>
            <Form>
              <SemanticField
                component={Form.Input}
                fluid
                label="Cost Code"
                placeholder="Cost Code"
                name="cc"
                error={errors.cc && touched.cc ? errors.cc : false}
              />
              <SemanticField
                component={Form.Input}
                fluid
                label="Description"
                placeholder="Description of Cost Code"
                name="descr"
                error={errors.descr && touched.descr ? errors.descr : false}
              />
              <Form.Group>
                <Form.Button
                  disabled={isSubmitting || !isValid}
                  type="submit"
                  onClick={handleSubmit}
                  primary
                >
                  Add Cost Code
                </Form.Button>
              </Form.Group>
            </Form>
          </Dimmer.Dimmable>
        );
      }}
    </Formik>
  );
}

export default AdminAddCode;
