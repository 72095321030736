import moment from "moment";
import { diff } from "deep-diff";

export const shapeFormData = (formData, employees, jobInfo, job, day) => {
  let currentTime = new Date().getTime();
  let we = String(moment(day).format("ww"));
  let year = String(moment(day).weekYear());
  let currentDay = moment(day).format("YYYY-MM-DD");
  let { employee, hours, ...restOfFormData } = formData;
  console.log(employees);
  let baseData = formData.employee.reduce((acc, emp) => {
    console.log(emp);
    let newObj = Object.assign(
      {},
      restOfFormData,
      { pk: `${jobInfo.cmpny}#${job}` },
      {
        sk: `t#${year}-${we}#${currentDay}#${emp}#${formData.cc}#${formData.type}`
      },
      { hours: parseFloat(hours) },
      { job: job },
      { trade: employees[emp].trade },
      { empName: `${employees[emp].lname}, ${employees[emp].fname}` },
      { clss: employees[emp].clss },
      { date: currentDay },
      { updated: currentTime },
      { emp: emp },
      { we: we },
      { maxRegHours: jobInfo.maxRegHours },
      { ttl: jobInfo.ttl }
    );
    console.log(acc.concat(newObj));
    return acc.concat(newObj);
  }, []);
  let timeUpdate = Object.assign(
    {},
    {
      pk: `${jobInfo.cmpny}#${job}`,
      sk: `t#${year}-${we}#${currentDay}#updated`,
      updated: `${currentTime}`,
      job,
      ttl: jobInfo.ttl
    }
  );
  return baseData.concat(timeUpdate);
};

export const shapeDeleteData = (formData, employees, jobInfo, job, day) => {
  let currentTime = new Date();
  let we = String(moment(day).format("ww"));
  let year = String(moment(day).weekYear());
  let currentDay = moment(day).format("YYYY-MM-DD");
  let { employee, hours, ...restOfFormData } = formData;
  console.log(employees);
  return formData.employee.reduce((acc, emp) => {
    console.log(emp);
    let newObj = Object.assign(
      {},
      restOfFormData,
      { pk: `${jobInfo.cmpny}#${job}` },
      {
        sk: `t%23${year}-${we}%23${currentDay}%23${emp}%23${formData.cc}%23${formData.type}`
      },
      { hours: parseFloat(hours) },
      { job: job },
      { trade: employees[emp].trade },
      { date: currentDay },
      { updated: currentTime },
      { emp: emp },
      { we: we },
      { maxRegHours: jobInfo.maxRegHours }
    );
    console.log(acc.concat(newObj));
    return acc.concat(newObj);
  }, []);
};

export const shapeReportData = (
  formData,
  originalFormState,
  jobInfo,
  job,
  day
) => {
  let currentTime = Date.now();
  let we = String(moment(day).format("ww"));
  let year = String(moment(day).weekYear());
  let currentDay = moment(day).format("YYYY-MM-DD");
  let { isSaveDisabled: x, isPrintDisabled: y, ...restOfForm } = formData;
  //shape formData to compare
  let modifiedForm = Object.entries(restOfForm).reduce((agg, [key, item]) => {
    switch (key) {
      case "report":
        return {
          ...agg,
          report: {
            ...item,
            pk: `${jobInfo.cmpny}#${job}`,
            sk: `d#r#${year}-${we}#${currentDay}#r`,
            ttl: jobInfo.ttl,
            updated: currentTime
          }
        };
      case "subs":
      case "eq":
        let addedObj = Object.keys(item).reduce((tempagg, x) => {
          let { timeAdd: omit, ...restOfItem } = item[x];
          return {
            ...tempagg,
            [x]: {
              ...restOfItem,
              pk: `${jobInfo.cmpny}#${job}`,
              sk: `d#r#${year}-${we}#${currentDay}#${key.charAt(0)}#${x}`,
              ttl: jobInfo.ttl,
              updated: currentTime
            }
          };
        }, {});
        return {
          ...agg,
          [key]: addedObj
        };
      default:
        console.log("No Case Matched!!!");
        return agg;
    }
  }, {});
  //ensure that a date is included with report
  modifiedForm = {
    ...modifiedForm,
    ...(!modifiedForm.report.date && {
      report: { ...modifiedForm.report, date: currentDay }
    })
  };
  console.log(modifiedForm);
  let { isSaveDisabled, isPrintDisabled, ...restOfState } = originalFormState;
  let differences = diff(restOfState, modifiedForm);
  console.log(restOfForm, restOfState);
  console.log(differences);
  let valueObj = differences.reduce(
    (agg, x) => {
      const tempObj = {
        ...(x.path[0] === "report" && { report: modifiedForm[x.path[0]] }),
        ...(x.path[0] === "subs" && {
          [`s#${x.path[1]}`]: modifiedForm[x.path[0]][x.path[1]]
        }),
        ...(x.path[0] === "eq" && {
          [`e#${x.path[1]}`]: modifiedForm[x.path[0]][x.path[1]]
        })
      };
      console.log(tempObj);
      switch (x.kind) {
        case "E":
          return { ...agg, put: { ...agg["put"], ...tempObj } };
        case "D":
          //create a delete object from previous state instead of modified
          //don't need report part of object, since report shouldn't be deleted.
          //it would only be edited to empty strings under "E"
          const deleteObj = {
            ...(x.path[0] === "subs" && {
              [`s#${x.path[1]}`]: restOfState[x.path[0]][x.path[1]]
            }),
            ...(x.path[0] === "eq" && {
              [`e#${x.path[1]}`]: restOfState[x.path[0]][x.path[1]]
            })
          };
          return { ...agg, delete: { ...agg["delete"], ...deleteObj } };
        case "N":
          return { ...agg, put: { ...agg["put"], ...tempObj } };
        default:
          return agg;
      }
    },
    { put: {}, delete: {} }
  );
  let timeUpdate = Object.assign(
    {},
    {
      pk: `${jobInfo.cmpny}#${job}`,
      sk: `d#r#${year}-${we}#${currentDay}#updated`,
      updated: `${currentTime}`,
      job,
      date: currentDay,
      ttl: jobInfo.ttl
    }
  );
  let newarr = [];
  newarr.push(timeUpdate);
  return { ...valueObj, timeUpdate: newarr };
};

export const shapeCCData = (formData, stateData, cmpny, jobInfo, job) => {
  return formData.map(cc => ({
    pk: `${cmpny}#${job}`,
    sk: `cc#${cc}`,
    descr: stateData[cc].descr,
    cc: cc,
    ttl: jobInfo.ttl
  }));
};

export const shapeSubData = (formData, stateData, cmpny, jobInfo, job) => {
  return formData.map(subNo => ({
    pk: `${cmpny}#${job}`,
    sk: `sub#${subNo}`,
    sub_name: stateData[subNo].sub_name,
    sub_no: subNo,
    ttl: jobInfo.ttl
  }));
};

export const shapeUserData = (formData, stateData, cmpny, jobInfo, job) => {
  return formData.map(cc => ({
    pk: `${cmpny}#${job}`,
    sk: `cc#${cc}`,
    descr: stateData[cc].descr,
    cc: cc,
    ttl: jobInfo.ttl
  }));
};
