export const ADD_TIME = "ADD_TIME";
export const RECEIVE_TIME_DATA = "RECEIVE_TIME_DATA";
export const RECEIVE_REPORT_DATA = "RECEIVE_REPORT_DATA";
export const SUBMIT_TIME_DATA = "SUBMIT_TIME_DATA";
export const SUBMIT_REPORT_DATA = "SUBMIT_REPORT_DATA";
export const UPDATE_REPORT_TIMESTAMP = "UPDATE_REPORT_TIMESTAMP";
export const MODIFY_TIME_DATA = "MODIFY_TIME_DATA";
export const FAILED_SUBMIT_TIME = "FAILED_SUBMIT_TIME";
export const RECEIVE_EMPLOYEE_DATA = "RECEIVE_EMPLOYEE_DATA";
export const RECEIVE_COST_CODE_DATA = "RECEIVE_COST_CODE_DATA";
export const RECEIVE_SUB_DATA = "RECEIVE_SUB_DATA";
export const RECEIVE_TOTALS_DATA = "RECEIVE_TOTALS_DATA";
export const DELETE_TIME_DATA = "DELETE_TIME_DATA";
export const DELETE_REPORT_DATA = "DELETE_REPORT_DATA";
export const DELETE_TOTALS_DATA = "DELETE_TOTALS_DATA";
export const ADD_COST_CODE_DATA = "ADD_COST_CODE_DATA";
export const ADD_SUB_DATA = "ADD_SUB_DATA";
export const DELETE_COST_CODE_DATA = "DELETE_COST_CODE_DATA";
export const DELETE_SUB_DATA = "DELETE_SUB_DATA";
export const UPDATE_MISSING_EMPLOYEE_DATA = "UPDATE_MISSING_EMPLOYEE_DATA";

export const addTime = payload => ({
  type: ADD_TIME,
  payload
});
