import React from "react";
import PropTypes from "prop-types";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import App from "../app/App";
import history from "../history";
import { PersistGate } from "redux-persist/integration/react";
import { StoreProvider } from "easy-peasy";

const Root = ({ store, persistor }) => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <StoreProvider store={store}>
        <Router history={history}>
          <App />
        </Router>
      </StoreProvider>
    </PersistGate>
  </Provider>
);

Root.propTypes = {
  store: PropTypes.object.isRequired
};

export default Root;
