import {
  RECEIVE_REPORT_DATA,
  RECEIVE_TIME_DATA,
  RECEIVE_COST_CODE_DATA,
  RECEIVE_EMPLOYEE_DATA,
  RECEIVE_TOTALS_DATA,
  RECEIVE_SUB_DATA
} from "./TimesheetActions";

export const empActionParams = (cmpny, selectedJob) => ({
  actionType: RECEIVE_EMPLOYEE_DATA,
  name: "time",
  url: `time/${cmpny}/${selectedJob}/emp`,
  job: selectedJob
});
export const ccActionParams = (cmpny, selectedJob) => ({
  actionType: RECEIVE_COST_CODE_DATA,
  name: "time",
  url: `time/${cmpny}/${selectedJob}/codes`,
  job: selectedJob
});

export const subActionParams = (cmpny, selectedJob) => ({
  actionType: RECEIVE_SUB_DATA,
  name: "time",
  url: `time/${cmpny}/${selectedJob}/subs`,
  job: selectedJob
});

export const timeActionParams = (cmpny, selectedJob) => ({
  actionType: RECEIVE_TIME_DATA,
  name: "time",
  url: `time/${cmpny}/${selectedJob}`,
  job: selectedJob
});
export const totalsActionParams = (cmpny, selectedDay, selectedJob) => ({
  actionType: RECEIVE_TOTALS_DATA,
  name: "time",
  url: `time/${cmpny}/totals/${selectedDay}`,
  job: selectedJob
});
export const reportActionParams = (cmpny, selectedJob) => ({
  actionType: RECEIVE_REPORT_DATA,
  name: "time",
  url: `report/${cmpny}/${selectedJob}`,
  job: selectedJob
});
