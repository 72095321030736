import { reducer } from "easy-peasy";
import { userName } from "../login/loginReducer";
import { jobs, group, role } from "../landing/landingReducer";
import {
  selectedDay,
  calendarFocus,
  calendarDaysWithTime
} from "../calendar/calendarReducer";
import {
  time,
  report,
  timeTotalsByWeek,
  employeesByJob,
  employeesInSummary,
  allEmployees,
  costCodesByJob,
  subsByJob,
  isLoading
} from "../timesheet/timesheetReducer";
import adminModel from "../admin/AdminModel";

const model = {
  userName: reducer(userName),
  selectedDay: reducer(selectedDay),
  group: reducer(group),
  role: reducer(role),
  employeesByJob: reducer(employeesByJob),
  employeesInSummary: reducer(employeesInSummary),
  allEmployees: reducer(allEmployees),
  costCodesByJob: reducer(costCodesByJob),
  costCode: reducer({ costCode: (state = {}) => state }),
  calendarFocus: reducer(calendarFocus),
  calendarDaysWithTime: reducer(calendarDaysWithTime),
  jobs: reducer(jobs),
  time: reducer(time),
  report: reducer(report),
  subsByJob: reducer(subsByJob),
  timeTotalsByWeek: reducer(timeTotalsByWeek),
  isLoading: reducer(isLoading),
  admin: adminModel
};

export { model };
