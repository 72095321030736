import React from "react";
import moment from "moment";
import "./Calendar.css";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { SingleDatePicker } from "react-dates";
import { Button } from "semantic-ui-react";
import isInclusivelyAfterDay from "../utils/isInclusivelyAfterDay";
import isSameDay from "../utils/isSameDay";
import history from "../history";

class JobCalendar extends React.Component {
  /*    componentDidMount() {
        console.log(moment("02-24-2018").week() == moment("02-24-2018").week()),
            console.log(moment().format("MMDDYYYY")),
            console.log(
                moment("03-26-2018")
                    .endOf("week")
                    .format("MMDDYYYY")
            );
    }*/
  render() {
    console.log(this.props);
    const {
      selectedDay,
      focused,
      handleDateChange,
      handleFocusChange,
      calendarDaysWithTime
    } = this.props;

    const selectedJob = this.props.match.params.selectedJob;
    /*        if (
            !this.props.jobs.allIds.some(el => {
                return el === selectedJob;
            })
        ) {
            return <div>Not Found</div>;
        }*/

    return calendarDaysWithTime[selectedJob] ? (
      <div className="button-pad">
        <div className="item-pad">
          Please select date for job {selectedJob}:
        </div>
        <div className="item-pad">
          <SingleDatePicker
            date={moment(selectedDay)} // momentPropTypes.momentObj or null
            onDateChange={date => handleDateChange(date)} // PropTypes.func.isRequired
            focused={typeof focused !== "boolean" ? false : focused} // PropTypes.bool
            numberOfMonths={1}
            isDayHighlighted={day1 =>
              calendarDaysWithTime[selectedJob].some(day2 =>
                isSameDay(day1, moment(day2))
              )
            }
            isOutsideRange={day =>
              !isInclusivelyAfterDay(day, moment().subtract(2, "weeks"))
            }
            onFocusChange={({ focused }) => handleFocusChange(focused)} // PropTypes.func.isRequired
          />
        </div>
        <Button
          onClick={() =>
            history.push(
              `/${selectedJob}/${moment(selectedDay).format("YYYY-MM-DD")}`
            )
          }
          size="large"
        >
          Submit
        </Button>
      </div>
    ) : (
      <div>Loading</div>
    );
  }
}

export default JobCalendar;
