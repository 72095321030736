const dev = {
  s3: {
    REGION: "YOUR_DEV_S3_UPLOADS_BUCKET_REGION",
    BUCKET: "YOUR_DEV_S3_UPLOADS_BUCKET_NAME",
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://z4buxj412e.execute-api.us-east-1.amazonaws.com/dev/",
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_DgAZffvBS",
    APP_CLIENT_ID: "5atuq8v9obejjp1le3g1o450jt",
    IDENTITY_POOL_ID: "us-east-1:e5ffd520-79a8-47f9-a488-0fb6a04ce620",
  },
};

const prod = {
  s3: {
    REGION: "YOUR_PROD_S3_UPLOADS_BUCKET_REGION",
    BUCKET: "YOUR_PROD_S3_UPLOADS_BUCKET_NAME",
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://z9qtqw9iel.execute-api.us-east-1.amazonaws.com/prod/",
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_Nd9oBDSXH",
    APP_CLIENT_ID: "5cg6c5i2nkbd94uv1l6gls0h7s",
    IDENTITY_POOL_ID: "us-east-1:dd8a3050-aefd-4cc3-ab06-500acff88097",
  },
};

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === "prod" ? prod : dev;

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config,
};
