import React from "react";
import { Form, Grid, Segment } from "semantic-ui-react";
import LoaderButton from "../components/LoaderButton";
import { Formik } from "formik";
import * as Yup from "yup";
import { SemanticField } from "../utils/SemanticField";

const LoginNewPassword = ({ submitFuction, setIsSubmitting }) => (
  <Formik
    initialValues={{
      password: "",
      newPassword: "",
      confirmPass: ""
    }}
    validationSchema={Yup.object().shape({
      password: Yup.string().required("Required"),
      newPassword: Yup.string().required("Required"),
      confirmPass: Yup.string()
        .oneOf([Yup.ref("newPassword")], "Passwords do not match")
        .required("Required")
    })}
    onSubmit={(values, { setSubmitting, resetForm }) => {
      setIsSubmitting(true);
      submitFuction(values);
      resetForm();
    }}
  >
    {({ isSubmitting, isValid, handleSubmit }) => {
      return (
        <Grid.Column style={{ maxWidth: 450 }}>
          <Form>
            <Segment stacked>
              <SemanticField
                component={Form.Input}
                name="password"
                label="Current Password"
                placeholder="Old Password"
                type="password"
                fluid
                icon="lock"
                iconPosition="left"
              />
              <SemanticField
                component={Form.Input}
                name="newPassword"
                label="New Password"
                placeholder="Password"
                type="password"
                fluid
                icon="lock"
                iconPosition="left"
              />
              <SemanticField
                component={Form.Input}
                name="confirmPass"
                label="Confirm New Password"
                placeholder="New Password"
                type="password"
                fluid
                icon="lock"
                iconPosition="left"
              />
              <LoaderButton
                size="large"
                text="Login"
                disabled={!isValid}
                isSubmitting={isSubmitting}
                loadingText="Logging in…"
                type="submit"
                onClick={handleSubmit}
              />
            </Segment>
          </Form>
        </Grid.Column>
      );
    }}
  </Formik>
);

export default LoginNewPassword;
