import React, { useState } from "react";
import styled from "styled-components";

//add css to Burger Menu
const StyledBurgerMenu = styled.div`
  /* Position and sizing of burger button */
  .bm-burger-button {
    position: fixed;
    width: 28px;
    height: 20px;
    left: 36px;
    top: 50px;
  }

  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: #373a47;
  }

  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: #a90000;
  }

  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }

  /* Color/shape of close button cross */
  .bm-cross {
    background: #bdc3c7;
  }

  /*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
    left: 0;
  }

  /* General sidebar styles */
  .bm-menu {
    background: #0f4e03;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
  }

  .bm-menu a {
    color: #bdc3c7;

    &:hover,
    &:focus {
      color: #606b5e;
    }
  }

  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }

  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
  }

  /* Individual item */
  .bm-item {
    display: inline-block;
  }
  .bm-item:focus {
    outline: none;
  }

  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }
`;
// make a new context
const BurgerMenuContext = React.createContext();

// create the provider
const BurgerProvider = ({ children }) => {
  const [menuOpenState, setMenuOpenState] = useState(false);

  return (
    <BurgerMenuContext.Provider
      value={{
        isMenuOpen: menuOpenState,
        toggleMenu: () => setMenuOpenState(!menuOpenState),
        stateChangeHandler: newState => setMenuOpenState(newState.isOpen)
      }}
    >
      <StyledBurgerMenu>{children}</StyledBurgerMenu>
    </BurgerMenuContext.Provider>
  );
};

export { BurgerProvider, BurgerMenuContext };
