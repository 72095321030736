import { API } from "aws-amplify";

export async function postData(name, url, dataArray) {
  let dataObj = {};
  let successArray = [];
  let failArray = [];
  console.log(name, url, dataArray);
  try {
    const promises = dataArray.map(item => {
      return API.post(name, url, { body: item })
        .then(() => {
          dataObj["success"] = successArray.push(item);
          console.log("one success: ", item);
          console.log(successArray);
          console.log(dataObj);
          return dataObj;
        })
        .catch(e => {
          console.log(e, item);
          dataObj["errors"] = failArray.push(item);
          return dataObj;
        });
    });

    const data = await Promise.all(promises);
    console.log(data);
    console.log(successArray);
    console.log(failArray);
    console.log(dataObj);
    console.log("confirm we got here?");
    return { success: successArray, fail: failArray };
  } catch (e) {
    console.log("Something went very wrong");
    console.log(e);
    return;
  }
}
