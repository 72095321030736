import React from "react";
import { Route, Redirect } from "react-router-dom";

export default ({ component: C, props: cProps, ...rest }) => {
  console.log("CPROPS: ", cProps);
  console.log(rest);
  return (
    <Route
      {...rest}
      render={props => {
        if (
          cProps.isAuthenticated &&
          cProps.jobList.includes(rest.computedMatch.params.selectedJob)
        ) {
          return <C {...props} {...cProps} />;
        } else if (cProps.isAuthenticated) {
          return <Redirect to="/notfound" />;
        } else {
          return <Redirect to="/login" />;
        }
      }}
    />
  );
};
