import React, { Component } from "react";
import { Radio, Dropdown, Button, List, Image } from "semantic-ui-react";
import styled from "styled-components";

const CircleIcon = styled.div`
  display: inline-block;
  height: 40px;
  width: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 40px;
  color: white;
  font-size: 1.5em;
  margin-right: 25px;
  background-color: #0e9120;
`;
const EmployeeGrid = styled.div`
  margin: 10px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 10px;
`;
const RadioGrid = styled.div`
  margin: 10px;
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
`;

const RadioItem = styled(Radio)`
  padding: 5px;
  flex: 0 0 0 0;
`;

const RegButton = styled(Button)`
  width: 200px;
  height: 35px;
  float: left;
  display: inline-block;
`;

export default class EmployeeComponent extends Component {
  state = { dropdownValue: "", radioValue: "J" };

  handleClick = () => {
    this.props.onAddEmployee(this.state, this.props);
    this.setState({ dropdownValue: "", radioValue: "J" });
  };
  handleDropdownChange = (e, { value }) =>
    this.setState({ dropdownValue: value });

  handleChange = (e, { value }) => this.setState({ radioValue: value });

  render() {
    const toTitleCase = str => {
      return str.replace(/\w\S*/g, function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    };

    return (
      <div>
        <Button.Group>
          <Button
            labelPosition="left"
            icon="left chevron"
            content="Back"
            onClick={() => this.props.history.goBack()}
          />
          <Button disabled>
            {toTitleCase(this.props.jobInfo.job_name)} ({this.props.jobInfo.job}
            )
          </Button>
        </Button.Group>
        <EmployeeGrid>
          <Dropdown
            onChange={this.handleDropdownChange}
            placeholder="Select Employee to Add"
            fluid
            search
            selection
            loading={this.props.searchList.length > 0 ? false : true}
            ref="myField"
            options={this.props.searchList}
            value={this.state.dropdownValue}
          />
          <RegButton
            onClick={this.handleClick}
            disabled={!this.state.dropdownValue}
            primary
          >
            Add Employee to Job
          </RegButton>
        </EmployeeGrid>
        {this.state.dropdownValue !== "" && (
          <RadioGrid>
            <RadioItem
              label="Journeyworker"
              value="J"
              checked={this.state.radioValue === "J"}
              onChange={this.handleChange}
            />
            <RadioItem
              label="Foreman"
              value="F"
              checked={this.state.radioValue === "F"}
              onChange={this.handleChange}
            />
            <RadioItem
              label="Superintendent"
              value="S"
              checked={this.state.radioValue === "S"}
              onChange={this.handleChange}
            />
            <RadioItem
              label="Apprentice"
              value="A"
              checked={this.state.radioValue === "A"}
              onChange={this.handleChange}
            />
          </RadioGrid>
        )}

        <List divided verticalAlign="middle">
          {this.props.employeesByJob && this.props.employeesByJob.length > 0 ? (
            this.props.employeesByJob.map(employee => {
              //console.log(employee.trade.match(/\b(\w)/g).join(""));
              return (
                <List.Item key={employee.emp}>
                  <List.Content floated="right">
                    <Button
                      onClick={() =>
                        this.props.onDeleteEmployee(employee.emp, this.props)
                      }
                    >
                      Delete
                    </Button>
                  </List.Content>
                  <Image>
                    <CircleIcon>
                      {employee.clss !== "J" &&
                      employee.clss !== "A" &&
                      employee.clss !== undefined
                        ? `${employee.trade.match(/\b(\w)/g).join("")}${
                            employee.clss
                          }`
                        : `${employee.trade.match(/\b(\w)/g).join("")}`}
                    </CircleIcon>
                  </Image>
                  <List.Content>
                    {employee.fname} {employee.lname}
                  </List.Content>
                </List.Item>
              );
            })
          ) : (
            <List.Item>No Employees on Job</List.Item>
          )}
        </List>
      </div>
    );
  }
}
