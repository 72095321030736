export const REQUEST_DATA = "REQUEST_DATA";
export const SUBMIT_DATA = "SUBMIT_DATA";
export const DELETE_DATA = "DELETE_DATA";
export const MODIFY_DATA = "MODIFY_DATA";
export const IS_LOADING = "IS_LOADING";

export const requestData = payload => ({
  type: REQUEST_DATA,
  payload
});

export const receiveData = (actionType, payload, data) => ({
  type: actionType,
  payload,
  data
});

export const submitData = payload => ({
  type: SUBMIT_DATA,
  payload
});

export const deleteData = payload => ({
  type: DELETE_DATA,
  payload
});

export const modifyData = payload => ({
  type: MODIFY_DATA,
  payload
});

export const isLoading = payload => ({
  type: IS_LOADING,
  payload
});
