import React, { Fragment, useContext } from "react";
import { BurgerProvider, BurgerMenuContext } from "./MenuContext";
import AdminRoutes from "./AdminRoutes";
import { slide as Menu } from "react-burger-menu";
import styled from "styled-components";
import { NavLink as Link } from "react-router-dom";

const ComponentWindow = styled.div`
  padding: 10px;
`;

const BurgerMenu = ({ childProps }) => {
  const ctx = useContext(BurgerMenuContext);
  console.log(ctx);
  return (
    <Menu
      isOpen={ctx.isMenuOpen}
      onStateChange={state => ctx.stateChangeHandler(state)}
      disableAutoFocus
    >
      <Link activeClassName="active" onClick={ctx.toggleMenu} to="/admin">
        Home
      </Link>
      {childProps.isRegionAdmin && (
        <Link
          activeClassName="active"
          onClick={ctx.toggleMenu}
          className="menu-item"
          to="/admin/employees"
        >
          Employees
        </Link>
      )}
      {childProps.isSuperAdmin && (
        <Link
          activeClassName="active"
          onClick={ctx.toggleMenu}
          className="menu-item"
          to="/admin/codes"
        >
          Cost Codes
        </Link>
      )}
      {childProps.isRegionAdmin && (
        <Link
          activeClassName="active"
          onClick={ctx.toggleMenu}
          className="menu-item"
          to="/admin/subs"
        >
          Subcontractors
        </Link>
      )}
      <Link
        activeClassName="active"
        onClick={ctx.toggleMenu}
        className="menu-item"
        to="/admin/equip"
      >
        Equipment
      </Link>
      {childProps.isSuperAdmin && (
        <Link
          activeClassName="active"
          onClick={ctx.toggleMenu}
          className="menu-item"
          to="/admin/users"
        >
          Users
        </Link>
      )}
    </Menu>
  );
};

const AdminContainer = props => {
  const childProps = {
    isAuthenticated: props.isAuthenticated,
    userHasAuthenticated: props.userHasAuthenticated,
    jobList: props.jobList,
    isAdmin: props.isAdmin,
    isRegionAdmin: props.isRegionAdmin,
    isSuperAdmin: props.isSuperAdmin
  };
  return (
    <Fragment>
      <BurgerProvider>
        <BurgerMenu childProps={childProps} />
      </BurgerProvider>
      <ComponentWindow>
        <AdminRoutes childProps={childProps} />
      </ComponentWindow>
    </Fragment>
  );
};

export default AdminContainer;
