import React from "react";
import { Menu, Icon, Dimmer, Loader } from "semantic-ui-react";
import TimeSummaryContainer from "../timesummary/TimeSummaryContainer";
import TimeHeader from "../timeheader/TimeHeaderContainer";
import ReportNavPromp from "../timeheader/ReportNavPromp";
import { connect } from "react-redux";
import { ReportProvider } from "../timesummary/ReportContext";
import {
  SUBMIT_TIME_DATA,
  SUBMIT_REPORT_DATA,
  UPDATE_REPORT_TIMESTAMP,
  DELETE_REPORT_DATA
} from "./TimesheetActions";
import { isLoading } from "../api/actions";
import {
  makeGetTimeByEmployee,
  makeGetTotalsByEmployee,
  makeGetEmployeesByJob,
  makeGetCostCodesByJob,
  makeGetSubsByJob,
  makeGetJobInfo,
  makeGetReport
} from "./timesheetSelectors";
import { requestData, submitData, deleteData } from "../api/actions";
import moment from "moment";
import styled from "styled-components";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  empActionParams,
  ccActionParams,
  timeActionParams,
  totalsActionParams,
  reportActionParams,
  subActionParams
} from "./TimesheetApiParams";

class TableExampleStructured extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeItem: "clock outline"
    };
  }

  componentDidMount() {
    const cmpny = this.props.cmpny;
    const job = this.props.match.params.selectedJob;
    const selectedDay = this.props.match.params.selectedDay;
    this.props.requestData(empActionParams(cmpny, job));
    this.props.requestData(ccActionParams(cmpny, job));
    this.props.requestData(timeActionParams(cmpny, job));
    this.props.requestData(totalsActionParams(cmpny, selectedDay, job));
    this.props.requestData(reportActionParams(cmpny, job));
    this.props.requestData(subActionParams(cmpny, job));
    this.props.checkProps(this.props);
  }

  componentDidUpdate(prevProps) {
    const cmpny = this.props.cmpny;
    const job = this.props.match.params.selectedJob;
    const selectedDay = this.props.match.params.selectedDay;
    if (job !== prevProps.match.params.selectedJob) {
      this.props.requestData(empActionParams(cmpny, job));
      this.props.requestData(ccActionParams(cmpny, job));
      this.props.requestData(timeActionParams(cmpny, job));
      this.props.requestData(totalsActionParams(cmpny, selectedDay, job));
      this.props.requestData(reportActionParams(cmpny, job));
      this.props.requestData(subActionParams(cmpny, job));
      this.props.checkProps(this.props);
    }
    if (
      moment(selectedDay).format("ww") !==
      moment(prevProps.match.params.selectedDay).format("ww")
    ) {
      console.log("calling totals api");
      this.props.requestData(totalsActionParams(cmpny, selectedDay, job));
    }
  }

  handleTabChange = (e, { name }) => this.setState({ activeItem: name });

  render() {
    const Sticky = styled.div`
      position: sticky;
      position: -webkit-sticky;
      top: 2.25em;
      z-index: 5;
    `;

    return (
      <ReportProvider reduxState={this.props.report}>
        {this.props.isLoading && (
          <Dimmer active inverted>
            <Loader inverted>Loading</Loader>
          </Dimmer>
        )}
        <ReportNavPromp {...this.props} />
        <Sticky>
          <TimeHeader
            toggleLoader={this.toggleLoader}
            activeItem={this.state.activeItem}
            {...this.props}
          />
        </Sticky>
        <ToastContainer
          containerId={"Time"}
          enableMultiContainer
          position="bottom-center"
          autoClose={10000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
        <TimeSummaryContainer
          activeItem={this.state.activeItem}
          {...this.props}
        />
        <div style={{ padding: "12px" }}>
          <Menu color="grey" inverted pointing secondary fixed="bottom">
            <Menu.Item
              name="clock outline"
              active={this.state.activeItem === "clock outline"}
              onClick={this.handleTabChange}
            >
              <Icon name="clock outline" />
              Time
            </Menu.Item>

            <Menu.Item
              name="file alternate outline"
              active={this.state.activeItem === "file alternate outline"}
              onClick={this.handleTabChange}
            >
              <Icon name="file alternate outline" />
              Report
            </Menu.Item>
          </Menu>
        </div>
      </ReportProvider>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    //console.log(dispatch)
    onSubmitTime: (values, props) => {
      //const timeObj = Object.assign({}, values, props);
      console.log("URL I WANT!!: ", `time/${props.cmpny}`);
      dispatch(
        submitData({
          name: "time",
          url: `time/${props.cmpny}`,
          data: values.put,
          actionType: SUBMIT_TIME_DATA,
          job: props.match.params.selectedJob
        })
      );
    },
    onLoading: () => {
      dispatch(isLoading(true));
    },
    offLoading: () => {
      dispatch(isLoading(false));
    },
    onSaveReport: (values, props) => {
      //const timeObj = Object.assign({}, values, props);
      Object.values(values.delete).length > 0 &&
        Object.values(values.delete).forEach(item => {
          dispatch(
            deleteData({
              name: "time",
              url: `report/${props.cmpny}/${props.match.params.selectedJob}`,
              data: item,
              actionType: DELETE_REPORT_DATA,
              job: props.match.params.selectedJob
            })
          );
        });
      Object.values(values.put).length > 0 &&
        dispatch(
          submitData({
            name: "time",
            url: `report/${props.cmpny}/${props.match.params.selectedJob}`,
            data: Object.values(values.put),
            actionType: SUBMIT_REPORT_DATA,
            job: props.match.params.selectedJob
          })
        );
      values.timeUpdate.length > 0 &&
        dispatch(
          submitData({
            name: "time",
            url: `report/${props.cmpny}/${props.match.params.selectedJob}`,
            data: values.timeUpdate,
            actionType: UPDATE_REPORT_TIMESTAMP,
            job: props.match.params.selectedJob
          })
        );
    },
    requestData: (actionType, name, url) =>
      dispatch(requestData(actionType, name, url)),

    checkProps: props => {
      console.log("First of all, this rendered...");
      console.log("also, here are the props: ", props);
    }
  };
};

const makeMapStateToProps = () => {
  const getTimeState = makeGetTimeByEmployee();
  const getReportState = makeGetReport();
  const getTotals = makeGetTotalsByEmployee();
  const getEmployees = makeGetEmployeesByJob();
  const getCostCodes = makeGetCostCodesByJob();
  const getSubsOnJob = makeGetSubsByJob();
  const getJobInfo = makeGetJobInfo();
  const mapStateToProps = (state, props) => {
    return {
      time: getTimeState(state, props),
      report: getReportState(state, props),
      totals: getTotals(state, props),
      costCodes: getCostCodes(state, props),
      employeesByJob: getEmployees(state, props),
      employees: state.allEmployees,
      cmpny: state.group,
      isLoading: state.isLoading,
      subsOnJob: getSubsOnJob(state, props),
      jobInfo: getJobInfo(state, props)
    };
  };
  return mapStateToProps;
};

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(TableExampleStructured);
