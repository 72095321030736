import React, { useState, useCallback, useEffect, useMemo, memo } from "react";
import { useStoreState, useStoreActions } from "easy-peasy";
import {
  Dropdown,
  Header,
  Button,
  Segment,
  Dimmer,
  Form,
  Loader,
  Radio
} from "semantic-ui-react";
import { empFormEditSchema } from "./FormSchema";
import { SemanticField } from "../utils/SemanticField";
import { Formik, Field } from "formik";

const trades = [
  "Carpenter",
  "Mason",
  "Laborer",
  "Oper.Engineer",
  "Ironworker",
  "Mason Apprentice",
  "Carp Apprentice",
  "Taper-Painter",
  "Laborer Apprentice",
  "Ironwork Aprentice",
  "Office Engineer",
  "Estimating",
  "Timekeeper",
  "Rodperson"
];

const tradeList = trades.map(key => {
  return { key, value: key, text: key };
});

const ethList = [
  { value: "1", name: "African-American" },
  { value: "2", name: "Hispanic" },
  { value: "3", name: "Native American/Alaskan" },
  { value: "4", name: "Asian" },
  { value: "5", name: "Caucasion" },
  { value: "6", name: "Two or More Ethnicities" },
  { value: "7", name: "Pacific Islander/Other" }
];

const renderRadioField = ({
  label,
  error,
  checked,
  form: { setFieldValue, handleBlur, values },
  field: { name, value },
  ...otherProps
}) => {
  return (
    <Radio
      value={value}
      checked={values.ethnic === checked}
      label={label}
      onChange={(e, { value }) => {
        setFieldValue(name, value);
      }}
      onBlur={handleBlur}
      {...otherProps}
    />
  );
};

function AdminEditEmployee() {
  const cmpnyEmps = useStoreState(state => state.admin.employees);
  const fetchCmpnyEmployees = useStoreActions(
    actions => actions.admin.fetchEmp
  );
  const addCmpnyEmp = useStoreActions(actions => actions.admin.addCmpnyEmp);
  const delCmpnyEmp = useStoreActions(actions => actions.admin.delCmpnyEmp);
  const isEmpLoading = useStoreState(state => state.admin.isEmpLoading);
  const cmpny = useStoreState(state => state.group);
  const [currentEmp, setCurrentEmp] = useState("");
  const [empInitialState, setEmpInitialState] = useState({
    empl: "",
    fname: "",
    lname: "",
    minit: "",
    ethnic: "",
    sex: "",
    trade: ""
  });
  const cmpnyEmployeeList = useMemo(
    () =>
      Object.values(cmpnyEmps)
        .map(item => ({
          key: item.empl,
          text: `${item.fname} ${item.lname} - ${item.trade} (${item.empl})`,
          value: item.empl
        }))
        .sort((a, b) =>
          a.key.localeCompare(b.key, undefined, {
            numeric: true,
            sensitivity: "base"
          })
        ),
    [cmpnyEmps]
  );
  useEffect(() => {
    fetchCmpnyEmployees(cmpny);
  }, [cmpny, fetchCmpnyEmployees]);
  useEffect(() => {
    currentEmp &&
      setEmpInitialState({
        empl: currentEmp,
        fname: cmpnyEmps[currentEmp].fname,
        lname: cmpnyEmps[currentEmp].lname,
        ...(cmpnyEmps[currentEmp].minit && {
          minit: cmpnyEmps[currentEmp].minit
        }),
        ethnic: String(cmpnyEmps[currentEmp].ethnic),
        sex: cmpnyEmps[currentEmp].sex,
        trade: cmpnyEmps[currentEmp].trade
      });
  }, [currentEmp, cmpnyEmps]);

  const handleDropdownChange = useCallback(value => {
    setCurrentEmp(value);
  }, []);

  const handleClick = () => {
    delCmpnyEmp({ cmpny, empl: currentEmp });
    setCurrentEmp("");
  };
  console.log("CURRENT EMP:", currentEmp);
  console.log("CURRENT EMP STATE:", empInitialState);

  return (
    <Dimmer.Dimmable as={Segment} dimmed={isEmpLoading} blurring>
      <Dimmer active={isEmpLoading} inverted>
        <Loader>Loading</Loader>
      </Dimmer>
      <Header as="h3">Edit Employee</Header>
      <ExpensiveDropdown
        empOptions={cmpnyEmployeeList}
        currentValue={currentEmp}
        changeValue={handleDropdownChange}
      />
      {currentEmp && (
        <Formik
          enableReinitialize //Allows Expensive Dropdown to set the Initial State
          initialValues={empInitialState}
          validationSchema={empFormEditSchema(cmpnyEmps, empInitialState.empl)}
          onSubmit={async (data, { resetForm }) => {
            // same shape as initial values
            await addCmpnyEmp({ cmpny, data });
            if (currentEmp !== data.empl) {
              let tempEmp = currentEmp;
              setCurrentEmp("");
              await delCmpnyEmp({ cmpny, empl: tempEmp });
            }
            setCurrentEmp("");
            //resetForm();
          }}
        >
          {({
            values,
            isSubmitting,
            isValid,
            handleSubmit,
            setFieldValue,
            ...rest
          }) => {
            console.log("VALUES: ", isValid, rest);
            return (
              <Segment>
                <Form>
                  <SemanticField
                    component={Form.Input}
                    fluid
                    label="Employee Number"
                    placeholder="Employee Number"
                    name="empl"
                  />
                  <SemanticField
                    component={Form.Input}
                    fluid
                    label="First Name"
                    placeholder="Employee First Name"
                    name="fname"
                  />
                  <SemanticField
                    component={Form.Input}
                    fluid
                    label="Last Name"
                    placeholder="Employee Last Name"
                    name="lname"
                  />
                  <SemanticField
                    component={Form.Input}
                    fluid
                    label="M"
                    placeholder="Employee Middle Initial (optional)"
                    name="minit"
                  />
                  <Form.Field label="Ethnicity">Ethnicity</Form.Field>
                  {ethList.map(item => {
                    return (
                      <Form.Field key={item.value}>
                        <Field
                          component={renderRadioField}
                          label={item.name}
                          value={item.value}
                          checked={item.value}
                          name="ethnic"
                        />
                      </Form.Field>
                    );
                  })}
                  <Form.Field label="Gender">Gender</Form.Field>
                  <Form.Field>
                    <Button.Group>
                      <Field
                        component={Button}
                        name="sex"
                        value="M"
                        active={values.sex === "M"}
                        onClick={(e, { value }) => setFieldValue("sex", value)}
                      >
                        M
                      </Field>
                      <Field
                        component={Button}
                        name="sex"
                        value="F"
                        active={values.sex === "F"}
                        onClick={(e, { value }) => {
                          setFieldValue("sex", value);
                        }}
                      >
                        F
                      </Field>
                    </Button.Group>
                  </Form.Field>
                  <SemanticField
                    component={Form.Dropdown}
                    search
                    selection
                    label="Trade"
                    placeholder="Employee Trade"
                    name="trade"
                    options={tradeList}
                  />
                  <Form.Group>
                    <Form.Button
                      disabled={isSubmitting || !isValid}
                      type="submit"
                      onClick={handleSubmit}
                      color="green"
                    >
                      Update Emp
                    </Form.Button>
                    <Form.Button onClick={handleClick} negative>
                      Delete Emp
                    </Form.Button>
                  </Form.Group>
                </Form>
              </Segment>
            );
          }}
        </Formik>
      )}
    </Dimmer.Dimmable>
  );
}

const ExpensiveDropdown = memo(({ empOptions, currentValue, changeValue }) => {
  console.log("Getting HERRRREEE:", currentValue);
  const handleChange = value => {
    console.log("change handler fired: ", value);
    changeValue(value);
  };
  console.log("this is the first Option: ", empOptions);
  // The rest of your rendering logic
  return (
    <Dropdown
      placeholder="Employee"
      fluid
      search
      selection
      clearable
      options={Object.values(empOptions)}
      value={currentValue}
      onChange={(e, { value }) => handleChange(value)}
    />
  );
});

export default AdminEditEmployee;
