import React, { useState } from "react";
import { useReportState, useReportDispatch } from "./ReportContext";
import { List, Form, Button, Loader } from "semantic-ui-react";
import { API } from "aws-amplify";
import styled from "styled-components";

const RowGroup = styled.div`
  display: flex;
  flex-direction: row;
`;
const ListGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 90%;
`;

const ListGroupItem = styled.div`
  flex: 1 2 80px;
  padding: 5px;
`;

const CountGroupItem = styled.div`
  flex: 1 2;
  min-width: 90px;
  padding: 5px;
`;

const DescriptionGroupItem = styled.div`
  flex: 1 1 40%;
  padding: 5px;
`;

const ListButton = styled.div`
  width: 4em;
  align-self: center;
  justify-content: flex-end;
`;
export default function ReportSubsComponent(props) {
  const subsOnJob = props.subsOnJob;
  const subsByNo = subsOnJob.reduce((agg, item) => {
    return { ...agg, [item.sub_no]: item };
  }, {});
  console.log("sub props: ", props);
  const [isFetchLoading, setIsFetchLoading] = useState(false);
  const state = useReportState();
  const dispatch = useReportDispatch();
  const subList = Object.values(state.state.subs).sort(function(a, b) {
    if ((!a.timeAdd && !b.timeAdd) || a.timeAdd === b.timeAdd) {
      // Price is only important when cities are the same
      return b.sub_name < a.sub_name ? 1 : -1;
    } else if (!a.timeAdd) {
      return 1;
    } else if (!b.timeAdd) {
      return -1;
    } else {
      return a.timeAdd < b.timeAdd ? 1 : -1;
    }
  });
  console.log(subList);
  console.log("state shape: ", state.state);
  const currentSubState = state.state.subs;
  const date = props.match.params.selectedDay;
  const subs = subsOnJob.reduce(
    (filteredList, item) =>
      !Object.keys(state.state.subs).includes(item.sub_no)
        ? filteredList.concat({
            key: item.sub_no,
            value: item.sub_no,
            text: item.sub_name
          })
        : filteredList,
    []
  );

  const handleChange = (e, { value, id, component }) => {
    //if the company is changed from one to another, will need to delete and replace item from state.
    console.log("FIRST CHECK: ", value !== id);
    console.log("SECOND CHECK: ", component === "cmpny");
    if (value !== id && component === "cmpny") {
      dispatch.dispatch({
        type: "replaceField",
        fieldType: "subs",
        name: subsByNo[value]["sub_name"],
        value,
        id,
        idFieldName: "sub_no",
        nameFieldName: "sub_name"
      });
      //confusing, but value=current field id and id=previous state id, value is new id of added object
      //addDispatch and deleteDispatch are just adding objects to be added or deleted in database, so there is never really a need to delete an object from this state
    } else {
      dispatch.dispatch({
        type: "changeField",
        fieldType: "subs",
        value: { ...currentSubState[id], [component]: value },
        id
      });
    }
  };

  function handleAdd() {
    dispatch.dispatch({
      type: "addField",
      fieldType: "subs",
      id: "TEMP",
      value: {
        sub_name: "",
        descr: "",
        sub_no: "TEMP",
        count: "",
        timeAdd: new Date(),
        date
      }
    });
  }

  function handleRemove(id) {
    console.log("button clicked:", id);
    dispatch.dispatch({ type: "deleteField", id, fieldType: "subs" });
  }

  async function handleFetchLastSub() {
    setIsFetchLoading(true);
    try {
      const subData = await API.get(
        "time",
        `report/${props.cmpny}/${props.match.params.selectedJob}/sub`
      );
      console.log("SUB DATA LOADED: ", subData);
      subData.forEach(x => {
        dispatch.dispatch({
          type: "addField",
          fieldType: "subs",
          id: x.sub_no,
          value: {
            sub_name: x.sub_name,
            descr: x.descr,
            sub_no: x.sub_no,
            count: x.count,
            timeAdd: new Date(),
            date: props.match.params.selectedDay
          }
        });
      });
      setIsFetchLoading(false);
    } catch (e) {
      setIsFetchLoading(false);
      console.log("Unable to load previous", e);
    }
    return;
  }

  return (
    <div>
      <div>
        <Button
          style={{ marginBottom: "5px" }}
          positive
          onClick={() => handleAdd()}
        >
          Add Subcontractor
        </Button>
        <Button
          style={{ marginBottom: "5px" }}
          onClick={() => handleFetchLastSub()}
          disabled={isFetchLoading}
        >
          <Loader active={isFetchLoading} inline />
          Load Previous Entry
        </Button>
      </div>
      <Form>
        <List celled>
          {Object.values(subList).map(field => {
            return (
              <List.Item key={`${field.sub_no}`}>
                <RowGroup>
                  <ListGroup>
                    <ListGroupItem>
                      <Form.Dropdown
                        label="Subcontractor"
                        placeholder="Add Sub"
                        search
                        selection
                        options={
                          subs.concat({
                            key: field.sub_no,
                            value: field.sub_no,
                            text: field.sub_name
                          }) || []
                        }
                        value={field.sub_no}
                        id={field.sub_no}
                        name={field.sub_name}
                        component="cmpny"
                        onChange={handleChange}
                      />
                    </ListGroupItem>
                    <CountGroupItem>
                      <Form.Input
                        type="number"
                        label="Headcount"
                        placeholder="Enter Head Count"
                        id={field.sub_no}
                        component="count"
                        value={field.count}
                        onChange={handleChange}
                      />
                    </CountGroupItem>
                    <DescriptionGroupItem>
                      <Form.TextArea
                        type="text"
                        label="Description"
                        placeholder="Enter Description"
                        id={field.sub_no}
                        component="descr"
                        value={field.descr}
                        onChange={handleChange}
                      />
                    </DescriptionGroupItem>
                  </ListGroup>
                  <ListButton>
                    <Button
                      size="mini"
                      onClick={() => handleRemove(field.sub_no)}
                    >
                      X
                    </Button>
                  </ListButton>
                </RowGroup>
              </List.Item>
            );
          })}
        </List>
      </Form>
    </div>
  );
}
