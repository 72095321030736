import React from "react";
import { Form, Grid, Segment } from "semantic-ui-react";
import LoaderButton from "../components/LoaderButton";
import { Formik } from "formik";
import * as Yup from "yup";
import { SemanticField } from "../utils/SemanticField";

const LoginComponent = ({ submitFuction, setIsSubmitting, setView }) => (
  <Formik
    initialValues={{
      email: "",
      password: ""
    }}
    validationSchema={Yup.object().shape({
      email: Yup.string().required("Required"),
      password: Yup.string().required("Required")
    })}
    onSubmit={(values, { setSubmitting, resetForm }) => {
      setIsSubmitting(true);
      submitFuction(values);
      resetForm();
    }}
  >
    {({ isSubmitting, isValid, handleSubmit }) => {
      return (
        <Grid.Column style={{ maxWidth: 450 }}>
          <Form>
            <Segment stacked>
              <SemanticField
                component={Form.Input}
                name="email"
                label="Email"
                placeholder="Email Address"
                fluid
              />
              <SemanticField
                component={Form.Input}
                name="password"
                label="Password"
                placeholder="Password"
                type="password"
                fluid
                icon="lock"
                iconPosition="left"
              />
              <Form.Field>
                <a
                  style={{ cursor: "pointer" }}
                  onClick={e => setView("resetPassword")}
                >
                  Forgot password?
                </a>
              </Form.Field>
              <LoaderButton
                size="large"
                text="Login"
                disabled={!isValid}
                loadingText="Logging in…"
                type="submit"
                onClick={handleSubmit}
              />
            </Segment>
          </Form>
        </Grid.Column>
      );
    }}
  </Formik>
);

export default LoginComponent;
