import { action, thunk } from "easy-peasy"; // 👈 import
import { API } from "aws-amplify";
import { postData } from "../utils/postArrayData";
import { deleteApi } from "../sagas";
import { toast } from "react-toastify";
import { ADD_SUB_DATA, DELETE_SUB_DATA } from "../timesheet/TimesheetActions";

export const subModel = {
  cmpnySubs: {},
  isSubLoading: false,
  updateIsLoading: action((state, payload) => {
    console.log("LOADING PAYLOAD: ", payload);
    state[`is${payload.name}Loading`] = payload.toggle;
  }),
  fetchCmpnySubs: thunk(async (actions, payload) => {
    const data = await API.get("time", `time/${payload}/subs`);
    const dataObj = data.reduce((agg, item) => {
      return { ...agg, [item.sub_no]: item };
    }, {});
    actions.updateSubs(dataObj);
  }),
  addCmpnySub: thunk(async (actions, payload) => {
    console.log("ACTION URL ISSUE: ", payload);
    actions.updateIsLoading({ toggle: true, name: "Sub" });
    try {
      const data = await postData("time", `admin/${payload.cmpny}/subs`, [
        payload.data
      ]);
      if (data.fail.length > 0) {
        data.fail.map(x => {
          actions.updateIsLoading({ toggle: false, name: "Sub" });
          let err = `Unable to Add ${x.sub_name}`;
          toast.error(err, { containerId: "general" });
          throw new Error(err);
        });
      }
      actions.updateIsLoading({ toggle: false, name: "Sub" });
      console.log("added Sub data: ", data);
      const dataObj = data.success.reduce((agg, item) => {
        return { ...agg, [item.sub_no]: item };
      }, {});
      actions.updateSubs(dataObj);
      toast.success("Successfully Updated", { containerId: "general" });
    } catch (error) {
      actions.updateIsLoading({ toggle: false, name: "Sub" });
      toast.error(error, { containerId: "general" });
      throw error;
    }
  }),
  delCmpnySub: thunk(async (actions, payload) => {
    actions.updateIsLoading({ toggle: true, name: "Sub" });
    try {
      const data = await deleteApi(
        "time",
        `admin/${payload.cmpny}/subs/${payload.sub_no}`
      );
      if (typeof data === "undefined") {
        let err = "Unable to Delete Subloyee";
        actions.updateIsLoading({ toggle: false, name: "Sub" });
        toast.error(err, { containerId: "general" });
        throw new Error(err);
      }
      actions.updateIsLoading({ toggle: false, name: "Sub" });
      console.log("Returned DELETE DATA: ", data);
      actions.delSub(payload.sub_no);
      toast.success("Successfully Updated", { containerId: "general" });
    } catch (error) {
      actions.updateIsLoading({ toggle: false, name: "Sub" });
      toast.error(error, { containerId: "general" });
      throw error;
    }
  }),
  addJobSubs: thunk(async (actions, payload, { dispatch }) => {
    actions.updateIsLoading({ toggle: true, name: "Sub" });
    try {
      const data = await postData(
        "time",
        `time/${payload.cmpny}/${payload.job}/subs`,
        payload.data
      );
      actions.updateIsLoading({ toggle: false, name: "Sub" });
      console.log("added Sub data: ", data);
      dispatch({
        type: ADD_SUB_DATA,
        payload: { data: data.success, job: payload.job }
      });
      console.log("WHAAAATTTTT");
      toast.success("Successfully Updated", { containerId: "general" });
    } catch (error) {
      actions.updateIsLoading({ toggle: false, name: "Sub" });
      toast.error("Unable to Process", { containerId: "general" });
      throw error;
    }
  }),
  delJobSubs: thunk(async (actions, payload, { dispatch }) => {
    actions.updateIsLoading({ toggle: true, name: "Sub" });
    try {
      const data = await deleteApi(
        "time",
        `time/${payload.cmpny}/${payload.job}/subs/${payload.sub_no}`
      );
      actions.updateIsLoading({ toggle: false, name: "Sub" });
      console.log("added Sub data: ", payload.sub_no, data);
      dispatch({
        type: DELETE_SUB_DATA,
        payload: { sub_no: payload.sub_no, job: payload.job }
      });
      console.log("WHAAAATTTTT");
      toast.success("Successfully Updated", { containerId: "general" });
    } catch (error) {
      actions.updateIsLoading({ toggle: false, name: "Sub" });
      toast.error("Unable to Process", { containerId: "general" });
      throw error;
    }
  }),
  /*SUB ACTIONS */
  updateSubs: action((state, payload) => {
    console.log("PAYLOAD: ", payload);
    state.cmpnySubs = { ...state.cmpnySubs, ...payload };
  }),
  delSub: action((state, payload) => {
    console.log("PAYLOAD: ", payload);
    let { [payload]: _, ...temp } = state.cmpnySubs;
    state.cmpnySubs = temp;
  })
};
