import React, { useState } from "react";
import { Breadcrumb, Button, Icon } from "semantic-ui-react";
import styled from "styled-components";
import ReportGeneralComponent from "./ReportGeneralComponent";
import ReportSubsComponent from "./ReportSubsComponent";

const initialBreadcrumbs = {
  items: ["General", "Subcontractors", "Equipment"],
  activeItem: "General"
};

const Seg = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
`;
const SegChild = styled.div`
  margin: 10px;
`;

export default function ReportSummaryComponent(props) {
  // Declare a new state variable, which we'll call "count"
  const [breadcrumbs, setActiveBreadcrumb] = useState(initialBreadcrumbs);
  console.log(breadcrumbs);
  console.log(props);

  return (
    <Seg>
      <SegChild>
        <Breadcrumb size="large">
          {breadcrumbs.items.map((crumb, index) => {
            console.log(`${crumb}: `, crumb !== breadcrumbs.activeItem);
            return index > 0 ? (
              <React.Fragment key={`${crumb}-frag`}>
                <Breadcrumb.Divider
                  icon="right angle"
                  key={`${crumb}-divider`}
                />
                <Breadcrumb.Section
                  key={crumb}
                  link={crumb !== breadcrumbs.activeItem}
                  active={crumb === breadcrumbs.activeItem}
                  onClick={e => {
                    //e.preventDefault();
                    crumb !== breadcrumbs.activeItem &&
                      setActiveBreadcrumb({
                        ...breadcrumbs,
                        activeItem: crumb
                      });
                  }}
                >
                  {crumb}
                </Breadcrumb.Section>
              </React.Fragment>
            ) : (
              <Breadcrumb.Section
                key={crumb}
                link={crumb !== breadcrumbs.activeItem}
                active={crumb === breadcrumbs.activeItem}
                onClick={e => {
                  //e.preventDefault();
                  crumb !== breadcrumbs.activeItem &&
                    setActiveBreadcrumb({ ...breadcrumbs, activeItem: crumb });
                }}
              >
                {crumb}
              </Breadcrumb.Section>
            );
          })}
        </Breadcrumb>
      </SegChild>
      <SegChild>
        {breadcrumbs.activeItem === "General" && (
          <ReportGeneralComponent {...props} />
        )}
        {breadcrumbs.activeItem === "Subcontractors" && (
          <ReportSubsComponent {...props} />
        )}
      </SegChild>
      <SegChild>
        {breadcrumbs.items.indexOf(breadcrumbs.activeItem) !== 0 && (
          <Button
            onClick={e => {
              //e.preventDefault();
              const current = breadcrumbs.items.indexOf(breadcrumbs.activeItem);
              setActiveBreadcrumb({
                ...breadcrumbs,
                activeItem: breadcrumbs.items[current - 1]
              });
            }}
            icon
            labelPosition="left"
          >
            <Icon name="left arrow" />
            Previous
          </Button>
        )}
        {breadcrumbs.items.indexOf(breadcrumbs.activeItem) !==
          breadcrumbs.items.length - 1 && (
          <Button
            onClick={e => {
              //e.preventDefault();
              const current = breadcrumbs.items.indexOf(breadcrumbs.activeItem);
              console.log(current);
              setActiveBreadcrumb({
                ...breadcrumbs,
                activeItem: breadcrumbs.items[current + 1]
              });
            }}
            icon
            labelPosition="right"
          >
            Next
            <Icon name="right arrow" />
          </Button>
        )}
      </SegChild>
    </Seg>
  );
}
