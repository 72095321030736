import React, { useState } from "react";
import {
  Button,
  Form as AltForm,
  Input,
  Radio,
  Select,
  Dropdown,
  Label,
  TextArea,
  Checkbox
} from "semantic-ui-react";
import { Formik, Field } from "formik";
import styled from "styled-components";
import { shapeFormData } from "../utils/shapeFormData";
import * as Yup from "yup";
import moment from "moment";
import TextareaAutosize from "react-textarea-autosize";

const Grid = styled(AltForm.Field)`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-column-gap: 30px;
  justify-content: center;
`;

const EmployeeGrid = styled.div`
  display: grid;
  grid-template-columns: minmax(200px, 1fr);
`;

const ItemGrid = styled.div`
  display: grid;
  grid-template-columns: minmax(200px, 1fr);
`;

const RegButton = styled(Button)`
  width: 150px;
  height: 35px;
`;

const renderRadioField = ({
  field,
  form,
  setFieldValue,
  label,
  radioValue,
  ...props
}) => {
  return (
    <AltForm.Field>
      <Radio
        value={radioValue}
        checked={field.value === radioValue}
        onChange={(e, { value }) => {
          setFieldValue(field.name, value);
        }}
        label={label}
      />
    </AltForm.Field>
  );
};

const renderCheckBox = ({ field, form, label, setFieldValue, ...props }) => {
  return (
    <AltForm.Field
      label={label}
      {...props}
      value={field.type === "number" ? Number(field.value) : field.value}
      onChange={(param, { value }) => {
        setFieldValue(field.name, value);
      }}
    />
  );
};

const renderField = ({ field, form, label, setFieldValue, ...props }) => {
  return (
    <AltForm.Field
      label={label}
      {...props}
      value={field.type === "number" ? Number(field.value) : field.value}
      onChange={(param, { value }) => {
        setFieldValue(field.name, value);
      }}
    />
  );
};

const renderTextField = ({ field, form, label, setFieldValue, ...props }) => {
  return (
    <TextareaAutosize
      label={label}
      {...props}
      value={field.value}
      onChange={(e, value) => {
        setFieldValue(field.name, e.target.value);
      }}
    />
  );
};

const renderLabel = label =>
  window.matchMedia("(min-width: 480px)").matches
    ? {
        content: `${label.text.split(" ")[0].charAt(0)}. ${
          label.text.split(" ")[1]
        } - ${label.text.split(" ")[3].substr(0, 3)} ${
          label.text.split(" ")[4] ? label.text.split(" ")[4].substr(0, 3) : ""
        }`
      }
    : {
        content: `${label.text.split(" ")[0].charAt(0)}. ${
          label.text.split(" ")[1]
        }`
      };

const FormSchema = (props, specialToggle) => {
  console.log(props);
  return Yup.object().shape({
    employee: Yup.array().required("Required"),
    cc: Yup.string().required("Required"),
    hours: Yup.number().when("type", {
      is: type => type == "r", //just an e.g. you can return a function
      then: Yup.number()
        .min(0)
        .lessThan(12)
        .test(
          "day-hours-test",
          `Cannot have more than ${props.jobInfo.maxRegHours} regular hours in one day`,
          value => {
            console.log(value);
            console.log(value <= props.jobInfo.maxRegHours);
            return value <= props.jobInfo.maxRegHours;
          }
        )
        .required("Required"),
      otherwise: Yup.number()
        .min(0)
        .lessThan(12)
        .required("Required")
    }),
    ...(specialToggle && {
      spcl: Yup.string()
        .max(25, "25 Characters Max")
        .required("Required")
    }),
    desc: Yup.string(),
    type: Yup.string()
      .test(
        "sat-reg-test",
        "Regular time cannot be entered on weekends.",
        value => {
          return (
            value !== "r" ||
            ![0, 6].includes(moment(props.match.params.selectedDay).day())
          );
        }
      )
      .test("sun-2x-test", "2x Time must be entered on Sundays.", value => {
        return (
          (value !== "r" && value !== "ot") ||
          ![0].includes(moment(props.match.params.selectedDay).day())
        );
      })
      .required("Required")
    /*     desc: Yup.string()
      .max(100, "Too Long!")
      .required("Required") */
  });
};

const TimeEntry = props => {
  const [specialToggle, setSpecialToggle] = useState(false);
  const formSchema = FormSchema(props, specialToggle);
  const handleClick = () => {
    props.history.push(`/${props.match.params.selectedJob}/employees`);
  };
  const { employeesByJob, costCodes, onSubmitTime, handleClose } = props;
  const employeeList = employeesByJob
    ? employeesByJob.reduce(
        (agg, item) =>
          agg.concat({
            key: item.emp,
            value: item.emp,
            text: `${item.fname} ${item.lname} - ${item.trade}`
          }),
        []
      )
    : [];
  const costCodeList = costCodes
    ? costCodes.reduce(
        (agg, item) =>
          agg.concat({
            key: item.cc,
            value: item.cc,
            text: `${item.cc} - ${item.descr}`
          }),
        []
      )
    : [];

  return (
    <Formik
      initialValues={{
        employee: [],
        cc: "",
        hours: "",
        type: "r",
        spcl: "",
        desc: ""
      }}
      validationSchema={formSchema}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        const newValues = shapeFormData(
          values,
          props.employees,
          props.jobInfo,
          props.match.params.selectedJob,
          props.match.params.selectedDay
        );
        await onSubmitTime(newValues, props);
        //setSubmitting(false);
        handleClose();
      }}
      render={({
        errors,
        touched,
        setFieldValue,
        handleSubmit,
        isSubmitting
      }) => {
        return (
          <AltForm onSubmit={handleSubmit}>
            <Grid>
              <EmployeeGrid>
                <Field
                  name="employee"
                  component={renderField}
                  control={Dropdown}
                  label="Employee"
                  placeholder="Select Employees"
                  fluid
                  multiple
                  search
                  selection
                  renderLabel={renderLabel}
                  options={employeeList || []}
                  setFieldValue={setFieldValue}
                />
                {errors.employee && touched.employee && (
                  <Label basic color="red" pointing>
                    {errors.employee}
                  </Label>
                )}
                <RegButton onClick={handleClick} primary type="button">
                  Edit Employees
                </RegButton>
              </EmployeeGrid>
              <ItemGrid>
                <Field
                  name="cc"
                  component={renderField}
                  control={Select}
                  label="Cost Code"
                  placeholder="Select Cost Code"
                  fluid
                  selection
                  search
                  options={costCodeList || []}
                  setFieldValue={setFieldValue}
                />
                {errors.cc && touched.cc && (
                  <Label basic color="red" pointing>
                    {errors.cc}
                  </Label>
                )}
              </ItemGrid>
              <ItemGrid>
                <Field
                  name="hours"
                  component={renderField}
                  control={Input}
                  label="Hours"
                  type="number"
                  placeholder="Hours"
                  min="0.0"
                  step="0.5"
                  max="12.0"
                  setFieldValue={setFieldValue}
                />
                {errors.hours && touched.hours && (
                  <Label basic color="red" pointing>
                    {errors.hours}
                  </Label>
                )}
              </ItemGrid>
            </Grid>
            <AltForm.Group>
              <Field
                name="type"
                component={renderRadioField}
                value="r"
                radioValue="r"
                label="Reg"
                setFieldValue={setFieldValue}
              />
              <Field
                name="type"
                component={renderRadioField}
                value="ot"
                radioValue="ot"
                label="OT"
                setFieldValue={setFieldValue}
              />
              <Field
                name="type"
                component={renderRadioField}
                value="2nd"
                radioValue="2nd"
                label="2nd Shift"
                setFieldValue={setFieldValue}
              />
              <Field
                name="type"
                component={renderRadioField}
                //component="input"
                //control={Radio}
                //type="radio"
                value="2x"
                radioValue="2x"
                label="2x OT"
                setFieldValue={setFieldValue}
              />
            </AltForm.Group>
            {errors.type && touched.type && (
              <Label basic color="red" pointing>
                {errors.type}
              </Label>
            )}
            <AltForm.Field>
              <Checkbox
                label="Special Rate?"
                checked={specialToggle}
                onChange={() => {
                  setSpecialToggle(!specialToggle);
                }}
              />
            </AltForm.Field>
            <AltForm.Field>
              {specialToggle && (
                <Field
                  name="spcl"
                  component={renderField}
                  control={TextArea}
                  rows={1}
                  placeholder="Describe Special Rate..."
                  setFieldValue={setFieldValue}
                />
              )}
              {errors.spcl && touched.spcl && specialToggle && (
                <Label basic color="red" pointing>
                  {errors.spcl}
                </Label>
              )}
            </AltForm.Field>
            <Field
              name="desc"
              component={renderTextField}
              label="Notes"
              placeholder="Enter notes here..."
              setFieldValue={setFieldValue}
            />
            {errors.desc && touched.desc && (
              <Label basic color="red" pointing>
                {errors.desc}
              </Label>
            )}
            <AltForm.Button
              disabled={isSubmitting}
              style={{ marginTop: "5px" }}
              type="submit"
            >
              Submit
            </AltForm.Button>
          </AltForm>
        );
      }}
    />
  );
};

export default TimeEntry;
