import "./Login.css";
import { connect } from "react-redux";
import { setUserName } from "./LoginActions";
import Login from "./Login";

const mapStateToProps = state => {
  return {
    state
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setUserName: userName => dispatch(setUserName(userName))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
