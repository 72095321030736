import { RECEIVE_JOB_DATA, RECEIVE_ADDED_JOB } from "./LandingActions";
import { normalizedJobData } from "../schemas.js";

export const jobs = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_JOB_DATA:
      return normalizedJobData(action.data.jobData);
    case RECEIVE_ADDED_JOB:
      return {
        entities: {
          jobs: { ...state.entities.jobs, [action.job]: action.data }
        },
        result: [...new Set([...state.result, action.job])]
      };
    default:
      return state;
  }
};

export const group = (state = "", action) => {
  switch (action.type) {
    case RECEIVE_JOB_DATA:
      if (action.data === undefined || action.data === 0) {
        return state;
      }
      return action.data.cmpny;
    default:
      return state;
  }
};

export const role = (state = "", action) => {
  switch (action.type) {
    case RECEIVE_JOB_DATA:
      if (action.data === undefined || action.data === 0) {
        return state;
      }
      return action.data.role;
    default:
      return state;
  }
};
