import React from "react";
import { useReportState, useReportDispatch } from "./ReportContext";
import { Form } from "semantic-ui-react";

const ReportGeneralComponent = props => {
  const state = useReportState();
  const dispatch = useReportDispatch();
  console.log("THIS RENDERED HERE.");
  console.log(`PROPS:`, props);
  const date = props.match.params.selectedDay;
  const handleInputChange = (e, { id, value }) => {
    dispatch.dispatch({
      type: "changeField",
      fieldType: "report",
      id,
      value,
      date
    });
  };

  return (
    <Form key="report">
      <Form.TextArea
        key="general"
        id="general"
        label="General Items"
        placeholder="Description of work..."
        value={state.state.report.general || ""}
        onChange={handleInputChange}
      />
      <Form.TextArea
        key="issues"
        id="issues"
        label="Issues"
        placeholder="Description of issues encountered..."
        value={state.state.report.issues || ""}
        onChange={handleInputChange}
      />
      <Form.TextArea
        key="safety"
        id="safety"
        label="Safety"
        placeholder="Description of safety items..."
        value={state.state.report.safety || ""}
        onChange={handleInputChange}
      />
      <Form.TextArea
        key="extra"
        id="extra"
        label="Extra Work"
        placeholder="Description of extra work..."
        value={state.state.report.extra || ""}
        onChange={handleInputChange}
      />
      <Form.TextArea
        key="deliveries"
        id="deliveries"
        label="Deliveries"
        placeholder="Deliveries received today..."
        value={state.state.report.deliveries || ""}
        onChange={handleInputChange}
      />
    </Form>
  );
};

export default ReportGeneralComponent;
