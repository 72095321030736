import { call, put, all, takeEvery } from "redux-saga/effects";
import {
  REQUEST_DATA,
  SUBMIT_DATA,
  DELETE_DATA,
  MODIFY_DATA,
  receiveData,
  isLoading
} from "./api/actions";
import { FAILED_SUBMIT_TIME } from "./timesheet/TimesheetActions";
import { API } from "aws-amplify";

export const fetchApi = async (name, url) => {
  try {
    console.log(API.get(name, url));
    console.log("GET API CALLED!!!!");
    const data = await API.get(name, url);
    return { data: data };
  } catch (e) {
    console.log(e);
    return;
  }
};

export const deleteApi = async (name, url, body) => {
  try {
    console.log("DELETE PARAMS: ", name, url);
    const data = await API.del(name, url, { body });
    return { data };
  } catch (e) {
    console.log(e);
    return;
  }
};

const submitApi = async (name, url, body) => {
  let dataObj = {};
  let successArray = [];
  let failArray = [];
  try {
    console.log(name, url, body);
    const promises = body.map(item => {
      return API.post(name, url, { body: item })
        .then(() => {
          dataObj["success"] = successArray.push(item);
          console.log("one success: ", item);
          console.log(successArray);
          console.log(dataObj);
          return dataObj;
        })
        .catch(e => {
          console.log(e, item);
          dataObj["errors"] = failArray.push(item);
          return dataObj;
        });
    });

    const data = await Promise.all(promises);
    console.log(data);
    console.log(successArray);
    console.log(failArray);
    console.log(dataObj);
    console.log("confirm we got here?");
    return { success: successArray, fail: failArray };
  } catch (e) {
    console.log("Something went very wrong");
    console.log(e);
    return;
  }
};

function* getData(action) {
  console.log(action);
  const { data, e } = yield call(
    fetchApi,
    action.payload.name,
    action.payload.url
  );
  console.log(action.payload.actionType, action.payload, data);
  if (data) {
    yield put(receiveData(action.payload.actionType, action.payload, data));
  }
  //yield put({ type: 'PRODUCTS_RECEIVED', products: response })
  else {
    console.log(e);
  }
}

function* postData(action) {
  console.log(action);
  yield put(isLoading(true));
  const { success, fail } = yield call(
    submitApi,
    action.payload.name,
    action.payload.url,
    action.payload.data
  );
  console.log("success data: ", success, "fail data: ", fail);
  console.log(success.length, fail.length);
  switch (true) {
    case success.length > 0 && fail.length > 0:
      yield all([
        put({
          type: action.payload.actionType,
          data: success,
          payload: { job: action.payload.job }
        }),
        put({
          type: FAILED_SUBMIT_TIME,
          data: fail,
          payload: { job: action.payload.job }
        })
      ]);
      yield put(isLoading(false));
      break;
    case success.length > 0:
      yield put({
        type: action.payload.actionType,
        data: success,
        payload: { job: action.payload.job }
      });
      yield put(isLoading(false));
      break;
    case fail.length > 0:
      yield put({
        type: FAILED_SUBMIT_TIME,
        data: fail,
        payload: { job: action.payload.job }
      });
      yield put(isLoading(false));
      break;
    default:
      yield put(isLoading(false));
      yield put({ type: "SUBMISSION_ERROR", data: "something went wrong" });
      break;
  }
}

function* deleteData(action) {
  console.log(action);
  yield put(isLoading(true));
  const res = yield call(
    deleteApi,
    action.payload.name,
    action.payload.url,
    action.payload.data
  );

  console.log(res.data);
  if (res.data) {
    yield put({
      type: action.payload.actionType,
      data: res.data,
      payload: { job: action.payload.job }
    });
    yield put(isLoading(false));
  }
  //yield put({ type: 'PRODUCTS_RECEIVED', products: response })
  else {
    yield put(isLoading(false));
    console.log(res.e);
  }
}

function* modifyData(action) {
  console.log(action);
  console.log("starting deletion...");
  yield* deleteData(action.payload.delete);
  console.log("now starting submit...");
  yield* postData(action.payload.submit);
  console.log("done");
}

// Our watcher Saga: spawn a new incrementAsync task on each INCREMENT_ASYNC
export function* watchJobRequest() {
  //gettime, getemployees, getccs, gettotals initial
  //subsequent gettime, gettoals
  yield takeEvery(REQUEST_DATA, getData);
}

export function* watchDataSubmit() {
  yield takeEvery(SUBMIT_DATA, postData);
}

export function* watchDataDelete() {
  yield takeEvery(DELETE_DATA, deleteData);
}

export function* watchDataModify() {
  yield takeEvery(MODIFY_DATA, modifyData);
}

export default function* rootSaga() {
  yield all([
    watchJobRequest(),
    watchDataSubmit(),
    watchDataDelete(),
    watchDataModify()
  ]);
}
