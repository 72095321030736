import React from "react";
import { useReportState } from "../timesummary/ReportContext";
import { Prompt } from "react-router-dom";

const ReportNavPromp = props => {
  const state = useReportState();
  return (
    <Prompt
      when={!state.state.isSaveDisabled}
      message={location => `Changes will be lost without saving. Proceed?`}
    />
  );
};

export default ReportNavPromp;
