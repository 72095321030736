import React from "react";
import { Button } from "semantic-ui-react";
import "./modalForm.css";
import Modal from "react-modal";
import { connect } from "react-redux";
import {
  requestData,
  submitData,
  deleteData,
  modifyData
} from "../api/actions";
import {
  SUBMIT_TIME_DATA,
  DELETE_TIME_DATA
} from "../timesheet/TimesheetActions";

const styles = {
  fontFamily: "sans-serif",
  textAlign: "center"
};

const modalStyle = {
  overlay: { zIndex: 10 }
};

Modal.setAppElement(document.getElementById("root"));

class ModalForm extends React.Component {
  _isMounted = false;
  constructor() {
    super();

    this.state = {
      open: false
    };

    this.onOpenModal = this.onOpenModal.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onOpenModal() {
    this._isMounted && this.setState({ open: true });
  }

  onCloseModal() {
    this._isMounted && this.setState({ open: false });
  }

  render() {
    const { open } = this.state;
    return (
      <div style={styles}>
        <Button
          disabled={this.props.disabled}
          primary
          onClick={this.onOpenModal}
        >
          {this.props.buttonName}
        </Button>
        <Modal
          style={modalStyle}
          isOpen={open}
          onRequestClose={this.onCloseModal}
          center
        >
          {this.props.render({ handleClose: this.onCloseModal, ...this.props })}
        </Modal>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    //console.log(dispatch)
    onSubmitTime: (values, props) => {
      //const timeObj = Object.assign({}, values, props);
      dispatch(
        submitData({
          name: "time",
          url: `time/${props.cmpny}`,
          data: values,
          actionType: SUBMIT_TIME_DATA,
          job: props.selectedJob
        })
      );
    },
    onModifyTime: (oldValues, newValues, props) => {
      //const timeObj = Object.assign({}, values, props);
      dispatch(
        modifyData({
          delete: {
            payload: {
              name: "time",
              url: `time/${props.cmpny}/${props.selectedJob}/delete/${props.selectedDay}/${oldValues.employee}/${oldValues.cc}/${oldValues.type}`,
              actionType: DELETE_TIME_DATA,
              job: props.selectedJob
            }
          },
          submit: {
            payload: {
              name: "time",
              url: `time/${props.cmpny}`,
              data: newValues,
              actionType: SUBMIT_TIME_DATA,
              job: props.selectedJob
            }
          }
        })
      );
    },
    onDeleteTime: (values, props) => {
      console.log(values[0]);
      dispatch(
        //note data is in list so API call can map over item.
        deleteData({
          name: "time",
          url: `time/${props.cmpny}/${props.selectedJob}/delete/${props.selectedDay}/${values.employee}/${values.cc}/${values.type}`,
          actionType: DELETE_TIME_DATA,
          job: props.selectedJob
        })
      );
    },
    requestData: (actionType, name, url) =>
      dispatch(requestData(actionType, name, url))
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    employees: state.allEmployees,
    cmpny: state.group,
    ...ownProps
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalForm);
