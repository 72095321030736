import { normalize, schema } from "normalizr";

// Define a users schema
const jobSchema = new schema.Entity("jobs", undefined, {
  // idAttribute *functions* must return the ids **value** (not key)
  idAttribute: value => value.job
});

export const normalizedJobData = data => normalize(data, [jobSchema]);

const timeSchema = new schema.Entity("time", undefined, {
  // idAttribute *functions* must return the ids **value** (not key)
  idAttribute: value => value.pk + value.sk
});

export const normalizedTimeData = data => normalize(data, [timeSchema]);

const reportSchema = new schema.Entity("report", undefined, {
  // idAttribute *functions* must return the ids **value** (not key)
  idAttribute: value => value.pk + value.sk
});

export const normalizedReportData = data => normalize(data, [reportSchema]);

const totalsSchema = new schema.Entity("time", undefined, {
  // idAttribute *functions* must return the ids **value** (not key)
  idAttribute: value => `${value.pk.split("#")[2]}#${value.sk}`
});

export const normalizedTotalsData = data => normalize(data, [totalsSchema]);
