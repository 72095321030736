import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import Root from "./components/Root";
import { createStore, action } from "easy-peasy";
import { model } from "./reducers/reducers";
import "semantic-ui-css/semantic.min.css";
import registerServiceWorker from "./registerServiceWorker";
import logger from "./middleware/processTime";
import Amplify from "aws-amplify";
import { Auth } from "aws-amplify";
import config from "./config";
import createSagaMiddleware from "redux-saga";
import rootSaga from "./sagas";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export const persistConfig = {
  key: "easypeasystate",
  storage,
  blacklist: ["selectedDay"]
};

const sagaMiddleware = createSagaMiddleware();

let initialState = {};
export const store = createStore(
  {
    ...model,
    reset: action((state, payload) => {
      console.log(initialState);
      return { ...initialState };
    })
  },
  {
    //disableImmer: true,
    reducerEnhancer: reducer => persistReducer(persistConfig, reducer),
    middleware: [sagaMiddleware, logger]
  }
);
initialState = store.getState();

export const persistor = persistStore(store);
sagaMiddleware.run(rootSaga);

if (process.env.NODE_ENV === "development") {
  if (module.hot) {
    module.hot.accept("./reducers/reducers", () => {
      store.reconfigure(model); // 👈 Here is the magic
    });
  }
}

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID
  },
  Storage: {
    region: config.s3.REGION,
    bucket: config.s3.BUCKET,
    identityPoolId: config.cognito.IDENTITY_POOL_ID
  },
  API: {
    endpoints: [
      {
        name: "time",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION,
        custom_header: async () => {
          return {
            "My-Custom-Header": (await Auth.currentSession()).idToken.jwtToken
          };
        }
      }
    ]
  }
});

ReactDOM.render(
  <Root store={store} persistor={persistor} />,
  document.getElementById("root")
);
registerServiceWorker();

/*const store = createStore(
    rootReducer,
    initialState,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
*/
